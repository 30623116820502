import { logger } from '@/utils/logger';

async function createHash(message: string) {
	const data = new TextEncoder().encode(message);
	const hash = await crypto.subtle.digest('SHA-256', data);

	return Array.from(new Uint8Array(hash))
		.map((b) => b.toString(16).padStart(2, '0'))
		.join('')
		.toString();
}

function randomString(size: number) {
	const i2hex = (i: number) => ('0' + i.toString(16)).slice(-2);
	const r = (a: string, i: number): string => a + i2hex(i);
	const bytes = crypto.getRandomValues(new Uint8Array(size));

	return Array.from(bytes).reduce(r, '');
}

export async function generateCSRFToken() {
	const secret = process.env.CSRF_SECRET_KEY;

	if (!secret) {
		logger.error('CSRF_SECRET_KEY is not defined in environment variables');

		return null;
	}

	const csrfToken = randomString(16);
	const csrfTokenHash = await createHash(`${csrfToken}${secret}`);

	return `${csrfToken}|${csrfTokenHash}`;
}

export async function verifyCSRFToken(csrfToken: string | null, csrfCookieValue?: string) {
	if (!csrfToken) {
		logger.error('CSRF token missing');

		return false;
	}

	if (!csrfCookieValue) {
		logger.error('CSRF cookie value missing');

		return false;
	}

	const secret = process.env.CSRF_SECRET_KEY;

	if (!secret) {
		logger.error('CSRF_SECRET_KEY is not defined in environment variables');

		return false;
	}

	const [token, providedHash] = csrfToken.split('|');
	const [cookieToken, cookieHash] = csrfCookieValue.split('|');

	if (!token || !providedHash || !cookieToken || !cookieHash) {
		logger.error(`Invalid CSRF token format: csrfToken=${csrfToken}, csrfCookieValue=${csrfCookieValue}`);

		return false;
	}

	const calculatedHash = await createHash(`${token}${secret}`);
	const calculatedCookieHash = await createHash(`${cookieToken}${secret}`);

	if (calculatedHash !== providedHash || calculatedCookieHash !== cookieHash) {
		logger.error(`CSRF token verification failed: csrfToken=${csrfToken}, csrfCookieValue=${csrfCookieValue}`);

		return false;
	}

	return true;
}

export function getCSRFCookieValue() {
	if (typeof document === 'undefined') {
		return undefined;
	}

	const cookies = document.cookie.split(';');

	for (const cookie of cookies) {
		const [key, value] = cookie.trim().split('=');

		if (key === '__Host-csrfToken') {
			return value;
		}
	}

	return undefined;
}
