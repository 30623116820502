import { SVGAttributes } from 'react';

export const ShareIcon = (props: SVGAttributes<SVGElement>) => (
	<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M21.9072 19.9365C20.617 19.9365 19.4742 20.5511 18.7427 21.501L10.0012 16.6451C10.0469 16.4115 10.0723 16.1677 10.0723 15.9188C10.0723 15.6953 10.0469 15.4769 10.0113 15.2584L19.0475 10.9969C19.7739 11.7181 20.7694 12.1651 21.8716 12.1651C24.0761 12.1651 25.8742 10.3721 25.8742 8.16257C25.8742 5.95305 24.0812 4.16003 21.8716 4.16003C19.6621 4.16003 17.8691 5.95305 17.8691 8.16257C17.8691 8.71622 17.9808 9.2394 18.184 9.71686L9.47798 13.821C8.77195 12.6781 7.51227 11.9111 6.07481 11.9111C3.87036 11.9111 2.07227 13.7042 2.07227 15.9137C2.07227 18.1232 3.86528 19.9162 6.07481 19.9162C7.48687 19.9162 8.72623 19.1797 9.44242 18.0724L18.0672 22.8623C17.9707 23.2026 17.9148 23.5632 17.9148 23.9391C17.9148 26.1435 19.7078 27.9416 21.9173 27.9416C24.1269 27.9416 25.9199 26.1486 25.9199 23.9391C25.9199 21.7296 24.1269 19.9365 21.9173 19.9365H21.9072ZM21.8615 5.68384C23.2278 5.68384 24.3402 6.79622 24.3402 8.16257C24.3402 9.52892 23.2278 10.6413 21.8615 10.6413C20.4951 10.6413 19.3827 9.52892 19.3827 8.16257C19.3827 6.79622 20.4951 5.68384 21.8615 5.68384ZM6.06465 18.3924C4.6983 18.3924 3.58592 17.28 3.58592 15.9137C3.58592 14.5473 4.6983 13.435 6.06465 13.435C7.431 13.435 8.54338 14.5473 8.54338 15.9137C8.54338 17.28 7.431 18.3924 6.06465 18.3924ZM21.9072 26.4127C20.5408 26.4127 19.4285 25.3004 19.4285 23.934C19.4285 22.5677 20.5408 21.4553 21.9072 21.4553C23.2735 21.4553 24.3859 22.5677 24.3859 23.934C24.3859 25.3004 23.2735 26.4127 21.9072 26.4127Z"
			fill="currentColor"
		></path>
	</svg>
);
