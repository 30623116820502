'use client';

import {
	FormTrackingEventTrigger,
	formTracking,
	getFormTrackingProps,
	getFormWrapperTrackingProps,
} from '@/components/block-templates/Forms/FormContainerBlockTemplate/FormContainerBlockTemplate.tracking';
import {
	extractSalesforceFormProps,
	formatFormFieldData,
	getDefaultValues,
	getHiddenHoneyPotFieldValue,
	isSelectFormField,
} from '@/components/block-templates/Forms/FormContainerBlockTemplate/FormContainerBlockTemplate.utils';
import {
	IFormProps,
	IFormResponse,
	constructFormData,
	getSuccessMessage,
	mergeClientAndServerFormComponents,
	saveFormPersonalizationValues,
	splitFormFieldsAndAttachments,
} from '@/components/block-templates/Forms/FormContainerBlockTemplate/components/Form.utils';
import { FormMessage } from '@/components/block-templates/Forms/FormContainerBlockTemplate/components/FormMessage';
import { TrackedContainer } from '@/components/shared/TrackedContainer/TrackedContainer';
import { Button } from '@/components/core/Button/Button';
import { displayOptionsCSSVariablesProvider } from '@/components/shared/ElementWithDisplayOptions/ElementWithDisplayOptions.utils';
import { FromTrackingProvider, IRegisteredSelectionItem } from '@/components/shared/Forms/FormTrackingProvider';
import { useGlobalTracking } from '@/components/shared/GlobalTrackingContainer/useGlobalTracking';
import { TextSnippet } from '@/components/shared/TextSnippet/TextSnippet';
import { Tracked } from '@/components/shared/tracking/Tracked';
import { styled } from '@/styled-system/jsx';
import { IFormSubmitDetails } from '@/types/form-submission/IFormSubmitBody';
import { useRouter, useSearchParams } from 'next/navigation';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { getCSRFCookieValue } from '@/utils/csrf';

export const Form: FC<IFormProps> = ({
	formType,
	formContainer,
	vontobelProfile,
	pageData,
	language,
	serverFormFields,
	translations,
}) => {
	const successMessageRef = useRef<HTMLDivElement>(null);
	const [currentForm, setCurrentForm] = useState(formContainer);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [isHoneypotTriggered, setIsHoneypotTriggered] = useState(false);
	const router = useRouter();
	const searchParams = useSearchParams();
	const { utag } = useGlobalTracking();

	const { formSalesforce, formFieldsAbove, formFieldsBelow, preselectedValues } = currentForm;
	const salesforceForm = extractSalesforceFormProps(formSalesforce);

	const successMessage = getSuccessMessage(isHoneypotTriggered, translations, salesforceForm?.submitSuccessText);

	const defaultValues = getDefaultValues(
		preselectedValues,
		formFieldsAbove,
		formFieldsBelow,
		searchParams,
		salesforceForm?.formFields,
		vontobelProfile
	);

	const methods = useForm({
		defaultValues,
	});

	const {
		reset,
		formState: { isSubmitSuccessful, isSubmitting, isValid },
	} = methods;

	const trackingRef = useRef<HTMLFormElement>(null);

	const registeredSelectionItems: IRegisteredSelectionItem[] = useMemo(() => [], []);

	const onSubmit: SubmitHandler<any> = async (data) => {
		formTracking({
			salesforceForm,
			formFieldsAbove,
			formFieldsBelow,
			eventTrigger: FormTrackingEventTrigger.Submit,
			formValues: data,
		})
			.sendFormTrackingEvent(trackingRef.current)
			.sendSelectionItemEvents(registeredSelectionItems);

		// If a success message is currently displayed, hide it
		if (showSuccessMessage) {
			setShowSuccessMessage(false);
		}

		const formFields = [...(salesforceForm?.formFields || []), ...(formFieldsAbove || []), ...(formFieldsBelow || [])];
		const confirmationLinkAnchor = salesforceForm?.confirmationLinkAnchor;
		const emailConfirmationTargetPageUrl = salesforceForm?.emailConfirmationTargetPage?.url;

		const { fileAttachments, formFieldsData } = splitFormFieldsAndAttachments(formFields, data);

		const HHPFieldValue = getHiddenHoneyPotFieldValue(formFieldsData, formFields);

		setIsHoneypotTriggered(!!HHPFieldValue);

		const formDetails: IFormSubmitDetails = {
			FormData: formFieldsData,
			SubmittedFields: formatFormFieldData(formFieldsData, formFields),
			HHPFieldValue,
			FormContainerId: currentForm?.contentLink?.id,
			FormTypeId: salesforceForm?.contentLink?.id,
			PageId: pageData?.id?.toString(),
			Language: currentForm?.language?.name,
			...(confirmationLinkAnchor ? { ConfirmationLinkAnchor: confirmationLinkAnchor } : {}),
			...(emailConfirmationTargetPageUrl ? { EmailConfirmationTargetPageUrl: emailConfirmationTargetPageUrl } : {}),
		};

		const formData = constructFormData(formDetails, fileAttachments);
		const csrfToken = getCSRFCookieValue();

		const submissionResultResponse = await fetch(`/api/FormContainer/Submit/${formType}/`, {
			method: 'POST',
			body: formData,
			headers: {
				Referer: `${pageData.domainUrl}/${pageData.slug}`,
				...(csrfToken ? { 'x-csrf-token': csrfToken } : {}),
			},
		});

		let submissionResult: IFormResponse | null = null;

		if (submissionResultResponse.ok) {
			submissionResult = await submissionResultResponse.json();
		}

		if (!submissionResultResponse.ok || !submissionResult?.result) {
			formTracking({
				salesforceForm,
				formFieldsAbove,
				formFieldsBelow,
				eventTrigger: FormTrackingEventTrigger.TechnicalError,
			}).sendFormTrackingEvent(trackingRef.current);
			setShowErrorMessage(true);

			return;
		}

		// Persist tealium hashId
		if (submissionResult?.hashId) {
			utag?.ext.persistUid({ hash_id: submissionResult.hashId });
		}

		formTracking({
			salesforceForm,
			formFieldsAbove,
			formFieldsBelow,
			eventTrigger: FormTrackingEventTrigger.SubmitSuccess,
			formValues: data,
		}).sendFormTrackingEvent(trackingRef.current);

		// Redirect
		if (salesforceForm?.submitSuccessRedirectPage && !HHPFieldValue) {
			let url = salesforceForm?.submitSuccessRedirectPage.url;

			if (salesforceForm?.successPageAnchor) {
				url += `#${salesforceForm?.successPageAnchor}`;
			}

			return router.push(url);
		}

		if (!salesforceForm?.hideFormAfterSubmit) {
			setShowSuccessMessage(true);
		}

		if (showErrorMessage) {
			setShowErrorMessage(false);
		}
	};

	const saveAllPersonalizationValues = async () => {
		const currentFormValues = methods.getValues();
		const values = Object.keys(currentFormValues).flatMap((key) => {
			if (currentFormValues[key] !== undefined) {
				const currentField = [
					...(formFieldsAbove || []),
					...(formFieldsBelow || []),
					...(salesforceForm?.formFields || []),
				].find((field) => {
					return field?.contentLink?.expanded?.fieldName === key;
				});

				if (currentField?.contentLink.expanded.triggersPersonalization) {
					let data: string | Array<string> = currentFormValues[key] || '';

					if (isSelectFormField(currentField)) {
						data = (currentFormValues[key] || '').split(',');
					}

					return [
						{
							fieldName: key,
							value: data,
						},
					];
				}
			}

			return [];
		});

		return saveFormPersonalizationValues(values);
	};

	const updateAndRebuildForm = async () => {
		let url = `/api/FormContainer/Form/${formContainer.contentLink.id}/`;
		const searchParamsString = searchParams.toString();

		if (searchParamsString) {
			url += `?${searchParamsString}`;
		}
		const formContentsResponse = await fetch(url, {
			headers: { 'accept-language': currentForm.language.name },
		});

		if (formContentsResponse.ok) {
			const formContents = await formContentsResponse.json();

			setCurrentForm(formContents);
		}
	};

	const handlePersonalizableFieldChange = async (fieldName: string, value: string | Array<string>) => {
		const res = await saveFormPersonalizationValues([{ fieldName, value }]);

		if (res) {
			updateAndRebuildForm();
		}
	};

	// Send invalid event
	useEffect(() => {
		if (!isSubmitting) {
			return;
		}

		if (!isValid) {
			formTracking({
				salesforceForm,
				formFieldsAbove,
				formFieldsBelow,
				eventTrigger: FormTrackingEventTrigger.ValidationError,
			}).sendFormTrackingEvent(trackingRef.current);
		}
	}, [isValid, isSubmitting]);

	useEffect(() => {
		if (!isSubmitSuccessful) {
			return;
		}

		if (!salesforceForm?.hideFormAfterSubmit) {
			reset(undefined, { keepDefaultValues: true });
		}

		if (successMessageRef.current) {
			successMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [isSubmitSuccessful]);

	useEffect(() => {
		saveAllPersonalizationValues().then(() => {
			updateAndRebuildForm();
		});
	}, []);

	useEffect(() => {
		// Reset the form with updated default values when the search params change.
		// This is necessary because useForm sets default values only on first render,
		// and due to shallow routing, the form component remains mounted across params changes on the same route.
		reset(defaultValues, {
			keepDirtyValues: true,
		});
	}, [searchParams?.toString()]);

	if (!formContainer || !salesforceForm) {
		return null;
	}

	return (
		<TrackedContainer {...getFormWrapperTrackingProps(formContainer, salesforceForm)}>
			<Tracked {...getFormTrackingProps(salesforceForm)} trackingElementRef={trackingRef}>
				<FromTrackingProvider selectionItems={registeredSelectionItems}>
					<FormProvider {...methods}>
						{!showErrorMessage && isSubmitSuccessful && salesforceForm?.hideFormAfterSubmit ? (
							<FormMessage
								variant="success"
								content={successMessage}
								ref={successMessageRef}
								scrollMarginTop="var(--header-height)"
							/>
						) : (
							<styled.form
								id={salesforceForm?.formAnchor}
								onSubmit={methods.handleSubmit(onSubmit)}
								display="flex"
								flexWrap="wrap"
								style={displayOptionsCSSVariablesProvider()}
								gap={4}
								noValidate
								ref={trackingRef}
							>
								{mergeClientAndServerFormComponents(
									handlePersonalizableFieldChange,
									translations,
									pageData,
									serverFormFields.componentsAbove,
									formFieldsAbove,
									language
								)}

								{mergeClientAndServerFormComponents(
									handlePersonalizableFieldChange,
									translations,
									pageData,
									serverFormFields.salesforceComponents,
									salesforceForm?.formFields,
									language
								)}

								{mergeClientAndServerFormComponents(
									handlePersonalizableFieldChange,
									translations,
									pageData,
									serverFormFields.componentsBelow,
									formFieldsBelow,
									language
								)}

								{salesforceForm?.mandatoryInformationInfo && (
									<TextSnippet
										dataId={`${salesforceForm?.formAnchor}-text-snippet`}
										pageData={pageData}
										content={salesforceForm?.mandatoryInformationInfo}
									/>
								)}

								<Button type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
									{salesforceForm?.sendButtonLabel}
								</Button>

								{showErrorMessage && <FormMessage variant="error" content={salesforceForm?.submitErrorText} />}

								{showSuccessMessage && !salesforceForm?.hideFormAfterSubmit && (
									<FormMessage variant="success" content={successMessage} />
								)}
							</styled.form>
						)}
					</FormProvider>
				</FromTrackingProvider>
			</Tracked>
		</TrackedContainer>
	);
};
