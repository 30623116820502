import { sva } from '@/styled-system/css';

export const contentSearchCardRecipe = sva({
	slots: ['root', 'documentIcon', 'thumbnail', 'content', 'info'],
	base: {
		root: {
			p: '7',
			gap: '6',
			position: 'relative',
			flexDir: { base: 'column', sm: 'row' },
			borderWidth: '1px',
			borderColor: 'other.border.regular.default',
			borderRadius: 'lg',
			transition: 'background-color 0.3s ease-out',
			backgroundColor: 'surface.interactive.transparent.regular',
			_hover: { backgroundColor: 'surface.interactive.transparent.regular.hover' },
			_active: { backgroundColor: 'surface.interactive.transparent.regular.pressed' },
		},
		documentIcon: {
			'& > svg': {
				width: '56px',
				height: '56px',
			},
		},
		thumbnail: {
			height: '56px',
			width: '56px',
			borderRadius: 'xs',
			objectFit: 'cover',
			flexShrink: '0',
		},
		content: {
			color: 'text.regular.default',
			flexDir: 'column',
			gap: '2',
		},
		info: {
			color: 'text.regular.subtitle',
			mt: '2',
		},
	},
});
