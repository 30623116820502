import { sva } from '@/styled-system/css';
import { RecipeVariant } from '@/styled-system/types';

export const searchDropdownRecipe = sva({
	slots: [
		'root',
		'control',
		'input',
		'trigger',
		'clearTrigger',
		'content',
		'itemGroup',
		'item',
		'tag',
		'helperText',
		'error',
	],
	base: {
		root: {
			width: 'full',
			color: 'text.regular.default',
		},
		control: {
			width: 'full',
			minH: 10,
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			px: 3,
			gap: 1,
			borderWidth: 1,
			borderColor: 'other.border.regular.default',
			borderRadius: 'sm',
			color: 'text.regular.subtitle',

			_open: {
				borderColor: 'other.border.regular.focus',
			},

			_hover: {
				backgroundColor: 'surface.interactive.input.regular.hoverAndPressed',
			},

			_focus: {
				borderColor: 'other.border.regular.focus',
				outline: 'none',
			},

			_pressed: {
				borderColor: 'other.border.regular.focus',
			},
		},
		input: {
			flexGrow: 1,
			background: 'transparent',
			color: 'text.regular.default',
			outline: 'none',
			border: '1px solid transparent',
			margin: 0.5,

			'&[data-filled="true"]': {
				fontWeight: 'semibold',
			},

			_placeholder: {
				color: 'text.regular.subtitle',
			},

			_disabled: {
				color: 'text.regular.disabled',
			},
		},
		trigger: {
			'& > svg': {
				cursor: 'pointer',
				transition: 'transform 0.2s',
				transformOrigin: 'center',
			},

			_open: {
				'& > svg': {
					transform: 'rotate(-180deg)',
				},
			},
		},
		clearTrigger: {
			cursor: 'pointer',
			backgroundColor: 'icons.regular.default',
			color: 'white',
			borderRadius: '3xl',
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			width: 'full',
			backgroundColor: 'surface.interactive.transparent.regular.default',
			boxShadow: 'strongBottom',
			gap: 2,
			p: 2,
			borderRadius: 'sm',
			zIndex: 1000,
		},
		itemGroup: {
			display: 'flex',
			flexDirection: 'column',
			gap: 2,
		},
		item: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			cursor: 'pointer',
			border: '1px solid transparent',
			borderRadius: 8,
			py: 3,
			px: 3,

			_checked: {
				bg: 'surface.interactive.transparent.regular.pressed',
			},

			_hover: {
				bg: 'surface.interactive.transparent.regular.hover',
			},

			_highlighted: {
				bg: 'surface.interactive.transparent.regular.hover',
			},

			_pressed: {
				bg: 'surface.interactive.transparent.regular.pressed',
			},

			_active: {
				bg: 'surface.interactive.transparent.regular.pressed',
			},

			_disabled: {
				pointerEvents: 'none',
				bg: 'surface.interactive.transparent.regular.default',
				color: 'text.regular.disabled',
			},
		},
		helperText: {
			color: 'text.regular.subtitle',
			mt: 1,
		},
		error: {
			display: 'flex',
			alignItems: 'center',
			mt: 2,
			gap: 1,
		},
	},
	variants: {
		size: {
			sm: {
				control: {
					py: 1,
				},
			},
			md: {
				control: {
					py: 1.5,
				},
			},
		},
		disabled: {
			true: {
				control: {
					color: 'text.regular.disabled',
					borderColor: 'other.border.regular.default',
					background: 'surface.interactive.transparent.regular.disabled',
					pointerEvents: 'none',
				},
			},
		},
		readOnly: {
			true: {
				control: {
					color: 'text.regular.subtitle',
					borderColor: 'other.border.regular.default',
					background: 'surface.interactive.transparent.regular.disabled',
					pointerEvents: 'none',
				},
				trigger: {
					'& > svg': {
						color: 'icons.regular.disabled',
					},
				},
			},
		},
		multiple: {
			true: {
				item: {
					width: 'full',
					height: 'auto',
					px: 0,
					py: 0,
					border: 'none',
					fontWeight: 'regular',

					_checked: {
						bg: 'surface.interactive.transparent.regular.default',
						fontWeight: 'semibold',
					},

					'& [data-scope="checkbox"]': {
						'&[data-part="root"]': {
							height: 'full',
							width: 'full',
							color: 'text.regular.default',
							py: 3,
							px: 2,
						},
					},
				},
			},
		},
		isError: {
			true: {
				control: {
					borderColor: 'trafficLights.error.default',

					_focus: {
						borderColor: 'trafficLights.error.default',
					},
				},
				helperText: {
					color: 'text.regular.danger',
				},
				error: {
					color: 'text.regular.danger',
				},
			},
		},
		hidden: {
			true: {
				root: {
					display: 'none',
				},
			},
		},
		scrollingOverflow: {
			md: {
				content: {
					overflowY: 'auto',
					maxH: '400px',
				},
			},
			lg: {
				content: {
					overflowY: 'auto',
					maxH: 'max(50vh, 300px)',
				},
			},
		},
	},
	compoundVariants: [
		{
			multiple: true,
			readOnly: true,
			css: {
				tag: {
					bg: 'actions.filterTags.regular.fillDefault',
					color: 'text.regular.default',
					fontWeight: 'semibold',
					pr: 2,
					borderWidth: '1px',
					borderStyle: 'solid',
					borderColor: 'other.border.regular.default',

					'& div': {
						display: 'none',
					},
				},
			},
		},
		{
			multiple: true,
			disabled: true,
			css: {
				tag: {
					bg: 'actions.filterTags.regular.fillDisabled',

					'& span': {
						color: 'actions.filterTags.regular.textAndIconDisabled',
					},

					'& div': {
						'& svg': {
							color: 'actions.filterTags.regular.textAndIconDisabled',
						},
					},
				},
			},
		},
	],
	defaultVariants: {
		scrollingOverflow: 'md',
		size: 'sm',
	},
});

export type SearchDropdownVariants = RecipeVariant<typeof searchDropdownRecipe>;
