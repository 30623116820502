import { css } from '@/styled-system/css';

export const NavigationScrollShadow = () => (
	<div
		className={css({
			pointerEvents: 'none',
			width: 'calc(100% - 20px)',
			height: 10,
			position: 'absolute',
			bottom: 0,
			background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 100%)',
		})}
	/>
);
