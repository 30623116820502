import { sva } from '@/styled-system/css';

export const contentSearchFieldRecipe = sva({
	slots: ['root', 'input', 'leftElement', 'rightElement', 'clearButton', 'searchButton'],
	base: {
		root: {
			width: 'full',
			display: 'flex',
			position: 'relative',
			isolation: 'isolate',
		},
		input: {
			width: 'full',
			height: '20',
			outline: 'transparent solid 2px',
			outlineOffset: '2px',
			pl: '6',
			pr: '125px',
			md: {
				pl: '14',
				pr: '250px',
			},
			position: 'relative',
			appearance: 'none',
			boxShadow: 'subtleBottom',
			borderRadius: '3xl',

			transition: 'background-color 0.3s ease-out',
			backgroundColor: 'surface.interactive.card.primary',
			_hover: {
				backgroundColor: 'surface.interactive.input.regular.hoverAndPressed',
			},

			color: 'text.regular.default',
			_placeholder: {
				color: 'text.regular.subtitle',
			},
		},
		leftElement: {
			display: {
				base: 'none',
				md: 'flex',
			},
			left: '0px',
			width: '14',
			height: '20',
			alignItems: 'center',
			justifyContent: 'end',
			position: 'absolute',
			top: '0px',
			zIndex: '2',
			pointerEvents: 'none',
			color: 'text.regular.default',

			'& > svg': {
				width: '24px',
				height: '24px',
				marginRight: '8px',
			},
		},

		rightElement: {
			right: '0px',
			width: {
				base: '125px',
				md: '250px',
			},
			height: '20',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'end',
			position: 'absolute',
			top: '0px',
			zIndex: '2',
			color: 'text.regular.default',
		},

		clearButton: {
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '48px',
			height: '48px',

			'& > svg': {
				width: '32px',
				height: '32px',
			},
		},
		searchButton: {
			mr: '4',
			w: 'max-content',

			'& > svg': {
				display: 'block',
				width: '24px',
				height: '24px',
			},

			'& > span': {
				display: 'none',
			},

			md: {
				w: '185px',

				'& > svg': {
					display: 'none',
				},

				'& > span': {
					display: 'inline',
				},
			},
		},
	},
});
