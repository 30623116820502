import { IArticleTeaserProps } from '@/components/block-templates/ArticlePageTeaserBlockTemplate/ArticlePageTeaserBlockTemplate.utils';
import { LinkModeVariant } from '@/enums/Link';
import { ArticleResult } from '@/types/article-filter';
import { ICloudinaryEnvironmentVariables } from '@/utils/cloudinary';
import { TranslationLabelValues } from '@/utils/i18n/translation-labels/translationLabels';

export enum ArticleTeaserBackgroundColor {
	SECONDARY = 'Secondary',
	INVERTED = 'Inverted',
	PRIMARY = 'Primary',
}

export const getArticleTeaserLinkModeVariant = (
	articleTeaserBackgroundVariant?: ArticleTeaserBackgroundColor
): LinkModeVariant => {
	switch (articleTeaserBackgroundVariant) {
		case ArticleTeaserBackgroundColor.INVERTED:
			return LinkModeVariant.INVERTED;
		default:
			return LinkModeVariant.STANDARD;
	}
};

export const getArticleTeaserProps = (
	article: ArticleResult,
	articleTranslations: TranslationLabelValues,
	timezoneTranslations: TranslationLabelValues,
	cloudinaryOverrides?: ICloudinaryEnvironmentVariables
): IArticleTeaserProps => {
	const author = article.attributes.authors?.[0] || {};
	const authorFullName = [author.title, author.firstName, author.lastName].filter(Boolean).join(' ');

	return {
		id: article.id?.toString(),
		author: {
			fullName: authorFullName,
			photo: {
				url: author.photoUrl,
				alternativeText: authorFullName,
			},
		},
		publishedDate: article?.attributes.date,
		categories: [
			...(article?.attributes.investorTypeCategories?.map((category) => category?.name) || []),
			...(article?.attributes.topicCategories?.map((category) => category?.name) || []),
		],
		title: article?.attributes.headLine,
		description: article?.attributes.description,
		articleImage: {
			url: article?.attributes.imageUrl,
			alternativeText: article?.attributes.headLine,
		},
		articleUrl: article?.attributes.url,
		positionOfTheImage: 'left',
		articleTranslations,
		timezoneTranslations,
		cloudinaryOverrides,
	};
};
