import { trackingSettingsUrl } from '@/constants/global';
import { RequestTag } from '@/constants/requestTag';
import { ITrackingSettingsContent } from '@/interfaces/settings';
import { fetcher } from '@/utils/fetcher';

export async function fetchTrackingSettings(): Promise<ITrackingSettingsContent | undefined> {
	const response = await fetcher(trackingSettingsUrl, {
		next: {
			revalidate: 24 * 60 * 60, // 24 hours
			tags: [RequestTag.GetTrackingSettings],
		},
	});

	if (!response.ok) {
		console.error('Error when getting tracking settings. Status code: ', response.status);

		return;
	}

	const settings = (await response.json()) as ITrackingSettingsContent;

	return settings;
}

export const updatePageTrackingData = (trackingData?: Record<string, unknown>): boolean => {
	if (typeof window === 'undefined') {
		return false;
	}

	if (!window?.utag || !window?.utag?.data || !window?.utag_data || !trackingData) {
		return false;
	}

	for (const [key, value] of Object.entries(trackingData)) {
		window.utag.data[key] = value;
		window.utag_data[key] = value;
	}

	return true;
};
