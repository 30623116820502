import { sva } from '@/styled-system/css';

export const disclaimer = sva({
	slots: ['root', 'content', 'container', 'backdrop', 'overlay', 'legalDisclaimer', 'cookieDisclaimer', 'separator'],
	variants: {
		blur: {
			true: {
				backdrop: {
					backdropFilter: 'blur(0.5rem)',
				},
			},
		},
	},
	base: {
		root: {},
		backdrop: {
			position: 'fixed',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: 999,
			bg: 'base.neutrals.90/60',

			lg: {
				zIndex: 996,
			},
		},
		overlay: {
			position: 'fixed',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: 998,
			pointerEvents: 'none',
		},
		content: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			position: 'fixed',
			bottom: 0,
			left: 0,
			right: 0,
			top: 0,
			zIndex: 999,
			overflow: 'hidden',

			p: {
				base: 2,
				md: 6,
				lg: 4,
			},
		},
		container: {
			position: 'relative',
			borderRadius: 6,
			overflow: 'hidden',

			maxHeight: {
				base: 'calc(100% - var(--spacing-2))',
				md: 'calc(100% - var(--spacing-6))',
				lg: 'calc(100% - var(--spacing-4))',
			},

			pr: 'env(safe-area-inset-right)',
			pl: 'env(safe-area-inset-left)',
		},
		legalDisclaimer: {
			borderRadius: 6,
			bg: 'surface.interactive.card.secondary',
			flexDirection: 'column',
			maxW: 592,
			w: 'full',
			alignItems: 'flex-start',
			gap: 4,
			p: 6,

			md: {
				gap: 6,
				p: 8,
			},

			'& .Introduction': {
				gap: 4,
				w: 'full',
				alignItems: 'flex-start',
			},

			'& .Selects': {
				flexDirection: 'column',
				width: 'full',
				alignItems: 'flex-start',
				gap: 4,

				md: {
					flexDirection: 'column',
					pb: 4,
				},

				'& > div': {
					gap: 2,

					md: {
						gap: 3,
					},
				},
			},

			'& .Buttons': {
				w: 'full',
				gap: 2,

				flexDirection: {
					base: 'column-reverse',
					md: 'row',
				},

				'& button': {
					h: 12,
					w: 'full',

					md: {
						maxW: 140,
					},
				},
			},

			'& .Terms': {
				fontSize: '0.875rem',
				color: 'actions.links.regular.default',
				textDecoration: 'underline',
				marginTop: 2,

				md: {
					marginTop: 0,
				},

				_hover: {
					color: 'actions.links.regular.hoverAndPressed',
					borderBottomColor: 'actions.links.regular.hoverAndPressed',
				},

				_active: {
					color: 'actions.links.regular.hoverAndPressed',
					borderBottomColor: 'actions.links.regular.hoverAndPressed',
				},
			},

			'& .CloseButton': {
				position: 'absolute',
				top: 2,
				right: 2,
				p: 3,
				color: 'icons.regular.default',
			},

			'&.-together': {
				position: 'relative',
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
				maxWidth: 1008,
				top: '2px',

				'& .Introduction': {
					gap: 2,
				},

				'& .Selects': {
					pb: 0,

					'& .Place': {
						'& [data-scope="checkbox"]': {
							fontSize: '0.875rem',
						},
					},

					md: {
						flexDirection: 'row',
					},
				},

				'& .Buttons': {
					display: 'none',
				},

				'& .Terms': {
					md: {
						textAlign: 'right',
					},
				},
			},

			'@media screen and (max-width: 1024px) and (max-height: 700px)': {
				'&.-together': {
					gap: 2,
					pt: 4,
					px: 3,
					pb: 3,

					'& .Introduction': {
						gap: 2,

						'& h2': {
							fontSize: '1.25rem',
						},
					},

					'& .Terms': {
						fontSize: '0.75rem',
					},

					'& .Selects': {
						'& > div': {
							gap: 1,
						},

						'& .Place': {
							'& [data-scope="checkbox"]': {
								fontSize: '0.75rem',
							},
						},

						'& .Terms': {
							fontSize: '0.75rem',
						},
					},
				},
			},
		},

		cookieDisclaimer: {
			position: 'fixed',
			bg: 'surface.interactive.card.secondary',
			w: 'full',
			bottom: 0,
			left: 0,
			right: 0,
			borderRadius: 0,
			gap: 6,
			p: 8,
			pt: 12,

			md: {
				py: 16,

				_landscape: {
					py: '10dvh',
				},
			},

			'& .Introduction': {
				gap: 4,
			},

			'& .CloseButton': {
				position: 'absolute',
				top: 2,
				right: 2,
				p: 3,
				color: 'icons.regular.default',
			},

			'& .Buttons': {
				gap: 2,
				flexDirection: {
					base: 'column-reverse',
					md: 'column',
				},

				justifyContent: 'flex-end',

				'&.-hidden': {
					justifyContent: 'center',
				},

				'& button': {
					width: 'full',
					minW: '200px',
					paddingInline: 4,
					paddingBlock: 3,
					fontSize: '0.875rem',
					height: '48px',
				},
			},

			'&.-together': {
				position: 'relative',
				borderBottomLeftRadius: 6,
				borderBottomRightRadius: 6,
				maxWidth: 1008,
				paddingX: 6,
				paddingBottom: 6,
				paddingTop: 0,

				md: {
					paddingX: 8,
					paddingBottom: 8,
				},

				'& .Introduction': {
					'& div': {
						md: {
							margin: 'auto',
						},
					},
				},

				'& .CloseButton': {
					display: 'none',
				},
			},

			'@media screen and (max-width: 1024px) and (max-height: 700px)': {
				'&.-together': {
					gap: 2,
					px: 3,
					pb: 3,
					pt: 2,

					'& > div > div': {
						gap: 4,
					},

					'& .Introduction': {
						gap: 2,

						'& h2': {
							// display: 'none',
						},
					},

					'& .Buttons': {
						'& button': {
							paddingInline: 4,
							paddingBlock: 2,
							fontSize: '0.875rem',
							height: '40px',
						},
					},
				},
			},
		},
		separator: {
			display: 'none',
			borderBottom: '1px solid',
			borderColor: 'other.border.regular.strong',
			zIndex: 0,
			w: 'calc(100% - 3rem)',

			md: {
				w: 'calc(100% - 4rem)',
				maxW: 'calc(1008px - 4rem)',
			},

			lg: {
				display: 'block',
			},
		},
	},
});
