'use client';

import { Heading } from '@/components/core/Heading/Heading';
import { Link } from '@/components/core/Link/Link';
import { Text } from '@/components/core/Text/Text';
import { pollRecipe } from '@/components/shared/Poll/Poll.recipe';
import {
	calculateVotePercentages,
	getPollResults,
	hasUserAlreadyVoted,
	isPollAnsweredCorrectly,
	setUserVoted,
	trackVoteEvent,
} from '@/components/shared/Poll/Poll.utils';
import { PollType } from '@/components/shared/Poll/PollType.enum';
import { PollActionBar } from '@/components/shared/Poll/components/PollActionBar';
import { PollAnswer } from '@/components/shared/Poll/components/PollAnswer';
import { TextSnippet } from '@/components/shared/TextSnippet/TextSnippet';
import { Tracked } from '@/components/shared/tracking/Tracked';
import { Box, Flex, styled } from '@/styled-system/jsx';
import { DisplayOption } from '@/types/display-options';
import { EventAttachMode, ITrackedBlockInformation, ITrackingInformation } from '@/types/tracking';
import { getCSRFCookieValue } from '@/utils/csrf';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { logger } from '@/utils/logger';
import { useEffect, useRef, useState } from 'react';

export interface IPollAnswer {
	answerText: string;
	id: number;
	isCorrect?: boolean;
	blockName?: string;
}

export interface IPoll {
	pollCmsId: number;
	question: string;
	answers: Array<IPollAnswer>;
	type: PollType;
	legalNotice?: string;
	allowMultiVoting?: boolean;
	correctAnswerText?: string;
	incorrectAnswerText?: string;
	isDisabled?: boolean;
	hideResultsAfterVoting?: boolean;
	cmsTrackingInformation?: ITrackingInformation;
	trackedBlockInformation?: ITrackedBlockInformation;
}

export type AnswerVoteCount = {
	answerCmsId: number;
	voteCount: number;
};

export type PollResults = {
	voteCount: number;
	answerVoteCounts: Array<AnswerVoteCount>;
};

interface IPollProps {
	poll: IPoll;
	displayOption?: DisplayOption;
	translations: TranslationLabelValues;
	language?: string;
}

export const Poll = ({ poll, displayOption, translations }: IPollProps) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [selectedAnswerIds, setSelectedAnswerIds] = useState<Array<number>>([]);
	const [pollResults, setPollResults] = useState<PollResults>();
	const [shouldShowAbsoluteValue, setShouldShowAbsoluteValue] = useState(false);
	const trackingRef = useRef<HTMLDivElement>(null);

	const classes = pollRecipe({ displayOption: displayOption === 'halfWidth' ? 'halfWidth' : 'fullWidth' });

	if (!poll) {
		return null;
	}

	const submitForm = async (answerIds: Array<number>) => {
		trackVoteEvent(answerIds, false, trackingRef, poll);
		setIsSubmitting(true);

		const csrfToken = getCSRFCookieValue();

		const voteResponse = await fetch('/api/polls/SubmitVotes/', {
			method: 'POST',
			body: JSON.stringify({
				answerCmsIds: answerIds || [],
				pollId: poll.pollCmsId,
			}),
			headers: {
				...(csrfToken ? { 'x-csrf-token': csrfToken } : {}),
			},
		});

		if (!voteResponse.ok) {
			console.error('Error casting vote');
			setIsSubmitting(false);
			setSelectedAnswerIds([]);

			return;
		}

		try {
			const newPollResults = await voteResponse.json();

			if (newPollResults) {
				setPollResults(newPollResults.data);
			}
		} catch (error) {
			logger.error(`Error parsing vote response ${error}`);
		}
		setIsSubmitting(false);
		setIsSubmitted(true);
		setUserVoted(poll.pollCmsId);
	};

	const handlePollVoteClick = async (answerId: number) => {
		if (poll.allowMultiVoting) {
			const voteAlreadyPresent = selectedAnswerIds.includes(answerId);

			if (voteAlreadyPresent) {
				const newVotedAnswerIds = selectedAnswerIds.filter((id) => id !== answerId);

				setSelectedAnswerIds(newVotedAnswerIds);
			} else {
				setSelectedAnswerIds((currentValue) => [...currentValue, answerId]);
			}
		} else {
			setSelectedAnswerIds([answerId]);
			submitForm([answerId]);
		}
	};

	useEffect(() => {
		const fetchPollResults = async () => {
			if (poll.hideResultsAfterVoting) {
				return;
			}
			const newPollResults = await getPollResults(poll.pollCmsId);

			setPollResults(newPollResults.data);
		};

		if (poll.isDisabled || hasUserAlreadyVoted(poll.pollCmsId)) {
			fetchPollResults();
			setIsSubmitted(true);
		} else if (poll.type === PollType.QUIZ) {
			fetchPollResults();
		}
	}, []);

	let vontobelOpinionText;

	if (poll.type === PollType.QUIZ && isSubmitted) {
		vontobelOpinionText = isPollAnsweredCorrectly(poll, selectedAnswerIds)
			? poll.correctAnswerText
			: poll.incorrectAnswerText;
	}

	const votePercentages = calculateVotePercentages(pollResults?.voteCount || 0, pollResults?.answerVoteCounts || []);

	const legalNoticeId = `poll-${poll.pollCmsId}-legal-notice`;

	return (
		<Tracked
			trackedBlockInformation={poll.trackedBlockInformation}
			cmsTrackingInformation={poll.cmsTrackingInformation}
			trackingElementRef={trackingRef}
			trackingInformation={{
				vote: {
					techCategory: 'interactive-element',
					category: 'poll',
					action: 'vote',
					nonInteraction: false,

					conversionId: poll.cmsTrackingInformation?.conversionId || 'vt-voting',
				},
			}}
			attachTrackingEvent={EventAttachMode.Custom}
		>
			<styled.div className={classes.root} id={`poll-${poll.pollCmsId}`} ref={trackingRef}>
				<Heading as="h4" type="h4" className={classes.question}>
					{poll.question}
					{poll.legalNotice && (
						<Link
							href={`#${legalNoticeId}`}
							aria-label={translate(translations, 'polls.legalDisclaimerLink.title')}
							title={translate(translations, 'polls.legalDisclaimerLink.title')}
						>
							{' *'}
						</Link>
					)}
				</Heading>
				<Flex className={classes.answerContainer}>
					{poll.answers.map((answer, i) => {
						let votePercentage = 0;
						let numberOfVotes = 0;

						if (poll.type === PollType.QUIZ) {
							votePercentage = answer.isCorrect ? 100 : 0;
						} else {
							votePercentage = votePercentages.find((value) => value.answerCmsId === answer.id)?.percentage || 0;
							numberOfVotes =
								pollResults?.answerVoteCounts?.find((value) => value.answerCmsId === answer.id)?.voteCount || 0;
						}

						return (
							<PollAnswer
								index={i}
								key={answer.id}
								answerText={answer.answerText}
								type={poll.type}
								percentageValue={
									!poll.hideResultsAfterVoting && (isSubmitted || poll.isDisabled) ? votePercentage : undefined
								}
								absoluteValue={
									!poll.hideResultsAfterVoting && (isSubmitted || poll.isDisabled) ? numberOfVotes : undefined
								}
								isCorrect={answer.isCorrect}
								isSelected={selectedAnswerIds.includes(answer.id)}
								shouldShowAbsoluteValue={shouldShowAbsoluteValue}
								disabled={isSubmitting || isSubmitted || !!poll.isDisabled}
								isVoted={isSubmitted || (!!poll.isDisabled && votePercentage !== undefined)}
								allowMultiVoting={poll.allowMultiVoting}
								translations={translations}
								onChangeResultLabel={() => setShouldShowAbsoluteValue((currentValue) => !currentValue)}
								onClick={() => handlePollVoteClick(answer.id)}
							/>
						);
					})}
				</Flex>

				<PollActionBar
					classes={classes.actionBar}
					poll={poll}
					isSubmitting={isSubmitting}
					isSubmitted={isSubmitted}
					isSubmitDisabled={selectedAnswerIds.length < 1}
					voteCount={pollResults?.voteCount}
					translations={translations}
					onSubmitForm={() => submitForm(selectedAnswerIds)}
					onShowResults={async () => {
						trackVoteEvent(selectedAnswerIds, true, trackingRef, poll);
						const newPollResults = await getPollResults(poll.pollCmsId);

						setPollResults(newPollResults.data);
						setIsSubmitted(true);
					}}
				/>

				{vontobelOpinionText && (
					<TextSnippet
						color="base.neutrals.70"
						content={vontobelOpinionText}
						dataId={'vontobel-opinion-text'}
					></TextSnippet>
				)}
				{poll.legalNotice && (
					<Box className={classes.legalNoticeContainer}>
						<Text id={legalNoticeId} size="xs" fontWeight="bold" className={classes.legalNoticeTitle}>
							{translate(translations, 'polls.legalDisclaimer.title')}
						</Text>
						<TextSnippet color="base.neutrals.70" content={poll.legalNotice} dataId={'snippet'}></TextSnippet>
					</Box>
				)}
			</styled.div>
		</Tracked>
	);
};
