import { FC } from 'react';
import { HTMLStyledProps, styled } from '@/styled-system/jsx';
import { ArrowRightIcon } from '@/icons/ArrowRightIcon';

interface IScrollToTopButtonProps extends HTMLStyledProps<'button'> {
	label: string;
}

export const ScrollToTopButton: FC<IScrollToTopButtonProps> = ({ label, ...rest }) => {
	return (
		<styled.button {...rest}>
			<styled.span aria-hidden>
				<ArrowRightIcon width="20px" height="20px" />
			</styled.span>
			{label}
		</styled.button>
	);
};
