export const countryCodes = [
	376, 93, 1268, 1264, 355, 374, 244, 672, 54, 1684, 43, 61, 297, 358, 994, 387, 1246, 880, 32, 226, 359, 973, 257, 229,
	590, 1441, 673, 591, 5997, 55, 975, 47, 267, 375, 501, 1, 41, 225, 56, 237, 86, 57, 506, 53, 238, 599, 61, 357, 49,
	253, 45, 767, 213, 593, 372, 20, 212, 291, 34, 251, 358, 679, 691, 33, 241, 1473, 995, 594, 44, 233, 350, 299, 224,
	590, 240, 30, 500, 502, 1, 245, 592, 852, 504, 385, 509, 36, 62, 353, 972, 44, 91, 964, 98, 354, 39, 44, 876, 962, 81,
	254, 996, 855, 850, 82, 686, 1869, 965, 7, 961, 1758, 423, 94, 231, 266, 370, 352, 371, 218, 212, 377, 382, 590, 261,
	223, 95, 976, 853, 596, 222, 1664, 356, 230, 960, 265, 52, 60, 258, 264, 687, 672, 234, 505, 47, 977, 674, 683, 64,
	968, 507, 51, 689, 675, 92, 48, 508, 64, 1, 970, 351, 680, 595, 974, 262, 40, 381, 7, 250, 966, 677, 248, 46, 65, 290,
	386, 4779, 421, 232, 378, 221, 252, 597, 211, 239, 503, 1721, 963, 235, 228, 66, 992, 690, 670, 993, 216, 676, 90,
	868, 688, 255, 380, 256, 1, 598, 998, 1784, 58, 1284, 1340, 84, 678, 681, 685, 967, 262, 27, 260, 263, 268, 389, 63,
	31, 971, 373, 220, 1, 249, 856, 886, 242, 420, 44, 227, 243, 1242, 61, 236, 682, 500, 298, 672, 246, 269, 1345, 692,
	1670, 1649, 672, 1, 39, 383, 599,
];
