'use client';

import { IFormFieldResponse } from '@/interfaces/blocks/forms';
import { Box } from '@/styled-system/jsx';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface IHiddenHoneyPotFormFieldProps {
	field: IFormFieldResponse;
}

export const HiddenHoneyPotFormField: FC<IHiddenHoneyPotFormFieldProps> = ({ field }) => {
	const { control } = useFormContext();
	const name = field.contentLink?.id?.toString();

	return (
		<Controller
			control={control}
			name={name}
			defaultValue={''}
			render={({ field }) => {
				return (
					<Box
						position="absolute"
						left="-9999px"
						opacity="0"
						height="1px"
						width="1px"
						overflow="hidden"
						pointerEvents="none"
					>
						<input type="search" {...field} autoComplete="new-password" tabIndex={-1} />
					</Box>
				);
			}}
		/>
	);
};
