import { Text } from '@/components/core/Text/Text';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';

interface IPollAnswerValueProps {
	answerText: string;
	absoluteValue?: number;
	animatedPercentageValue?: number;
	onChangeResultLabel: () => void;
	shouldShowAbsoluteValue: boolean;
	parentClasses?: string;
	translations: TranslationLabelValues;
}

export const PollAnswerValue = ({
	answerText,
	absoluteValue,
	animatedPercentageValue,
	onChangeResultLabel,
	shouldShowAbsoluteValue,
	parentClasses,
	translations,
}: IPollAnswerValueProps) => {
	return (
		<button
			onClick={(e) => {
				e.preventDefault();
				onChangeResultLabel();
			}}
			aria-label={`${answerText} vote ${shouldShowAbsoluteValue ? translate(translations, 'polls.answer.count.titleSuffix') : translate(translations, 'polls.answer.percentage.titleSuffix')}`}
		>
			<Text size="lg" className={parentClasses}>
				{!shouldShowAbsoluteValue && animatedPercentageValue !== undefined ? `${animatedPercentageValue}%` : ''}
				{shouldShowAbsoluteValue && absoluteValue !== undefined ? absoluteValue : ''}
			</Text>
		</button>
	);
};
