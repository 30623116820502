import { RecipeVariant, RecipeVariantProps, sva } from '@/styled-system/css';

export type FormMessageVariant = RecipeVariant<typeof formMessageRecipe>;

export type FormMessageVariants = RecipeVariantProps<typeof formMessageRecipe>;

export const formMessageRecipe = sva({
	slots: ['root', 'icon'],
	base: {
		root: {
			width: 'full',
			borderRadius: 'sm',
			display: 'flex',
			gap: {
				base: '4',
				sm: '6',
			},
			flexDir: {
				base: 'column-reverse',
				sm: 'row',
			},
			alignItems: {
				base: 'start',
				sm: 'center',
			},
			padding: {
				base: '6',
				sm: '12',
			},
		},
		icon: {
			'& > svg': {
				base: {
					width: '54px',
					height: '54px',
				},
				md: {
					width: '64px',
					height: '64px',
				},
			},
		},
	},
	variants: {
		variant: {
			error: {
				root: {
					backgroundColor: 'trafficLights.error.background',
				},
				icon: {
					color: 'text.regular.default',
				},
			},
			success: {
				root: {
					backgroundColor: 'surface.interactive.card.secondary',
				},
				icon: {
					color: 'icons.inverted.default',
				},
			},
		},
		site: {
			vosti: {},
		},
	},
	compoundVariants: [
		{
			variant: 'success',
			site: 'vosti',
			css: {
				icon: {
					color: 'text.regular.default',
				},
			},
		},
	],
});
