import {
	AppBannerBackgroundColor,
	getAppBannerButtonModeVariant,
} from '@/components/block-templates/AppBannerBlockTemplate/AppBannerBlockTemplate.utils';
import { IInlineImageProps } from '@/components/block-templates/InlineImageBlockTemplate/InlineImageBlockTemplate';
import {
	ILinkBlockTemplateProps,
	LinkTemplate,
} from '@/components/block-templates/LinkBlockTemplate/LinkBlockTemplate';
import { AppBanner } from '@/components/shared/AppBanner/AppBanner';
import { TrackedContainer } from '@/components/shared/TrackedContainer/TrackedContainer';
import { IBlockContent, IBlockResponse, IContentLinkExpanded } from '@/interfaces/coreInformation';
import { getCloudinaryImageSourceUrl } from '@/utils/cloudinary';
import { generateBlockId } from '@/utils/generateBlockId';
import { getTrackingPropertiesFromCMSBlock } from '@/utils/tracking/tracking';
import { FC } from 'react';

export interface ISoftwareApplicationStructuredData {
	ratingsCount?: number;
	ratingsValue?: number;
	currency?: string;
	price?: number;
	operatingSystem?: string;
	category?: string;
}

export interface IAppBannerBlockProps extends IBlockContent {
	title: string;
	description?: string;
	link?: Array<IBlockResponse<ILinkBlockTemplateProps>>;
	backgroundColor?: AppBannerBackgroundColor;
	appStoreID?: string;
	softwareApplicationStructuredData?: ISoftwareApplicationStructuredData;
	image: IContentLinkExpanded<IInlineImageProps>;
}

interface IAppBannerBlockTemplateProps {
	blockDefinition: IBlockResponse<IAppBannerBlockProps>;
}

export const getAppBannerBlockProps = (blockDefinition: IBlockResponse<IAppBannerBlockProps>) => {
	const blockDefinitionExpanded = blockDefinition?.contentLink?.expanded;

	const id = blockDefinition?.contentLink?.id?.toString();
	const contentType = blockDefinitionExpanded?.contentType?.at(-1)?.toLowerCase() || '';
	const linkBlockDefinition = blockDefinitionExpanded?.link?.[0];

	return {
		id: generateBlockId({ contentType, id }),
		color: blockDefinitionExpanded?.backgroundColor,
		title: blockDefinitionExpanded?.title,
		description: blockDefinitionExpanded?.description,
		image: {
			url: getCloudinaryImageSourceUrl({
				src: blockDefinitionExpanded?.image?.expanded?.url,
				transformations: 'f_auto/q_auto:best/ar_1:1,g_face,c_fill,g_auto,',
			}),
			alt: blockDefinitionExpanded?.image?.expanded?.alternativeText,
		},
		actionLink: linkBlockDefinition ? (
			<LinkTemplate
				blockDefinition={linkBlockDefinition}
				mode={getAppBannerButtonModeVariant(blockDefinitionExpanded?.backgroundColor)}
				size="medium"
			/>
		) : undefined,
	};
};

export const AppBannerBlockTemplate: FC<IAppBannerBlockTemplateProps> = ({ blockDefinition }) => {
	const trackedContainerProps = getTrackingPropertiesFromCMSBlock(blockDefinition.contentLink.expanded);

	return (
		<TrackedContainer {...trackedContainerProps}>
			<AppBanner {...getAppBannerBlockProps(blockDefinition)} />
		</TrackedContainer>
	);
};
