export enum RequestTag {
	Sitemap = 'Sitemap',
	Robots = 'Robots',
	GetFooterSettings = 'GetFooterSettings',
	GetLanguagesSettings = 'GetLanguagesSettings',
	GetNavigationCulture = 'GetNavigationCulture',
	GetTrackingSettings = 'GetTrackingSettings',
	GetSEOSettings = 'GetSEOSettings',
	TranslationLabels = 'TranslationLabels',
	VimeoVideo = 'VimeoVideo',
	NotFoundPage = 'NotFoundPage',
}
