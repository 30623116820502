import { Text } from '@/components/core/Text/Text';
import { LanguageSelect } from '@/components/shared/language/LanguageSelect';
import { MarketSelect } from '@/components/shared/language/MarketSelect';
import { ITranslatableComponentProps } from '@/interfaces/ITranslatableComponentProps';
import { IPageLanguage } from '@/interfaces/coreInformation';
import { css } from '@/styled-system/css';
import { HStack, VStack } from '@/styled-system/jsx';
import { translate } from '@/utils/i18n/translation-labels/translationLabels';
import { ILanguageSettings } from '@/utils/language';
import { FC } from 'react';

interface INavigationHeaderProps extends ITranslatableComponentProps {
	forceCulture?: string;
	languageSettings: ILanguageSettings;
	existingLanguages: Array<IPageLanguage>;
}

const languageSelectContentClassName = css({
	'&[data-part="content"]': {
		minWidth: '180px',
	},
});

export const NavigationHeader: FC<INavigationHeaderProps> = ({
	languageSettings,
	forceCulture,
	existingLanguages,
	translations,
}) => {
	return (
		<HStack gap={4} py={{ base: 4 }} _landscape={{ '@media (max-height: 500px)': { display: 'none' } }}>
			<VStack gap={2} alignItems="flex-left">
				<Text size="xs" fontWeight="semibold">
					{translate(translations, 'components.market.label')}
				</Text>
				<MarketSelect
					forceCulture={forceCulture}
					usePortal={false}
					variant="defaultLight"
					languageSettings={languageSettings}
					existingLanguages={existingLanguages}
					translations={translations}
					className={css({ width: 'auto', minWidth: '147px' })}
					positioning={{ placement: 'bottom-start' }}
				/>
			</VStack>
			<VStack gap={2} alignItems="flex-left">
				<Text size="xs" fontWeight="semibold">
					{translate(translations, 'components.language.label')}
				</Text>
				<LanguageSelect
					forceCulture={forceCulture}
					usePortal={false}
					languageSettings={languageSettings}
					existingLanguages={existingLanguages}
					translations={translations}
					contentClassName={languageSelectContentClassName}
					className={css({ width: 'auto', minWidth: '147px' })}
					positioning={{ placement: 'bottom-start' }}
					variant="defaultLight"
				/>
			</VStack>
		</HStack>
	);
};
