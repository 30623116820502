const tokens = {
  "aspectRatios.square": {
    "value": "1 / 1",
    "variable": "var(--aspect-ratios-square)"
  },
  "aspectRatios.landscape": {
    "value": "4 / 3",
    "variable": "var(--aspect-ratios-landscape)"
  },
  "aspectRatios.portrait": {
    "value": "3 / 4",
    "variable": "var(--aspect-ratios-portrait)"
  },
  "aspectRatios.wide": {
    "value": "16 / 9",
    "variable": "var(--aspect-ratios-wide)"
  },
  "aspectRatios.ultrawide": {
    "value": "18 / 5",
    "variable": "var(--aspect-ratios-ultrawide)"
  },
  "aspectRatios.golden": {
    "value": "1.618 / 1",
    "variable": "var(--aspect-ratios-golden)"
  },
  "borders.none": {
    "value": "none",
    "variable": "var(--borders-none)"
  },
  "easings.default": {
    "value": "cubic-bezier(0.4, 0, 0.2, 1)",
    "variable": "var(--easings-default)"
  },
  "easings.linear": {
    "value": "linear",
    "variable": "var(--easings-linear)"
  },
  "easings.in": {
    "value": "cubic-bezier(0.4, 0, 1, 1)",
    "variable": "var(--easings-in)"
  },
  "easings.out": {
    "value": "cubic-bezier(0, 0, 0.2, 1)",
    "variable": "var(--easings-out)"
  },
  "easings.in-out": {
    "value": "cubic-bezier(0.4, 0, 0.2, 1)",
    "variable": "var(--easings-in-out)"
  },
  "durations.fastest": {
    "value": "50ms",
    "variable": "var(--durations-fastest)"
  },
  "durations.faster": {
    "value": "100ms",
    "variable": "var(--durations-faster)"
  },
  "durations.fast": {
    "value": "150ms",
    "variable": "var(--durations-fast)"
  },
  "durations.normal": {
    "value": "200ms",
    "variable": "var(--durations-normal)"
  },
  "durations.slow": {
    "value": "300ms",
    "variable": "var(--durations-slow)"
  },
  "durations.slower": {
    "value": "400ms",
    "variable": "var(--durations-slower)"
  },
  "durations.slowest": {
    "value": "500ms",
    "variable": "var(--durations-slowest)"
  },
  "lineHeights.none": {
    "value": "1",
    "variable": "var(--line-heights-none)"
  },
  "lineHeights.tight": {
    "value": "1.25",
    "variable": "var(--line-heights-tight)"
  },
  "lineHeights.snug": {
    "value": "1.375",
    "variable": "var(--line-heights-snug)"
  },
  "lineHeights.normal": {
    "value": "1.5",
    "variable": "var(--line-heights-normal)"
  },
  "lineHeights.relaxed": {
    "value": "1.625",
    "variable": "var(--line-heights-relaxed)"
  },
  "lineHeights.loose": {
    "value": "2",
    "variable": "var(--line-heights-loose)"
  },
  "letterSpacings.tighter": {
    "value": "-0.05em",
    "variable": "var(--letter-spacings-tighter)"
  },
  "letterSpacings.tight": {
    "value": "-0.025em",
    "variable": "var(--letter-spacings-tight)"
  },
  "letterSpacings.normal": {
    "value": "0em",
    "variable": "var(--letter-spacings-normal)"
  },
  "letterSpacings.wide": {
    "value": "0.025em",
    "variable": "var(--letter-spacings-wide)"
  },
  "letterSpacings.wider": {
    "value": "0.05em",
    "variable": "var(--letter-spacings-wider)"
  },
  "letterSpacings.widest": {
    "value": "0.1em",
    "variable": "var(--letter-spacings-widest)"
  },
  "fontSizes.2xs": {
    "value": "0.5rem",
    "variable": "var(--font-sizes-2xs)"
  },
  "fontSizes.xs": {
    "value": "0.75rem",
    "variable": "var(--font-sizes-xs)"
  },
  "fontSizes.sm": {
    "value": "0.875rem",
    "variable": "var(--font-sizes-sm)"
  },
  "fontSizes.md": {
    "value": "1rem",
    "variable": "var(--font-sizes-md)"
  },
  "fontSizes.lg": {
    "value": "1.125rem",
    "variable": "var(--font-sizes-lg)"
  },
  "fontSizes.xl": {
    "value": "1.25rem",
    "variable": "var(--font-sizes-xl)"
  },
  "fontSizes.2xl": {
    "value": "1.5rem",
    "variable": "var(--font-sizes-2xl)"
  },
  "fontSizes.3xl": {
    "value": "1.875rem",
    "variable": "var(--font-sizes-3xl)"
  },
  "fontSizes.4xl": {
    "value": "2.25rem",
    "variable": "var(--font-sizes-4xl)"
  },
  "fontSizes.5xl": {
    "value": "3rem",
    "variable": "var(--font-sizes-5xl)"
  },
  "fontSizes.6xl": {
    "value": "3.75rem",
    "variable": "var(--font-sizes-6xl)"
  },
  "fontSizes.7xl": {
    "value": "4.5rem",
    "variable": "var(--font-sizes-7xl)"
  },
  "fontSizes.8xl": {
    "value": "6rem",
    "variable": "var(--font-sizes-8xl)"
  },
  "fontSizes.9xl": {
    "value": "8rem",
    "variable": "var(--font-sizes-9xl)"
  },
  "blurs.sm": {
    "value": "4px",
    "variable": "var(--blurs-sm)"
  },
  "blurs.base": {
    "value": "8px",
    "variable": "var(--blurs-base)"
  },
  "blurs.md": {
    "value": "12px",
    "variable": "var(--blurs-md)"
  },
  "blurs.lg": {
    "value": "16px",
    "variable": "var(--blurs-lg)"
  },
  "blurs.xl": {
    "value": "24px",
    "variable": "var(--blurs-xl)"
  },
  "blurs.2xl": {
    "value": "40px",
    "variable": "var(--blurs-2xl)"
  },
  "blurs.3xl": {
    "value": "64px",
    "variable": "var(--blurs-3xl)"
  },
  "sizes.0": {
    "value": "0rem",
    "variable": "var(--sizes-0)"
  },
  "sizes.1": {
    "value": "0.25rem",
    "variable": "var(--sizes-1)"
  },
  "sizes.2": {
    "value": "0.5rem",
    "variable": "var(--sizes-2)"
  },
  "sizes.3": {
    "value": "0.75rem",
    "variable": "var(--sizes-3)"
  },
  "sizes.4": {
    "value": "1rem",
    "variable": "var(--sizes-4)"
  },
  "sizes.5": {
    "value": "1.25rem",
    "variable": "var(--sizes-5)"
  },
  "sizes.6": {
    "value": "1.5rem",
    "variable": "var(--sizes-6)"
  },
  "sizes.7": {
    "value": "1.75rem",
    "variable": "var(--sizes-7)"
  },
  "sizes.8": {
    "value": "2rem",
    "variable": "var(--sizes-8)"
  },
  "sizes.9": {
    "value": "2.25rem",
    "variable": "var(--sizes-9)"
  },
  "sizes.10": {
    "value": "2.5rem",
    "variable": "var(--sizes-10)"
  },
  "sizes.11": {
    "value": "2.75rem",
    "variable": "var(--sizes-11)"
  },
  "sizes.12": {
    "value": "3rem",
    "variable": "var(--sizes-12)"
  },
  "sizes.14": {
    "value": "3.5rem",
    "variable": "var(--sizes-14)"
  },
  "sizes.16": {
    "value": "4rem",
    "variable": "var(--sizes-16)"
  },
  "sizes.20": {
    "value": "5rem",
    "variable": "var(--sizes-20)"
  },
  "sizes.24": {
    "value": "6rem",
    "variable": "var(--sizes-24)"
  },
  "sizes.28": {
    "value": "7rem",
    "variable": "var(--sizes-28)"
  },
  "sizes.32": {
    "value": "8rem",
    "variable": "var(--sizes-32)"
  },
  "sizes.36": {
    "value": "9rem",
    "variable": "var(--sizes-36)"
  },
  "sizes.40": {
    "value": "10rem",
    "variable": "var(--sizes-40)"
  },
  "sizes.44": {
    "value": "11rem",
    "variable": "var(--sizes-44)"
  },
  "sizes.48": {
    "value": "12rem",
    "variable": "var(--sizes-48)"
  },
  "sizes.52": {
    "value": "13rem",
    "variable": "var(--sizes-52)"
  },
  "sizes.56": {
    "value": "14rem",
    "variable": "var(--sizes-56)"
  },
  "sizes.60": {
    "value": "15rem",
    "variable": "var(--sizes-60)"
  },
  "sizes.64": {
    "value": "16rem",
    "variable": "var(--sizes-64)"
  },
  "sizes.72": {
    "value": "18rem",
    "variable": "var(--sizes-72)"
  },
  "sizes.80": {
    "value": "20rem",
    "variable": "var(--sizes-80)"
  },
  "sizes.96": {
    "value": "24rem",
    "variable": "var(--sizes-96)"
  },
  "sizes.0.5": {
    "value": "0.125rem",
    "variable": "var(--sizes-0\\.5)"
  },
  "sizes.1.5": {
    "value": "0.375rem",
    "variable": "var(--sizes-1\\.5)"
  },
  "sizes.2.5": {
    "value": "0.625rem",
    "variable": "var(--sizes-2\\.5)"
  },
  "sizes.3.5": {
    "value": "0.875rem",
    "variable": "var(--sizes-3\\.5)"
  },
  "sizes.xs": {
    "value": "20rem",
    "variable": "var(--sizes-xs)"
  },
  "sizes.sm": {
    "value": "24rem",
    "variable": "var(--sizes-sm)"
  },
  "sizes.md": {
    "value": "28rem",
    "variable": "var(--sizes-md)"
  },
  "sizes.lg": {
    "value": "32rem",
    "variable": "var(--sizes-lg)"
  },
  "sizes.xl": {
    "value": "36rem",
    "variable": "var(--sizes-xl)"
  },
  "sizes.2xl": {
    "value": "42rem",
    "variable": "var(--sizes-2xl)"
  },
  "sizes.3xl": {
    "value": "48rem",
    "variable": "var(--sizes-3xl)"
  },
  "sizes.4xl": {
    "value": "56rem",
    "variable": "var(--sizes-4xl)"
  },
  "sizes.5xl": {
    "value": "64rem",
    "variable": "var(--sizes-5xl)"
  },
  "sizes.6xl": {
    "value": "72rem",
    "variable": "var(--sizes-6xl)"
  },
  "sizes.7xl": {
    "value": "80rem",
    "variable": "var(--sizes-7xl)"
  },
  "sizes.8xl": {
    "value": "90rem",
    "variable": "var(--sizes-8xl)"
  },
  "sizes.prose": {
    "value": "65ch",
    "variable": "var(--sizes-prose)"
  },
  "sizes.full": {
    "value": "100%",
    "variable": "var(--sizes-full)"
  },
  "sizes.min": {
    "value": "min-content",
    "variable": "var(--sizes-min)"
  },
  "sizes.max": {
    "value": "max-content",
    "variable": "var(--sizes-max)"
  },
  "sizes.fit": {
    "value": "fit-content",
    "variable": "var(--sizes-fit)"
  },
  "sizes.breakpoint-2xl": {
    "value": "1536px",
    "variable": "var(--sizes-breakpoint-2xl)"
  },
  "sizes.breakpoint-xl": {
    "value": "1280px",
    "variable": "var(--sizes-breakpoint-xl)"
  },
  "sizes.breakpoint-lg": {
    "value": "1024px",
    "variable": "var(--sizes-breakpoint-lg)"
  },
  "sizes.breakpoint-md": {
    "value": "768px",
    "variable": "var(--sizes-breakpoint-md)"
  },
  "sizes.breakpoint-sm": {
    "value": "320px",
    "variable": "var(--sizes-breakpoint-sm)"
  },
  "animations.spin": {
    "value": "spin 1s linear infinite",
    "variable": "var(--animations-spin)"
  },
  "animations.ping": {
    "value": "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
    "variable": "var(--animations-ping)"
  },
  "animations.pulse": {
    "value": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
    "variable": "var(--animations-pulse)"
  },
  "animations.bounce": {
    "value": "bounce 1s infinite",
    "variable": "var(--animations-bounce)"
  },
  "colors.current": {
    "value": "currentColor",
    "variable": "var(--colors-current)"
  },
  "colors.black": {
    "value": "#000",
    "variable": "var(--colors-black)"
  },
  "colors.white": {
    "value": "#fff",
    "variable": "var(--colors-white)"
  },
  "colors.transparent": {
    "value": "rgb(0 0 0 / 0)",
    "variable": "var(--colors-transparent)"
  },
  "colors.rose.50": {
    "value": "#fff1f2",
    "variable": "var(--colors-rose-50)"
  },
  "colors.rose.100": {
    "value": "#ffe4e6",
    "variable": "var(--colors-rose-100)"
  },
  "colors.rose.200": {
    "value": "#fecdd3",
    "variable": "var(--colors-rose-200)"
  },
  "colors.rose.300": {
    "value": "#fda4af",
    "variable": "var(--colors-rose-300)"
  },
  "colors.rose.400": {
    "value": "#fb7185",
    "variable": "var(--colors-rose-400)"
  },
  "colors.rose.500": {
    "value": "#f43f5e",
    "variable": "var(--colors-rose-500)"
  },
  "colors.rose.600": {
    "value": "#e11d48",
    "variable": "var(--colors-rose-600)"
  },
  "colors.rose.700": {
    "value": "#be123c",
    "variable": "var(--colors-rose-700)"
  },
  "colors.rose.800": {
    "value": "#9f1239",
    "variable": "var(--colors-rose-800)"
  },
  "colors.rose.900": {
    "value": "#881337",
    "variable": "var(--colors-rose-900)"
  },
  "colors.rose.950": {
    "value": "#4c0519",
    "variable": "var(--colors-rose-950)"
  },
  "colors.pink.50": {
    "value": "#fdf2f8",
    "variable": "var(--colors-pink-50)"
  },
  "colors.pink.100": {
    "value": "#fce7f3",
    "variable": "var(--colors-pink-100)"
  },
  "colors.pink.200": {
    "value": "#fbcfe8",
    "variable": "var(--colors-pink-200)"
  },
  "colors.pink.300": {
    "value": "#f9a8d4",
    "variable": "var(--colors-pink-300)"
  },
  "colors.pink.400": {
    "value": "#f472b6",
    "variable": "var(--colors-pink-400)"
  },
  "colors.pink.500": {
    "value": "#ec4899",
    "variable": "var(--colors-pink-500)"
  },
  "colors.pink.600": {
    "value": "#db2777",
    "variable": "var(--colors-pink-600)"
  },
  "colors.pink.700": {
    "value": "#be185d",
    "variable": "var(--colors-pink-700)"
  },
  "colors.pink.800": {
    "value": "#9d174d",
    "variable": "var(--colors-pink-800)"
  },
  "colors.pink.900": {
    "value": "#831843",
    "variable": "var(--colors-pink-900)"
  },
  "colors.pink.950": {
    "value": "#500724",
    "variable": "var(--colors-pink-950)"
  },
  "colors.fuchsia.50": {
    "value": "#fdf4ff",
    "variable": "var(--colors-fuchsia-50)"
  },
  "colors.fuchsia.100": {
    "value": "#fae8ff",
    "variable": "var(--colors-fuchsia-100)"
  },
  "colors.fuchsia.200": {
    "value": "#f5d0fe",
    "variable": "var(--colors-fuchsia-200)"
  },
  "colors.fuchsia.300": {
    "value": "#f0abfc",
    "variable": "var(--colors-fuchsia-300)"
  },
  "colors.fuchsia.400": {
    "value": "#e879f9",
    "variable": "var(--colors-fuchsia-400)"
  },
  "colors.fuchsia.500": {
    "value": "#d946ef",
    "variable": "var(--colors-fuchsia-500)"
  },
  "colors.fuchsia.600": {
    "value": "#c026d3",
    "variable": "var(--colors-fuchsia-600)"
  },
  "colors.fuchsia.700": {
    "value": "#a21caf",
    "variable": "var(--colors-fuchsia-700)"
  },
  "colors.fuchsia.800": {
    "value": "#86198f",
    "variable": "var(--colors-fuchsia-800)"
  },
  "colors.fuchsia.900": {
    "value": "#701a75",
    "variable": "var(--colors-fuchsia-900)"
  },
  "colors.fuchsia.950": {
    "value": "#4a044e",
    "variable": "var(--colors-fuchsia-950)"
  },
  "colors.purple.50": {
    "value": "#faf5ff",
    "variable": "var(--colors-purple-50)"
  },
  "colors.purple.100": {
    "value": "#f3e8ff",
    "variable": "var(--colors-purple-100)"
  },
  "colors.purple.200": {
    "value": "#e9d5ff",
    "variable": "var(--colors-purple-200)"
  },
  "colors.purple.300": {
    "value": "#d8b4fe",
    "variable": "var(--colors-purple-300)"
  },
  "colors.purple.400": {
    "value": "#c084fc",
    "variable": "var(--colors-purple-400)"
  },
  "colors.purple.500": {
    "value": "#a855f7",
    "variable": "var(--colors-purple-500)"
  },
  "colors.purple.600": {
    "value": "#9333ea",
    "variable": "var(--colors-purple-600)"
  },
  "colors.purple.700": {
    "value": "#7e22ce",
    "variable": "var(--colors-purple-700)"
  },
  "colors.purple.800": {
    "value": "#6b21a8",
    "variable": "var(--colors-purple-800)"
  },
  "colors.purple.900": {
    "value": "#581c87",
    "variable": "var(--colors-purple-900)"
  },
  "colors.purple.950": {
    "value": "#3b0764",
    "variable": "var(--colors-purple-950)"
  },
  "colors.violet.50": {
    "value": "#f5f3ff",
    "variable": "var(--colors-violet-50)"
  },
  "colors.violet.100": {
    "value": "#ede9fe",
    "variable": "var(--colors-violet-100)"
  },
  "colors.violet.200": {
    "value": "#ddd6fe",
    "variable": "var(--colors-violet-200)"
  },
  "colors.violet.300": {
    "value": "#c4b5fd",
    "variable": "var(--colors-violet-300)"
  },
  "colors.violet.400": {
    "value": "#a78bfa",
    "variable": "var(--colors-violet-400)"
  },
  "colors.violet.500": {
    "value": "#8b5cf6",
    "variable": "var(--colors-violet-500)"
  },
  "colors.violet.600": {
    "value": "#7c3aed",
    "variable": "var(--colors-violet-600)"
  },
  "colors.violet.700": {
    "value": "#6d28d9",
    "variable": "var(--colors-violet-700)"
  },
  "colors.violet.800": {
    "value": "#5b21b6",
    "variable": "var(--colors-violet-800)"
  },
  "colors.violet.900": {
    "value": "#4c1d95",
    "variable": "var(--colors-violet-900)"
  },
  "colors.violet.950": {
    "value": "#2e1065",
    "variable": "var(--colors-violet-950)"
  },
  "colors.indigo.50": {
    "value": "#eef2ff",
    "variable": "var(--colors-indigo-50)"
  },
  "colors.indigo.100": {
    "value": "#e0e7ff",
    "variable": "var(--colors-indigo-100)"
  },
  "colors.indigo.200": {
    "value": "#c7d2fe",
    "variable": "var(--colors-indigo-200)"
  },
  "colors.indigo.300": {
    "value": "#a5b4fc",
    "variable": "var(--colors-indigo-300)"
  },
  "colors.indigo.400": {
    "value": "#818cf8",
    "variable": "var(--colors-indigo-400)"
  },
  "colors.indigo.500": {
    "value": "#6366f1",
    "variable": "var(--colors-indigo-500)"
  },
  "colors.indigo.600": {
    "value": "#4f46e5",
    "variable": "var(--colors-indigo-600)"
  },
  "colors.indigo.700": {
    "value": "#4338ca",
    "variable": "var(--colors-indigo-700)"
  },
  "colors.indigo.800": {
    "value": "#3730a3",
    "variable": "var(--colors-indigo-800)"
  },
  "colors.indigo.900": {
    "value": "#312e81",
    "variable": "var(--colors-indigo-900)"
  },
  "colors.indigo.950": {
    "value": "#1e1b4b",
    "variable": "var(--colors-indigo-950)"
  },
  "colors.blue.50": {
    "value": "#eff6ff",
    "variable": "var(--colors-blue-50)"
  },
  "colors.blue.100": {
    "value": "#dbeafe",
    "variable": "var(--colors-blue-100)"
  },
  "colors.blue.200": {
    "value": "#bfdbfe",
    "variable": "var(--colors-blue-200)"
  },
  "colors.blue.300": {
    "value": "#93c5fd",
    "variable": "var(--colors-blue-300)"
  },
  "colors.blue.400": {
    "value": "#60a5fa",
    "variable": "var(--colors-blue-400)"
  },
  "colors.blue.500": {
    "value": "#3b82f6",
    "variable": "var(--colors-blue-500)"
  },
  "colors.blue.600": {
    "value": "#2563eb",
    "variable": "var(--colors-blue-600)"
  },
  "colors.blue.700": {
    "value": "#1d4ed8",
    "variable": "var(--colors-blue-700)"
  },
  "colors.blue.800": {
    "value": "#1e40af",
    "variable": "var(--colors-blue-800)"
  },
  "colors.blue.900": {
    "value": "#1e3a8a",
    "variable": "var(--colors-blue-900)"
  },
  "colors.blue.950": {
    "value": "#172554",
    "variable": "var(--colors-blue-950)"
  },
  "colors.sky.50": {
    "value": "#f0f9ff",
    "variable": "var(--colors-sky-50)"
  },
  "colors.sky.100": {
    "value": "#e0f2fe",
    "variable": "var(--colors-sky-100)"
  },
  "colors.sky.200": {
    "value": "#bae6fd",
    "variable": "var(--colors-sky-200)"
  },
  "colors.sky.300": {
    "value": "#7dd3fc",
    "variable": "var(--colors-sky-300)"
  },
  "colors.sky.400": {
    "value": "#38bdf8",
    "variable": "var(--colors-sky-400)"
  },
  "colors.sky.500": {
    "value": "#0ea5e9",
    "variable": "var(--colors-sky-500)"
  },
  "colors.sky.600": {
    "value": "#0284c7",
    "variable": "var(--colors-sky-600)"
  },
  "colors.sky.700": {
    "value": "#0369a1",
    "variable": "var(--colors-sky-700)"
  },
  "colors.sky.800": {
    "value": "#075985",
    "variable": "var(--colors-sky-800)"
  },
  "colors.sky.900": {
    "value": "#0c4a6e",
    "variable": "var(--colors-sky-900)"
  },
  "colors.sky.950": {
    "value": "#082f49",
    "variable": "var(--colors-sky-950)"
  },
  "colors.cyan.50": {
    "value": "#ecfeff",
    "variable": "var(--colors-cyan-50)"
  },
  "colors.cyan.100": {
    "value": "#cffafe",
    "variable": "var(--colors-cyan-100)"
  },
  "colors.cyan.200": {
    "value": "#a5f3fc",
    "variable": "var(--colors-cyan-200)"
  },
  "colors.cyan.300": {
    "value": "#67e8f9",
    "variable": "var(--colors-cyan-300)"
  },
  "colors.cyan.400": {
    "value": "#22d3ee",
    "variable": "var(--colors-cyan-400)"
  },
  "colors.cyan.500": {
    "value": "#06b6d4",
    "variable": "var(--colors-cyan-500)"
  },
  "colors.cyan.600": {
    "value": "#0891b2",
    "variable": "var(--colors-cyan-600)"
  },
  "colors.cyan.700": {
    "value": "#0e7490",
    "variable": "var(--colors-cyan-700)"
  },
  "colors.cyan.800": {
    "value": "#155e75",
    "variable": "var(--colors-cyan-800)"
  },
  "colors.cyan.900": {
    "value": "#164e63",
    "variable": "var(--colors-cyan-900)"
  },
  "colors.cyan.950": {
    "value": "#083344",
    "variable": "var(--colors-cyan-950)"
  },
  "colors.teal.50": {
    "value": "#f0fdfa",
    "variable": "var(--colors-teal-50)"
  },
  "colors.teal.100": {
    "value": "#ccfbf1",
    "variable": "var(--colors-teal-100)"
  },
  "colors.teal.200": {
    "value": "#99f6e4",
    "variable": "var(--colors-teal-200)"
  },
  "colors.teal.300": {
    "value": "#5eead4",
    "variable": "var(--colors-teal-300)"
  },
  "colors.teal.400": {
    "value": "#2dd4bf",
    "variable": "var(--colors-teal-400)"
  },
  "colors.teal.500": {
    "value": "#14b8a6",
    "variable": "var(--colors-teal-500)"
  },
  "colors.teal.600": {
    "value": "#0d9488",
    "variable": "var(--colors-teal-600)"
  },
  "colors.teal.700": {
    "value": "#0f766e",
    "variable": "var(--colors-teal-700)"
  },
  "colors.teal.800": {
    "value": "#115e59",
    "variable": "var(--colors-teal-800)"
  },
  "colors.teal.900": {
    "value": "#134e4a",
    "variable": "var(--colors-teal-900)"
  },
  "colors.teal.950": {
    "value": "#042f2e",
    "variable": "var(--colors-teal-950)"
  },
  "colors.emerald.50": {
    "value": "#ecfdf5",
    "variable": "var(--colors-emerald-50)"
  },
  "colors.emerald.100": {
    "value": "#d1fae5",
    "variable": "var(--colors-emerald-100)"
  },
  "colors.emerald.200": {
    "value": "#a7f3d0",
    "variable": "var(--colors-emerald-200)"
  },
  "colors.emerald.300": {
    "value": "#6ee7b7",
    "variable": "var(--colors-emerald-300)"
  },
  "colors.emerald.400": {
    "value": "#34d399",
    "variable": "var(--colors-emerald-400)"
  },
  "colors.emerald.500": {
    "value": "#10b981",
    "variable": "var(--colors-emerald-500)"
  },
  "colors.emerald.600": {
    "value": "#059669",
    "variable": "var(--colors-emerald-600)"
  },
  "colors.emerald.700": {
    "value": "#047857",
    "variable": "var(--colors-emerald-700)"
  },
  "colors.emerald.800": {
    "value": "#065f46",
    "variable": "var(--colors-emerald-800)"
  },
  "colors.emerald.900": {
    "value": "#064e3b",
    "variable": "var(--colors-emerald-900)"
  },
  "colors.emerald.950": {
    "value": "#022c22",
    "variable": "var(--colors-emerald-950)"
  },
  "colors.green.50": {
    "value": "#f0fdf4",
    "variable": "var(--colors-green-50)"
  },
  "colors.green.100": {
    "value": "#dcfce7",
    "variable": "var(--colors-green-100)"
  },
  "colors.green.200": {
    "value": "#bbf7d0",
    "variable": "var(--colors-green-200)"
  },
  "colors.green.300": {
    "value": "#86efac",
    "variable": "var(--colors-green-300)"
  },
  "colors.green.400": {
    "value": "#4ade80",
    "variable": "var(--colors-green-400)"
  },
  "colors.green.500": {
    "value": "#22c55e",
    "variable": "var(--colors-green-500)"
  },
  "colors.green.600": {
    "value": "#16a34a",
    "variable": "var(--colors-green-600)"
  },
  "colors.green.700": {
    "value": "#15803d",
    "variable": "var(--colors-green-700)"
  },
  "colors.green.800": {
    "value": "#166534",
    "variable": "var(--colors-green-800)"
  },
  "colors.green.900": {
    "value": "#14532d",
    "variable": "var(--colors-green-900)"
  },
  "colors.green.950": {
    "value": "#052e16",
    "variable": "var(--colors-green-950)"
  },
  "colors.lime.50": {
    "value": "#f7fee7",
    "variable": "var(--colors-lime-50)"
  },
  "colors.lime.100": {
    "value": "#ecfccb",
    "variable": "var(--colors-lime-100)"
  },
  "colors.lime.200": {
    "value": "#d9f99d",
    "variable": "var(--colors-lime-200)"
  },
  "colors.lime.300": {
    "value": "#bef264",
    "variable": "var(--colors-lime-300)"
  },
  "colors.lime.400": {
    "value": "#a3e635",
    "variable": "var(--colors-lime-400)"
  },
  "colors.lime.500": {
    "value": "#84cc16",
    "variable": "var(--colors-lime-500)"
  },
  "colors.lime.600": {
    "value": "#65a30d",
    "variable": "var(--colors-lime-600)"
  },
  "colors.lime.700": {
    "value": "#4d7c0f",
    "variable": "var(--colors-lime-700)"
  },
  "colors.lime.800": {
    "value": "#3f6212",
    "variable": "var(--colors-lime-800)"
  },
  "colors.lime.900": {
    "value": "#365314",
    "variable": "var(--colors-lime-900)"
  },
  "colors.lime.950": {
    "value": "#1a2e05",
    "variable": "var(--colors-lime-950)"
  },
  "colors.yellow.50": {
    "value": "#fefce8",
    "variable": "var(--colors-yellow-50)"
  },
  "colors.yellow.100": {
    "value": "#fef9c3",
    "variable": "var(--colors-yellow-100)"
  },
  "colors.yellow.200": {
    "value": "#fef08a",
    "variable": "var(--colors-yellow-200)"
  },
  "colors.yellow.300": {
    "value": "#fde047",
    "variable": "var(--colors-yellow-300)"
  },
  "colors.yellow.400": {
    "value": "#facc15",
    "variable": "var(--colors-yellow-400)"
  },
  "colors.yellow.500": {
    "value": "#eab308",
    "variable": "var(--colors-yellow-500)"
  },
  "colors.yellow.600": {
    "value": "#ca8a04",
    "variable": "var(--colors-yellow-600)"
  },
  "colors.yellow.700": {
    "value": "#a16207",
    "variable": "var(--colors-yellow-700)"
  },
  "colors.yellow.800": {
    "value": "#854d0e",
    "variable": "var(--colors-yellow-800)"
  },
  "colors.yellow.900": {
    "value": "#713f12",
    "variable": "var(--colors-yellow-900)"
  },
  "colors.yellow.950": {
    "value": "#422006",
    "variable": "var(--colors-yellow-950)"
  },
  "colors.amber.50": {
    "value": "#fffbeb",
    "variable": "var(--colors-amber-50)"
  },
  "colors.amber.100": {
    "value": "#fef3c7",
    "variable": "var(--colors-amber-100)"
  },
  "colors.amber.200": {
    "value": "#fde68a",
    "variable": "var(--colors-amber-200)"
  },
  "colors.amber.300": {
    "value": "#fcd34d",
    "variable": "var(--colors-amber-300)"
  },
  "colors.amber.400": {
    "value": "#fbbf24",
    "variable": "var(--colors-amber-400)"
  },
  "colors.amber.500": {
    "value": "#f59e0b",
    "variable": "var(--colors-amber-500)"
  },
  "colors.amber.600": {
    "value": "#d97706",
    "variable": "var(--colors-amber-600)"
  },
  "colors.amber.700": {
    "value": "#b45309",
    "variable": "var(--colors-amber-700)"
  },
  "colors.amber.800": {
    "value": "#92400e",
    "variable": "var(--colors-amber-800)"
  },
  "colors.amber.900": {
    "value": "#78350f",
    "variable": "var(--colors-amber-900)"
  },
  "colors.amber.950": {
    "value": "#451a03",
    "variable": "var(--colors-amber-950)"
  },
  "colors.orange.50": {
    "value": "#fff7ed",
    "variable": "var(--colors-orange-50)"
  },
  "colors.orange.100": {
    "value": "#ffedd5",
    "variable": "var(--colors-orange-100)"
  },
  "colors.orange.200": {
    "value": "#fed7aa",
    "variable": "var(--colors-orange-200)"
  },
  "colors.orange.300": {
    "value": "#fdba74",
    "variable": "var(--colors-orange-300)"
  },
  "colors.orange.400": {
    "value": "#fb923c",
    "variable": "var(--colors-orange-400)"
  },
  "colors.orange.500": {
    "value": "#f97316",
    "variable": "var(--colors-orange-500)"
  },
  "colors.orange.600": {
    "value": "#ea580c",
    "variable": "var(--colors-orange-600)"
  },
  "colors.orange.700": {
    "value": "#c2410c",
    "variable": "var(--colors-orange-700)"
  },
  "colors.orange.800": {
    "value": "#9a3412",
    "variable": "var(--colors-orange-800)"
  },
  "colors.orange.900": {
    "value": "#7c2d12",
    "variable": "var(--colors-orange-900)"
  },
  "colors.orange.950": {
    "value": "#431407",
    "variable": "var(--colors-orange-950)"
  },
  "colors.red.50": {
    "value": "#fef2f2",
    "variable": "var(--colors-red-50)"
  },
  "colors.red.100": {
    "value": "#fee2e2",
    "variable": "var(--colors-red-100)"
  },
  "colors.red.200": {
    "value": "#fecaca",
    "variable": "var(--colors-red-200)"
  },
  "colors.red.300": {
    "value": "#fca5a5",
    "variable": "var(--colors-red-300)"
  },
  "colors.red.400": {
    "value": "#f87171",
    "variable": "var(--colors-red-400)"
  },
  "colors.red.500": {
    "value": "#ef4444",
    "variable": "var(--colors-red-500)"
  },
  "colors.red.600": {
    "value": "#dc2626",
    "variable": "var(--colors-red-600)"
  },
  "colors.red.700": {
    "value": "#b91c1c",
    "variable": "var(--colors-red-700)"
  },
  "colors.red.800": {
    "value": "#991b1b",
    "variable": "var(--colors-red-800)"
  },
  "colors.red.900": {
    "value": "#7f1d1d",
    "variable": "var(--colors-red-900)"
  },
  "colors.red.950": {
    "value": "#450a0a",
    "variable": "var(--colors-red-950)"
  },
  "colors.neutral.50": {
    "value": "#fafafa",
    "variable": "var(--colors-neutral-50)"
  },
  "colors.neutral.100": {
    "value": "#f5f5f5",
    "variable": "var(--colors-neutral-100)"
  },
  "colors.neutral.200": {
    "value": "#e5e5e5",
    "variable": "var(--colors-neutral-200)"
  },
  "colors.neutral.300": {
    "value": "#d4d4d4",
    "variable": "var(--colors-neutral-300)"
  },
  "colors.neutral.400": {
    "value": "#a3a3a3",
    "variable": "var(--colors-neutral-400)"
  },
  "colors.neutral.500": {
    "value": "#737373",
    "variable": "var(--colors-neutral-500)"
  },
  "colors.neutral.600": {
    "value": "#525252",
    "variable": "var(--colors-neutral-600)"
  },
  "colors.neutral.700": {
    "value": "#404040",
    "variable": "var(--colors-neutral-700)"
  },
  "colors.neutral.800": {
    "value": "#262626",
    "variable": "var(--colors-neutral-800)"
  },
  "colors.neutral.900": {
    "value": "#171717",
    "variable": "var(--colors-neutral-900)"
  },
  "colors.neutral.950": {
    "value": "#0a0a0a",
    "variable": "var(--colors-neutral-950)"
  },
  "colors.stone.50": {
    "value": "#fafaf9",
    "variable": "var(--colors-stone-50)"
  },
  "colors.stone.100": {
    "value": "#f5f5f4",
    "variable": "var(--colors-stone-100)"
  },
  "colors.stone.200": {
    "value": "#e7e5e4",
    "variable": "var(--colors-stone-200)"
  },
  "colors.stone.300": {
    "value": "#d6d3d1",
    "variable": "var(--colors-stone-300)"
  },
  "colors.stone.400": {
    "value": "#a8a29e",
    "variable": "var(--colors-stone-400)"
  },
  "colors.stone.500": {
    "value": "#78716c",
    "variable": "var(--colors-stone-500)"
  },
  "colors.stone.600": {
    "value": "#57534e",
    "variable": "var(--colors-stone-600)"
  },
  "colors.stone.700": {
    "value": "#44403c",
    "variable": "var(--colors-stone-700)"
  },
  "colors.stone.800": {
    "value": "#292524",
    "variable": "var(--colors-stone-800)"
  },
  "colors.stone.900": {
    "value": "#1c1917",
    "variable": "var(--colors-stone-900)"
  },
  "colors.stone.950": {
    "value": "#0c0a09",
    "variable": "var(--colors-stone-950)"
  },
  "colors.zinc.50": {
    "value": "#fafafa",
    "variable": "var(--colors-zinc-50)"
  },
  "colors.zinc.100": {
    "value": "#f4f4f5",
    "variable": "var(--colors-zinc-100)"
  },
  "colors.zinc.200": {
    "value": "#e4e4e7",
    "variable": "var(--colors-zinc-200)"
  },
  "colors.zinc.300": {
    "value": "#d4d4d8",
    "variable": "var(--colors-zinc-300)"
  },
  "colors.zinc.400": {
    "value": "#a1a1aa",
    "variable": "var(--colors-zinc-400)"
  },
  "colors.zinc.500": {
    "value": "#71717a",
    "variable": "var(--colors-zinc-500)"
  },
  "colors.zinc.600": {
    "value": "#52525b",
    "variable": "var(--colors-zinc-600)"
  },
  "colors.zinc.700": {
    "value": "#3f3f46",
    "variable": "var(--colors-zinc-700)"
  },
  "colors.zinc.800": {
    "value": "#27272a",
    "variable": "var(--colors-zinc-800)"
  },
  "colors.zinc.900": {
    "value": "#18181b",
    "variable": "var(--colors-zinc-900)"
  },
  "colors.zinc.950": {
    "value": "#09090b",
    "variable": "var(--colors-zinc-950)"
  },
  "colors.gray.50": {
    "value": "#f9fafb",
    "variable": "var(--colors-gray-50)"
  },
  "colors.gray.100": {
    "value": "#f3f4f6",
    "variable": "var(--colors-gray-100)"
  },
  "colors.gray.200": {
    "value": "#e5e7eb",
    "variable": "var(--colors-gray-200)"
  },
  "colors.gray.300": {
    "value": "#d1d5db",
    "variable": "var(--colors-gray-300)"
  },
  "colors.gray.400": {
    "value": "#9ca3af",
    "variable": "var(--colors-gray-400)"
  },
  "colors.gray.500": {
    "value": "#6b7280",
    "variable": "var(--colors-gray-500)"
  },
  "colors.gray.600": {
    "value": "#4b5563",
    "variable": "var(--colors-gray-600)"
  },
  "colors.gray.700": {
    "value": "#374151",
    "variable": "var(--colors-gray-700)"
  },
  "colors.gray.800": {
    "value": "#1f2937",
    "variable": "var(--colors-gray-800)"
  },
  "colors.gray.900": {
    "value": "#111827",
    "variable": "var(--colors-gray-900)"
  },
  "colors.gray.950": {
    "value": "#030712",
    "variable": "var(--colors-gray-950)"
  },
  "colors.slate.50": {
    "value": "#f8fafc",
    "variable": "var(--colors-slate-50)"
  },
  "colors.slate.100": {
    "value": "#f1f5f9",
    "variable": "var(--colors-slate-100)"
  },
  "colors.slate.200": {
    "value": "#e2e8f0",
    "variable": "var(--colors-slate-200)"
  },
  "colors.slate.300": {
    "value": "#cbd5e1",
    "variable": "var(--colors-slate-300)"
  },
  "colors.slate.400": {
    "value": "#94a3b8",
    "variable": "var(--colors-slate-400)"
  },
  "colors.slate.500": {
    "value": "#64748b",
    "variable": "var(--colors-slate-500)"
  },
  "colors.slate.600": {
    "value": "#475569",
    "variable": "var(--colors-slate-600)"
  },
  "colors.slate.700": {
    "value": "#334155",
    "variable": "var(--colors-slate-700)"
  },
  "colors.slate.800": {
    "value": "#1e293b",
    "variable": "var(--colors-slate-800)"
  },
  "colors.slate.900": {
    "value": "#0f172a",
    "variable": "var(--colors-slate-900)"
  },
  "colors.slate.950": {
    "value": "#020617",
    "variable": "var(--colors-slate-950)"
  },
  "colors.base.neutrals.0": {
    "value": "#FFFFFF",
    "variable": "var(--colors-base-neutrals-0)"
  },
  "colors.base.neutrals.10": {
    "value": "#FAFAFA",
    "variable": "var(--colors-base-neutrals-10)"
  },
  "colors.base.neutrals.20": {
    "value": "#F2F2F2",
    "variable": "var(--colors-base-neutrals-20)"
  },
  "colors.base.neutrals.30": {
    "value": "#E3E3E3",
    "variable": "var(--colors-base-neutrals-30)"
  },
  "colors.base.neutrals.40": {
    "value": "#D0D1D1",
    "variable": "var(--colors-base-neutrals-40)"
  },
  "colors.base.neutrals.60": {
    "value": "#929292",
    "variable": "var(--colors-base-neutrals-60)"
  },
  "colors.base.neutrals.70": {
    "value": "#757575",
    "variable": "var(--colors-base-neutrals-70)"
  },
  "colors.base.neutrals.80": {
    "value": "#585858",
    "variable": "var(--colors-base-neutrals-80)"
  },
  "colors.base.neutrals.90": {
    "value": "#373A36",
    "variable": "var(--colors-base-neutrals-90)"
  },
  "colors.base.neutrals.100": {
    "value": "#000000",
    "variable": "var(--colors-base-neutrals-100)"
  },
  "colors.base.neutrals.100-8": {
    "value": "#00000014",
    "variable": "var(--colors-base-neutrals-100-8)"
  },
  "colors.base.neutrals.100-12": {
    "value": "#0000001F",
    "variable": "var(--colors-base-neutrals-100-12)"
  },
  "colors.base.neutrals.100-30": {
    "value": "#0000004D",
    "variable": "var(--colors-base-neutrals-100-30)"
  },
  "colors.base.neutrals.100-50": {
    "value": "#00000080",
    "variable": "var(--colors-base-neutrals-100-50)"
  },
  "colors.base.neutrals.90-20": {
    "value": "#373A3633",
    "variable": "var(--colors-base-neutrals-90-20)"
  },
  "colors.base.neutrals.90-30": {
    "value": "#373A364D",
    "variable": "var(--colors-base-neutrals-90-30)"
  },
  "colors.base.neutrals.90-80": {
    "value": "#373A36CC",
    "variable": "var(--colors-base-neutrals-90-80)"
  },
  "colors.base.neutrals.0-0": {
    "value": "#FFFFFF00",
    "variable": "var(--colors-base-neutrals-0-0)"
  },
  "colors.base.neutrals.0-8": {
    "value": "#FFFFFF14",
    "variable": "var(--colors-base-neutrals-0-8)"
  },
  "colors.base.neutrals.0-15": {
    "value": "#FFFFFF26",
    "variable": "var(--colors-base-neutrals-0-15)"
  },
  "colors.base.neutrals.0-30": {
    "value": "#FFFFFF4D",
    "variable": "var(--colors-base-neutrals-0-30)"
  },
  "colors.base.neutrals.0-50": {
    "value": "#FFFFFF80",
    "variable": "var(--colors-base-neutrals-0-50)"
  },
  "colors.base.sand.20": {
    "value": "#F8F7F3",
    "variable": "var(--colors-base-sand-20)"
  },
  "colors.base.sand.30": {
    "value": "#F0EDE4",
    "variable": "var(--colors-base-sand-30)"
  },
  "colors.base.sand.40": {
    "value": "#E1DCCA",
    "variable": "var(--colors-base-sand-40)"
  },
  "colors.base.sand.50": {
    "value": "#C9C4B5",
    "variable": "var(--colors-base-sand-50)"
  },
  "colors.base.sand.60": {
    "value": "#9E9A8E",
    "variable": "var(--colors-base-sand-60)"
  },
  "colors.base.sand.70": {
    "value": "#807B6F",
    "variable": "var(--colors-base-sand-70)"
  },
  "colors.base.sand.80": {
    "value": "#656258",
    "variable": "var(--colors-base-sand-80)"
  },
  "colors.base.sand.90": {
    "value": "#4D4A44",
    "variable": "var(--colors-base-sand-90)"
  },
  "colors.base.sand.100": {
    "value": "#33322F",
    "variable": "var(--colors-base-sand-100)"
  },
  "colors.base.amethyst.10": {
    "value": "#F8F0FA",
    "variable": "var(--colors-base-amethyst-10)"
  },
  "colors.base.amethyst.20": {
    "value": "#EDDDF0",
    "variable": "var(--colors-base-amethyst-20)"
  },
  "colors.base.amethyst.30": {
    "value": "#DBBDE0",
    "variable": "var(--colors-base-amethyst-30)"
  },
  "colors.base.amethyst.40": {
    "value": "#C694D1",
    "variable": "var(--colors-base-amethyst-40)"
  },
  "colors.base.amethyst.50": {
    "value": "#B466C4",
    "variable": "var(--colors-base-amethyst-50)"
  },
  "colors.base.amethyst.60": {
    "value": "#882F9C",
    "variable": "var(--colors-base-amethyst-60)"
  },
  "colors.base.amethyst.70": {
    "value": "#702082",
    "variable": "var(--colors-base-amethyst-70)"
  },
  "colors.base.amethyst.80": {
    "value": "#4F165B",
    "variable": "var(--colors-base-amethyst-80)"
  },
  "colors.base.amethyst.90": {
    "value": "#3A1249",
    "variable": "var(--colors-base-amethyst-90)"
  },
  "colors.base.amethyst.100": {
    "value": "#1E0627",
    "variable": "var(--colors-base-amethyst-100)"
  },
  "colors.base.amethyst.80-30": {
    "value": "#4F165B4D",
    "variable": "var(--colors-base-amethyst-80-30)"
  },
  "colors.evolution.aquamarine.10": {
    "value": "#E5F7FA",
    "variable": "var(--colors-evolution-aquamarine-10)"
  },
  "colors.evolution.aquamarine.20": {
    "value": "#C4EDF5",
    "variable": "var(--colors-evolution-aquamarine-20)"
  },
  "colors.evolution.aquamarine.30": {
    "value": "#ADDCE6",
    "variable": "var(--colors-evolution-aquamarine-30)"
  },
  "colors.evolution.aquamarine.40": {
    "value": "#78D2E5",
    "variable": "var(--colors-evolution-aquamarine-40)"
  },
  "colors.evolution.aquamarine.50": {
    "value": "#54B7CC",
    "variable": "var(--colors-evolution-aquamarine-50)"
  },
  "colors.evolution.aquamarine.60": {
    "value": "#349EB2",
    "variable": "var(--colors-evolution-aquamarine-60)"
  },
  "colors.evolution.aquamarine.70": {
    "value": "#287D96",
    "variable": "var(--colors-evolution-aquamarine-70)"
  },
  "colors.evolution.aquamarine.80": {
    "value": "#126070",
    "variable": "var(--colors-evolution-aquamarine-80)"
  },
  "colors.evolution.aquamarine.90": {
    "value": "#0B3D47",
    "variable": "var(--colors-evolution-aquamarine-90)"
  },
  "colors.evolution.aquamarine.100": {
    "value": "#041D22",
    "variable": "var(--colors-evolution-aquamarine-100)"
  },
  "colors.evolution.citrine.10": {
    "value": "#FFFAD8",
    "variable": "var(--colors-evolution-citrine-10)"
  },
  "colors.evolution.citrine.20": {
    "value": "#FFEEA3",
    "variable": "var(--colors-evolution-citrine-20)"
  },
  "colors.evolution.citrine.30": {
    "value": "#FFE566",
    "variable": "var(--colors-evolution-citrine-30)"
  },
  "colors.evolution.citrine.40": {
    "value": "#FFDB33",
    "variable": "var(--colors-evolution-citrine-40)"
  },
  "colors.evolution.citrine.50": {
    "value": "#EDC71F",
    "variable": "var(--colors-evolution-citrine-50)"
  },
  "colors.evolution.citrine.60": {
    "value": "#DEB810",
    "variable": "var(--colors-evolution-citrine-60)"
  },
  "colors.evolution.citrine.70": {
    "value": "#CCAA00",
    "variable": "var(--colors-evolution-citrine-70)"
  },
  "colors.evolution.citrine.80": {
    "value": "#997D00",
    "variable": "var(--colors-evolution-citrine-80)"
  },
  "colors.evolution.citrine.90": {
    "value": "#665300",
    "variable": "var(--colors-evolution-citrine-90)"
  },
  "colors.evolution.citrine.100": {
    "value": "#332A00",
    "variable": "var(--colors-evolution-citrine-100)"
  },
  "colors.evolution.coral.10": {
    "value": "#FFF2F5",
    "variable": "var(--colors-evolution-coral-10)"
  },
  "colors.evolution.coral.20": {
    "value": "#FDCFDA",
    "variable": "var(--colors-evolution-coral-20)"
  },
  "colors.evolution.coral.30": {
    "value": "#F8AABD",
    "variable": "var(--colors-evolution-coral-30)"
  },
  "colors.evolution.coral.40": {
    "value": "#FB6F90",
    "variable": "var(--colors-evolution-coral-40)"
  },
  "colors.evolution.coral.50": {
    "value": "#E05172",
    "variable": "var(--colors-evolution-coral-50)"
  },
  "colors.evolution.coral.60": {
    "value": "#C73859",
    "variable": "var(--colors-evolution-coral-60)"
  },
  "colors.evolution.coral.70": {
    "value": "#B0235F",
    "variable": "var(--colors-evolution-coral-70)"
  },
  "colors.evolution.coral.80": {
    "value": "#911432",
    "variable": "var(--colors-evolution-coral-80)"
  },
  "colors.evolution.coral.90": {
    "value": "#730922",
    "variable": "var(--colors-evolution-coral-90)"
  },
  "colors.evolution.coral.100": {
    "value": "#540315",
    "variable": "var(--colors-evolution-coral-100)"
  },
  "colors.secondary.emerald.20": {
    "value": "#DBF0EE",
    "variable": "var(--colors-secondary-emerald-20)"
  },
  "colors.secondary.emerald.40": {
    "value": "#7FD1CC",
    "variable": "var(--colors-secondary-emerald-40)"
  },
  "colors.secondary.emerald.50": {
    "value": "#39BFB7",
    "variable": "var(--colors-secondary-emerald-50)"
  },
  "colors.secondary.emerald.60": {
    "value": "#00A499",
    "variable": "var(--colors-secondary-emerald-60)"
  },
  "colors.secondary.emerald.70": {
    "value": "#00736A",
    "variable": "var(--colors-secondary-emerald-70)"
  },
  "colors.secondary.amethyst.40": {
    "value": "#B78FC0",
    "variable": "var(--colors-secondary-amethyst-40)"
  },
  "colors.secondary.amethyst.60": {
    "value": "#702082",
    "variable": "var(--colors-secondary-amethyst-60)"
  },
  "colors.secondary.amethyst.70": {
    "value": "#4F165B",
    "variable": "var(--colors-secondary-amethyst-70)"
  },
  "colors.secondary.amber.30": {
    "value": "#F4B966",
    "variable": "var(--colors-secondary-amber-30)"
  },
  "colors.secondary.amber.50": {
    "value": "#ED8B00",
    "variable": "var(--colors-secondary-amber-50)"
  },
  "colors.secondary.amber.70": {
    "value": "#A66200",
    "variable": "var(--colors-secondary-amber-70)"
  },
  "colors.secondary.amber.50-30": {
    "value": "#ED8B004D",
    "variable": "var(--colors-secondary-amber-50-30)"
  },
  "colors.secondary.nucleusBlue.10": {
    "value": "#CDE7F4",
    "variable": "var(--colors-secondary-nucleus-blue-10)"
  },
  "colors.secondary.nucleusBlue.20": {
    "value": "#ACD1E6",
    "variable": "var(--colors-secondary-nucleus-blue-20)"
  },
  "colors.secondary.nucleusBlue.30": {
    "value": "#7FB0CB",
    "variable": "var(--colors-secondary-nucleus-blue-30)"
  },
  "colors.secondary.nucleusBlue.60": {
    "value": "#006298",
    "variable": "var(--colors-secondary-nucleus-blue-60)"
  },
  "colors.secondary.nucleusBlue.70": {
    "value": "#00456B",
    "variable": "var(--colors-secondary-nucleus-blue-70)"
  },
  "colors.secondary.nucleusBlue.10-30": {
    "value": "#CDE7F44D",
    "variable": "var(--colors-secondary-nucleus-blue-10-30)"
  },
  "colors.signal.green.20": {
    "value": "#DFF7DF",
    "variable": "var(--colors-signal-green-20)"
  },
  "colors.signal.green.50": {
    "value": "#4CB85C",
    "variable": "var(--colors-signal-green-50)"
  },
  "colors.signal.green.60": {
    "value": "#009A17",
    "variable": "var(--colors-signal-green-60)"
  },
  "colors.signal.green.70": {
    "value": "#007B12",
    "variable": "var(--colors-signal-green-70)"
  },
  "colors.signal.yellow.20": {
    "value": "#FCF6D8",
    "variable": "var(--colors-signal-yellow-20)"
  },
  "colors.signal.yellow.50": {
    "value": "#FFD400",
    "variable": "var(--colors-signal-yellow-50)"
  },
  "colors.signal.red.10": {
    "value": "#FDEEF0",
    "variable": "var(--colors-signal-red-10)"
  },
  "colors.signal.red.20": {
    "value": "#F9D9DC",
    "variable": "var(--colors-signal-red-20)"
  },
  "colors.signal.red.50": {
    "value": "#E24C5E",
    "variable": "var(--colors-signal-red-50)"
  },
  "colors.signal.red.60": {
    "value": "#D50032",
    "variable": "var(--colors-signal-red-60)"
  },
  "colors.signal.red.70": {
    "value": "#AA0028",
    "variable": "var(--colors-signal-red-70)"
  },
  "colors.signal.red.50-30": {
    "value": "#E24C5E4D",
    "variable": "var(--colors-signal-red-50-30)"
  },
  "colors.lyra.green.10": {
    "value": "#F2FFF2",
    "variable": "var(--colors-lyra-green-10)"
  },
  "colors.lyra.green.20": {
    "value": "#DFF7DF",
    "variable": "var(--colors-lyra-green-20)"
  },
  "colors.lyra.green.30": {
    "value": "#ACE5B5",
    "variable": "var(--colors-lyra-green-30)"
  },
  "colors.lyra.green.40": {
    "value": "#74CC81",
    "variable": "var(--colors-lyra-green-40)"
  },
  "colors.lyra.green.50": {
    "value": "#4CB85C",
    "variable": "var(--colors-lyra-green-50)"
  },
  "colors.lyra.green.60": {
    "value": "#009A17",
    "variable": "var(--colors-lyra-green-60)"
  },
  "colors.lyra.green.70": {
    "value": "#007B12",
    "variable": "var(--colors-lyra-green-70)"
  },
  "colors.lyra.green.80": {
    "value": "#005C0E",
    "variable": "var(--colors-lyra-green-80)"
  },
  "colors.lyra.green.90": {
    "value": "#003D09",
    "variable": "var(--colors-lyra-green-90)"
  },
  "colors.lyra.green.100": {
    "value": "#001F05",
    "variable": "var(--colors-lyra-green-100)"
  },
  "colors.lyra.green.100-8": {
    "value": "#001F0514",
    "variable": "var(--colors-lyra-green-100-8)"
  },
  "colors.lyra.green.100-12": {
    "value": "#001F051F",
    "variable": "var(--colors-lyra-green-100-12)"
  },
  "colors.lyra.green.90-30": {
    "value": "#003D094D",
    "variable": "var(--colors-lyra-green-90-30)"
  },
  "colors.lyra.green.80-30": {
    "value": "#005C0E4D",
    "variable": "var(--colors-lyra-green-80-30)"
  },
  "colors.lyra.gold.10": {
    "value": "#FAF7EE",
    "variable": "var(--colors-lyra-gold-10)"
  },
  "colors.lyra.gold.20": {
    "value": "#F2EBD3",
    "variable": "var(--colors-lyra-gold-20)"
  },
  "colors.lyra.gold.30": {
    "value": "#EAD79A",
    "variable": "var(--colors-lyra-gold-30)"
  },
  "colors.lyra.gold.40": {
    "value": "#DFC368",
    "variable": "var(--colors-lyra-gold-40)"
  },
  "colors.lyra.gold.50": {
    "value": "#D4AF36",
    "variable": "var(--colors-lyra-gold-50)"
  },
  "colors.lyra.gold.60": {
    "value": "#A88924",
    "variable": "var(--colors-lyra-gold-60)"
  },
  "colors.lyra.gold.70": {
    "value": "#756019",
    "variable": "var(--colors-lyra-gold-70)"
  },
  "colors.lyra.gold.80": {
    "value": "#5E4D14",
    "variable": "var(--colors-lyra-gold-80)"
  },
  "colors.lyra.gold.90": {
    "value": "#4A3C10",
    "variable": "var(--colors-lyra-gold-90)"
  },
  "colors.lyra.gold.100": {
    "value": "#33290B",
    "variable": "var(--colors-lyra-gold-100)"
  },
  "colors.lyra.gold.100-30": {
    "value": "#33290B4D",
    "variable": "var(--colors-lyra-gold-100-30)"
  },
  "colors.lyra.gold.60-15": {
    "value": "#A8892426",
    "variable": "var(--colors-lyra-gold-60-15)"
  },
  "colors.lyra.gold.60-30": {
    "value": "#A889244D",
    "variable": "var(--colors-lyra-gold-60-30)"
  },
  "colors.lyra.gold.10-30": {
    "value": "#FAF7EE4D",
    "variable": "var(--colors-lyra-gold-10-30)"
  },
  "gradients.image.light": {
    "value": "linear-gradient(to right, rgba(240, 237, 228, 0.7) 0%, rgba(240, 237, 228, 0.6) 24.79%, rgba(240, 237, 228, 0.45) 34.79%, rgba(240, 237, 228, 0.30) 54.79%, rgba(240, 237, 228, 0) 100%)",
    "variable": "var(--gradients-image-light)"
  },
  "gradients.image.dark": {
    "value": "linear-gradient(to right,  #373A36 0%, rgba(55, 58, 54, 0.78) 32.29%, rgba(55, 58, 54, 0) 100%)",
    "variable": "var(--gradients-image-dark)"
  },
  "fonts.mono": {
    "value": "ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, \"Liberation Mono\", \"Courier New\", monospace",
    "variable": "var(--fonts-mono)"
  },
  "fonts.serif": {
    "value": "var(--font-vontobel-serif), monospace",
    "variable": "var(--fonts-serif)"
  },
  "fonts.sans": {
    "value": "var(--font-vontobel-sans), sans-serif",
    "variable": "var(--fonts-sans)"
  },
  "shadows.xs": {
    "value": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    "variable": "var(--shadows-xs)"
  },
  "shadows.sm": {
    "value": "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-sm)"
  },
  "shadows.md": {
    "value": "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-md)"
  },
  "shadows.lg": {
    "value": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-lg)"
  },
  "shadows.xl": {
    "value": "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
    "variable": "var(--shadows-xl)"
  },
  "shadows.2xl": {
    "value": "0 25px 50px -12px rgb(0 0 0 / 0.25)",
    "variable": "var(--shadows-2xl)"
  },
  "shadows.inner": {
    "value": "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
    "variable": "var(--shadows-inner)"
  },
  "shadows.outline": {
    "value": "0 0 0 3px rgba(66, 153, 225, 0.6)",
    "variable": "var(--shadows-outline)"
  },
  "shadows.subtleTop": {
    "value": "0px -1px 6px -1px rgba(55, 58, 54, 0.10)",
    "variable": "var(--shadows-subtle-top)"
  },
  "shadows.subtleBottom": {
    "value": "0px 1px 6px -1px rgba(55, 58, 54, 0.10)",
    "variable": "var(--shadows-subtle-bottom)"
  },
  "shadows.strongTop": {
    "value": "0px -12px 22px -4px rgba(55, 58, 54, 0.10)",
    "variable": "var(--shadows-strong-top)"
  },
  "shadows.strongBottom": {
    "value": "0px 12px 22px -4px rgba(55, 58, 54, 0.10)",
    "variable": "var(--shadows-strong-bottom)"
  },
  "radii.xs": {
    "value": "0.125rem",
    "variable": "var(--radii-xs)"
  },
  "radii.md": {
    "value": "0.375rem",
    "variable": "var(--radii-md)"
  },
  "radii.lg": {
    "value": "0.5rem",
    "variable": "var(--radii-lg)"
  },
  "radii.xl": {
    "value": "0.75rem",
    "variable": "var(--radii-xl)"
  },
  "radii.2xl": {
    "value": "1rem",
    "variable": "var(--radii-2xl)"
  },
  "radii.4xl": {
    "value": "2rem",
    "variable": "var(--radii-4xl)"
  },
  "radii.full": {
    "value": "9999px",
    "variable": "var(--radii-full)"
  },
  "radii.sm": {
    "value": "0.375rem",
    "variable": "var(--radii-sm)"
  },
  "radii.3xl": {
    "value": "3rem",
    "variable": "var(--radii-3xl)"
  },
  "fontWeights.extralight": {
    "value": "200",
    "variable": "var(--font-weights-extralight)"
  },
  "fontWeights.normal": {
    "value": "400",
    "variable": "var(--font-weights-normal)"
  },
  "fontWeights.extrabold": {
    "value": "800",
    "variable": "var(--font-weights-extrabold)"
  },
  "fontWeights.thin": {
    "value": "120",
    "variable": "var(--font-weights-thin)"
  },
  "fontWeights.light": {
    "value": "264",
    "variable": "var(--font-weights-light)"
  },
  "fontWeights.book": {
    "value": "382",
    "variable": "var(--font-weights-book)"
  },
  "fontWeights.regular": {
    "value": "500",
    "variable": "var(--font-weights-regular)"
  },
  "fontWeights.medium": {
    "value": "560",
    "variable": "var(--font-weights-medium)"
  },
  "fontWeights.semibold": {
    "value": "660",
    "variable": "var(--font-weights-semibold)"
  },
  "fontWeights.bold": {
    "value": "775",
    "variable": "var(--font-weights-bold)"
  },
  "fontWeights.black": {
    "value": "1000",
    "variable": "var(--font-weights-black)"
  },
  "spacing.0": {
    "value": "0rem",
    "variable": "var(--spacing-0)"
  },
  "spacing.1": {
    "value": "0.235rem",
    "variable": "var(--spacing-1)"
  },
  "spacing.2": {
    "value": "0.471rem",
    "variable": "var(--spacing-2)"
  },
  "spacing.3": {
    "value": "0.706rem",
    "variable": "var(--spacing-3)"
  },
  "spacing.4": {
    "value": "0.941rem",
    "variable": "var(--spacing-4)"
  },
  "spacing.5": {
    "value": "1.176rem",
    "variable": "var(--spacing-5)"
  },
  "spacing.6": {
    "value": "1.412rem",
    "variable": "var(--spacing-6)"
  },
  "spacing.7": {
    "value": "1.647rem",
    "variable": "var(--spacing-7)"
  },
  "spacing.8": {
    "value": "1.882rem",
    "variable": "var(--spacing-8)"
  },
  "spacing.9": {
    "value": "2.118rem",
    "variable": "var(--spacing-9)"
  },
  "spacing.10": {
    "value": "2.353rem",
    "variable": "var(--spacing-10)"
  },
  "spacing.11": {
    "value": "2.75rem",
    "variable": "var(--spacing-11)"
  },
  "spacing.12": {
    "value": "2.824rem",
    "variable": "var(--spacing-12)"
  },
  "spacing.14": {
    "value": "3.294rem",
    "variable": "var(--spacing-14)"
  },
  "spacing.16": {
    "value": "3.765rem",
    "variable": "var(--spacing-16)"
  },
  "spacing.20": {
    "value": "4.706rem",
    "variable": "var(--spacing-20)"
  },
  "spacing.24": {
    "value": "5.647rem",
    "variable": "var(--spacing-24)"
  },
  "spacing.28": {
    "value": "6.588rem",
    "variable": "var(--spacing-28)"
  },
  "spacing.32": {
    "value": "7.529rem",
    "variable": "var(--spacing-32)"
  },
  "spacing.36": {
    "value": "8.471rem",
    "variable": "var(--spacing-36)"
  },
  "spacing.40": {
    "value": "9.412rem",
    "variable": "var(--spacing-40)"
  },
  "spacing.44": {
    "value": "10.353rem",
    "variable": "var(--spacing-44)"
  },
  "spacing.48": {
    "value": "11.294rem",
    "variable": "var(--spacing-48)"
  },
  "spacing.52": {
    "value": "12.235rem",
    "variable": "var(--spacing-52)"
  },
  "spacing.56": {
    "value": "13.176rem",
    "variable": "var(--spacing-56)"
  },
  "spacing.60": {
    "value": "14.118rem",
    "variable": "var(--spacing-60)"
  },
  "spacing.64": {
    "value": "15.059rem",
    "variable": "var(--spacing-64)"
  },
  "spacing.72": {
    "value": "16.941rem",
    "variable": "var(--spacing-72)"
  },
  "spacing.80": {
    "value": "18.824rem",
    "variable": "var(--spacing-80)"
  },
  "spacing.96": {
    "value": "22.588rem",
    "variable": "var(--spacing-96)"
  },
  "spacing.0.5": {
    "value": "0.118rem",
    "variable": "var(--spacing-0\\.5)"
  },
  "spacing.1.5": {
    "value": "0.353rem",
    "variable": "var(--spacing-1\\.5)"
  },
  "spacing.2.5": {
    "value": "0.588rem",
    "variable": "var(--spacing-2\\.5)"
  },
  "spacing.3.5": {
    "value": "0.824rem",
    "variable": "var(--spacing-3\\.5)"
  },
  "breakpoints.2xl": {
    "value": "1536px",
    "variable": "var(--breakpoints-2xl)"
  },
  "breakpoints.xl": {
    "value": "1280px",
    "variable": "var(--breakpoints-xl)"
  },
  "breakpoints.lg": {
    "value": "1024px",
    "variable": "var(--breakpoints-lg)"
  },
  "breakpoints.md": {
    "value": "768px",
    "variable": "var(--breakpoints-md)"
  },
  "breakpoints.sm": {
    "value": "320px",
    "variable": "var(--breakpoints-sm)"
  },
  "sizes.headerHeight": {
    "value": "var(--sizes-header-height)",
    "variable": "var(--sizes-header-height)"
  },
  "sizes.appBannerHeight": {
    "value": "80px",
    "variable": "var(--sizes-app-banner-height)"
  },
  "sizes.appBannerHeight.compact": {
    "value": "48px",
    "variable": "var(--sizes-app-banner-height-compact)"
  },
  "sizes.anchorBarHeight": {
    "value": "53px",
    "variable": "var(--sizes-anchor-bar-height)"
  },
  "spacing.-1": {
    "value": "calc(var(--spacing-1) * -1)",
    "variable": "var(--spacing-1)"
  },
  "spacing.-2": {
    "value": "calc(var(--spacing-2) * -1)",
    "variable": "var(--spacing-2)"
  },
  "spacing.-3": {
    "value": "calc(var(--spacing-3) * -1)",
    "variable": "var(--spacing-3)"
  },
  "spacing.-4": {
    "value": "calc(var(--spacing-4) * -1)",
    "variable": "var(--spacing-4)"
  },
  "spacing.-5": {
    "value": "calc(var(--spacing-5) * -1)",
    "variable": "var(--spacing-5)"
  },
  "spacing.-6": {
    "value": "calc(var(--spacing-6) * -1)",
    "variable": "var(--spacing-6)"
  },
  "spacing.-7": {
    "value": "calc(var(--spacing-7) * -1)",
    "variable": "var(--spacing-7)"
  },
  "spacing.-8": {
    "value": "calc(var(--spacing-8) * -1)",
    "variable": "var(--spacing-8)"
  },
  "spacing.-9": {
    "value": "calc(var(--spacing-9) * -1)",
    "variable": "var(--spacing-9)"
  },
  "spacing.-10": {
    "value": "calc(var(--spacing-10) * -1)",
    "variable": "var(--spacing-10)"
  },
  "spacing.-11": {
    "value": "calc(var(--spacing-11) * -1)",
    "variable": "var(--spacing-11)"
  },
  "spacing.-12": {
    "value": "calc(var(--spacing-12) * -1)",
    "variable": "var(--spacing-12)"
  },
  "spacing.-14": {
    "value": "calc(var(--spacing-14) * -1)",
    "variable": "var(--spacing-14)"
  },
  "spacing.-16": {
    "value": "calc(var(--spacing-16) * -1)",
    "variable": "var(--spacing-16)"
  },
  "spacing.-20": {
    "value": "calc(var(--spacing-20) * -1)",
    "variable": "var(--spacing-20)"
  },
  "spacing.-24": {
    "value": "calc(var(--spacing-24) * -1)",
    "variable": "var(--spacing-24)"
  },
  "spacing.-28": {
    "value": "calc(var(--spacing-28) * -1)",
    "variable": "var(--spacing-28)"
  },
  "spacing.-32": {
    "value": "calc(var(--spacing-32) * -1)",
    "variable": "var(--spacing-32)"
  },
  "spacing.-36": {
    "value": "calc(var(--spacing-36) * -1)",
    "variable": "var(--spacing-36)"
  },
  "spacing.-40": {
    "value": "calc(var(--spacing-40) * -1)",
    "variable": "var(--spacing-40)"
  },
  "spacing.-44": {
    "value": "calc(var(--spacing-44) * -1)",
    "variable": "var(--spacing-44)"
  },
  "spacing.-48": {
    "value": "calc(var(--spacing-48) * -1)",
    "variable": "var(--spacing-48)"
  },
  "spacing.-52": {
    "value": "calc(var(--spacing-52) * -1)",
    "variable": "var(--spacing-52)"
  },
  "spacing.-56": {
    "value": "calc(var(--spacing-56) * -1)",
    "variable": "var(--spacing-56)"
  },
  "spacing.-60": {
    "value": "calc(var(--spacing-60) * -1)",
    "variable": "var(--spacing-60)"
  },
  "spacing.-64": {
    "value": "calc(var(--spacing-64) * -1)",
    "variable": "var(--spacing-64)"
  },
  "spacing.-72": {
    "value": "calc(var(--spacing-72) * -1)",
    "variable": "var(--spacing-72)"
  },
  "spacing.-80": {
    "value": "calc(var(--spacing-80) * -1)",
    "variable": "var(--spacing-80)"
  },
  "spacing.-96": {
    "value": "calc(var(--spacing-96) * -1)",
    "variable": "var(--spacing-96)"
  },
  "spacing.-0.5": {
    "value": "calc(var(--spacing-0\\.5) * -1)",
    "variable": "var(--spacing-0\\.5)"
  },
  "spacing.-1.5": {
    "value": "calc(var(--spacing-1\\.5) * -1)",
    "variable": "var(--spacing-1\\.5)"
  },
  "spacing.-2.5": {
    "value": "calc(var(--spacing-2\\.5) * -1)",
    "variable": "var(--spacing-2\\.5)"
  },
  "spacing.-3.5": {
    "value": "calc(var(--spacing-3\\.5) * -1)",
    "variable": "var(--spacing-3\\.5)"
  },
  "fonts.heading.h1": {
    "value": "var(--fonts-heading-h1)",
    "variable": "var(--fonts-heading-h1)"
  },
  "fontWeights.heading.h1.bold": {
    "value": "var(--font-weights-heading-h1-bold)",
    "variable": "var(--font-weights-heading-h1-bold)"
  },
  "colors.text.regular.default": {
    "value": "var(--colors-text-regular-default)",
    "variable": "var(--colors-text-regular-default)"
  },
  "colors.text.regular.subtitle": {
    "value": "var(--colors-text-regular-subtitle)",
    "variable": "var(--colors-text-regular-subtitle)"
  },
  "colors.text.regular.disabled": {
    "value": "var(--colors-text-regular-disabled)",
    "variable": "var(--colors-text-regular-disabled)"
  },
  "colors.text.regular.pressed": {
    "value": "var(--colors-text-regular-pressed)",
    "variable": "var(--colors-text-regular-pressed)"
  },
  "colors.text.regular.danger": {
    "value": "var(--colors-text-regular-danger)",
    "variable": "var(--colors-text-regular-danger)"
  },
  "colors.text.regular.success": {
    "value": "var(--colors-text-regular-success)",
    "variable": "var(--colors-text-regular-success)"
  },
  "colors.text.inverted.default": {
    "value": "var(--colors-text-inverted-default)",
    "variable": "var(--colors-text-inverted-default)"
  },
  "colors.text.inverted.subtitle": {
    "value": "var(--colors-text-inverted-subtitle)",
    "variable": "var(--colors-text-inverted-subtitle)"
  },
  "colors.text.inverted.disabled": {
    "value": "var(--colors-text-inverted-disabled)",
    "variable": "var(--colors-text-inverted-disabled)"
  },
  "colors.text.inverted.pressed": {
    "value": "var(--colors-text-inverted-pressed)",
    "variable": "var(--colors-text-inverted-pressed)"
  },
  "colors.text.inverted.danger": {
    "value": "var(--colors-text-inverted-danger)",
    "variable": "var(--colors-text-inverted-danger)"
  },
  "colors.text.inverted.success": {
    "value": "var(--colors-text-inverted-success)",
    "variable": "var(--colors-text-inverted-success)"
  },
  "colors.icons.regular.default": {
    "value": "var(--colors-icons-regular-default)",
    "variable": "var(--colors-icons-regular-default)"
  },
  "colors.icons.regular.secondary": {
    "value": "var(--colors-icons-regular-secondary)",
    "variable": "var(--colors-icons-regular-secondary)"
  },
  "colors.icons.regular.disabled": {
    "value": "var(--colors-icons-regular-disabled)",
    "variable": "var(--colors-icons-regular-disabled)"
  },
  "colors.icons.regular.pressed": {
    "value": "var(--colors-icons-regular-pressed)",
    "variable": "var(--colors-icons-regular-pressed)"
  },
  "colors.icons.regular.danger": {
    "value": "var(--colors-icons-regular-danger)",
    "variable": "var(--colors-icons-regular-danger)"
  },
  "colors.icons.inverted.default": {
    "value": "var(--colors-icons-inverted-default)",
    "variable": "var(--colors-icons-inverted-default)"
  },
  "colors.icons.inverted.secondary": {
    "value": "var(--colors-icons-inverted-secondary)",
    "variable": "var(--colors-icons-inverted-secondary)"
  },
  "colors.icons.inverted.disabled": {
    "value": "var(--colors-icons-inverted-disabled)",
    "variable": "var(--colors-icons-inverted-disabled)"
  },
  "colors.icons.inverted.pressed": {
    "value": "var(--colors-icons-inverted-pressed)",
    "variable": "var(--colors-icons-inverted-pressed)"
  },
  "colors.icons.inverted.danger": {
    "value": "var(--colors-icons-inverted-danger)",
    "variable": "var(--colors-icons-inverted-danger)"
  },
  "colors.icons.accent.aquamarine": {
    "value": "var(--colors-icons-accent-aquamarine)",
    "variable": "var(--colors-icons-accent-aquamarine)"
  },
  "colors.icons.accent.coral": {
    "value": "var(--colors-icons-accent-coral)",
    "variable": "var(--colors-icons-accent-coral)"
  },
  "colors.icons.accent.citrine": {
    "value": "var(--colors-icons-accent-citrine)",
    "variable": "var(--colors-icons-accent-citrine)"
  },
  "colors.icons.accent.sand": {
    "value": "var(--colors-icons-accent-sand)",
    "variable": "var(--colors-icons-accent-sand)"
  },
  "colors.surface.static.primary": {
    "value": "var(--colors-surface-static-primary)",
    "variable": "var(--colors-surface-static-primary)"
  },
  "colors.surface.static.secondary": {
    "value": "var(--colors-surface-static-secondary)",
    "variable": "var(--colors-surface-static-secondary)"
  },
  "colors.surface.static.tertiary": {
    "value": "var(--colors-surface-static-tertiary)",
    "variable": "var(--colors-surface-static-tertiary)"
  },
  "colors.surface.static.chat": {
    "value": "var(--colors-surface-static-chat)",
    "variable": "var(--colors-surface-static-chat)"
  },
  "colors.surface.static.inverted": {
    "value": "var(--colors-surface-static-inverted)",
    "variable": "var(--colors-surface-static-inverted)"
  },
  "colors.surface.static.primaryInverted": {
    "value": "var(--colors-surface-static-primary-inverted)",
    "variable": "var(--colors-surface-static-primary-inverted)"
  },
  "colors.surface.static.invertedException": {
    "value": "var(--colors-surface-static-inverted-exception)",
    "variable": "var(--colors-surface-static-inverted-exception)"
  },
  "colors.surface.static.aquamarine": {
    "value": "var(--colors-surface-static-aquamarine)",
    "variable": "var(--colors-surface-static-aquamarine)"
  },
  "colors.surface.static.citrine": {
    "value": "var(--colors-surface-static-citrine)",
    "variable": "var(--colors-surface-static-citrine)"
  },
  "colors.surface.static.coral": {
    "value": "var(--colors-surface-static-coral)",
    "variable": "var(--colors-surface-static-coral)"
  },
  "colors.surface.interactive.card.primary": {
    "value": "var(--colors-surface-interactive-card-primary)",
    "variable": "var(--colors-surface-interactive-card-primary)"
  },
  "colors.surface.interactive.card.secondary": {
    "value": "var(--colors-surface-interactive-card-secondary)",
    "variable": "var(--colors-surface-interactive-card-secondary)"
  },
  "colors.surface.interactive.card.inverted": {
    "value": "var(--colors-surface-interactive-card-inverted)",
    "variable": "var(--colors-surface-interactive-card-inverted)"
  },
  "colors.surface.interactive.card.primaryInverted": {
    "value": "var(--colors-surface-interactive-card-primary-inverted)",
    "variable": "var(--colors-surface-interactive-card-primary-inverted)"
  },
  "colors.surface.interactive.card.primaryAccent": {
    "value": "var(--colors-surface-interactive-card-primary-accent)",
    "variable": "var(--colors-surface-interactive-card-primary-accent)"
  },
  "colors.surface.interactive.card.secondaryAccent": {
    "value": "var(--colors-surface-interactive-card-secondary-accent)",
    "variable": "var(--colors-surface-interactive-card-secondary-accent)"
  },
  "colors.surface.interactive.card.tertiaryAccent": {
    "value": "var(--colors-surface-interactive-card-tertiary-accent)",
    "variable": "var(--colors-surface-interactive-card-tertiary-accent)"
  },
  "colors.surface.interactive.transparent.regular.default": {
    "value": "var(--colors-surface-interactive-transparent-regular-default)",
    "variable": "var(--colors-surface-interactive-transparent-regular-default)"
  },
  "colors.surface.interactive.transparent.regular.hover": {
    "value": "var(--colors-surface-interactive-transparent-regular-hover)",
    "variable": "var(--colors-surface-interactive-transparent-regular-hover)"
  },
  "colors.surface.interactive.transparent.regular.pressed": {
    "value": "var(--colors-surface-interactive-transparent-regular-pressed)",
    "variable": "var(--colors-surface-interactive-transparent-regular-pressed)"
  },
  "colors.surface.interactive.transparent.regular.disabled": {
    "value": "var(--colors-surface-interactive-transparent-regular-disabled)",
    "variable": "var(--colors-surface-interactive-transparent-regular-disabled)"
  },
  "colors.surface.interactive.transparent.inverted.default": {
    "value": "var(--colors-surface-interactive-transparent-inverted-default)",
    "variable": "var(--colors-surface-interactive-transparent-inverted-default)"
  },
  "colors.surface.interactive.transparent.inverted.hover": {
    "value": "var(--colors-surface-interactive-transparent-inverted-hover)",
    "variable": "var(--colors-surface-interactive-transparent-inverted-hover)"
  },
  "colors.surface.interactive.transparent.inverted.pressed": {
    "value": "var(--colors-surface-interactive-transparent-inverted-pressed)",
    "variable": "var(--colors-surface-interactive-transparent-inverted-pressed)"
  },
  "colors.surface.interactive.transparent.inverted.disabled": {
    "value": "var(--colors-surface-interactive-transparent-inverted-disabled)",
    "variable": "var(--colors-surface-interactive-transparent-inverted-disabled)"
  },
  "colors.surface.interactive.input.regular.hoverAndPressed": {
    "value": "var(--colors-surface-interactive-input-regular-hover-and-pressed)",
    "variable": "var(--colors-surface-interactive-input-regular-hover-and-pressed)"
  },
  "colors.surface.interactive.input.inverted.hoverAndPressed": {
    "value": "var(--colors-surface-interactive-input-inverted-hover-and-pressed)",
    "variable": "var(--colors-surface-interactive-input-inverted-hover-and-pressed)"
  },
  "colors.surface.interactive.accordion.regular.fillDefault": {
    "value": "var(--colors-surface-interactive-accordion-regular-fill-default)",
    "variable": "var(--colors-surface-interactive-accordion-regular-fill-default)"
  },
  "colors.surface.interactive.accordion.regular.fillDefaultOpened": {
    "value": "var(--colors-surface-interactive-accordion-regular-fill-default-opened)",
    "variable": "var(--colors-surface-interactive-accordion-regular-fill-default-opened)"
  },
  "colors.surface.interactive.accordion.regular.fillHover": {
    "value": "var(--colors-surface-interactive-accordion-regular-fill-hover)",
    "variable": "var(--colors-surface-interactive-accordion-regular-fill-hover)"
  },
  "colors.surface.interactive.accordion.regular.fillHoverOpened": {
    "value": "var(--colors-surface-interactive-accordion-regular-fill-hover-opened)",
    "variable": "var(--colors-surface-interactive-accordion-regular-fill-hover-opened)"
  },
  "colors.surface.interactive.accordion.inverted.fillDefault": {
    "value": "var(--colors-surface-interactive-accordion-inverted-fill-default)",
    "variable": "var(--colors-surface-interactive-accordion-inverted-fill-default)"
  },
  "colors.surface.interactive.accordion.inverted.fillDefaultOpened": {
    "value": "var(--colors-surface-interactive-accordion-inverted-fill-default-opened)",
    "variable": "var(--colors-surface-interactive-accordion-inverted-fill-default-opened)"
  },
  "colors.surface.interactive.accordion.inverted.fillHover": {
    "value": "var(--colors-surface-interactive-accordion-inverted-fill-hover)",
    "variable": "var(--colors-surface-interactive-accordion-inverted-fill-hover)"
  },
  "colors.surface.interactive.accordion.inverted.fillHoverOpened": {
    "value": "var(--colors-surface-interactive-accordion-inverted-fill-hover-opened)",
    "variable": "var(--colors-surface-interactive-accordion-inverted-fill-hover-opened)"
  },
  "colors.surface.interactive.accordion.sand.fillDefault": {
    "value": "var(--colors-surface-interactive-accordion-sand-fill-default)",
    "variable": "var(--colors-surface-interactive-accordion-sand-fill-default)"
  },
  "colors.surface.interactive.accordion.sand.fillDefaultOpened": {
    "value": "var(--colors-surface-interactive-accordion-sand-fill-default-opened)",
    "variable": "var(--colors-surface-interactive-accordion-sand-fill-default-opened)"
  },
  "colors.surface.interactive.accordion.sand.fillHover": {
    "value": "var(--colors-surface-interactive-accordion-sand-fill-hover)",
    "variable": "var(--colors-surface-interactive-accordion-sand-fill-hover)"
  },
  "colors.surface.interactive.accordion.sand.fillHoverOpened": {
    "value": "var(--colors-surface-interactive-accordion-sand-fill-hover-opened)",
    "variable": "var(--colors-surface-interactive-accordion-sand-fill-hover-opened)"
  },
  "colors.surface.interactive.skeleton.regular": {
    "value": "var(--colors-surface-interactive-skeleton-regular)",
    "variable": "var(--colors-surface-interactive-skeleton-regular)"
  },
  "colors.surface.interactive.skeleton.inverted": {
    "value": "var(--colors-surface-interactive-skeleton-inverted)",
    "variable": "var(--colors-surface-interactive-skeleton-inverted)"
  },
  "colors.charts.polls.background.regular.positive.60": {
    "value": "var(--colors-charts-polls-background-regular-positive-60)",
    "variable": "var(--colors-charts-polls-background-regular-positive-60)"
  },
  "colors.charts.polls.background.regular.positive.70": {
    "value": "var(--colors-charts-polls-background-regular-positive-70)",
    "variable": "var(--colors-charts-polls-background-regular-positive-70)"
  },
  "colors.charts.polls.background.regular.positive.80": {
    "value": "var(--colors-charts-polls-background-regular-positive-80)",
    "variable": "var(--colors-charts-polls-background-regular-positive-80)"
  },
  "colors.charts.polls.background.regular.positive.90": {
    "value": "var(--colors-charts-polls-background-regular-positive-90)",
    "variable": "var(--colors-charts-polls-background-regular-positive-90)"
  },
  "colors.charts.polls.background.regular.neutral": {
    "value": "var(--colors-charts-polls-background-regular-neutral)",
    "variable": "var(--colors-charts-polls-background-regular-neutral)"
  },
  "colors.charts.polls.background.regular.negative.60": {
    "value": "var(--colors-charts-polls-background-regular-negative-60)",
    "variable": "var(--colors-charts-polls-background-regular-negative-60)"
  },
  "colors.charts.polls.background.regular.negative.70": {
    "value": "var(--colors-charts-polls-background-regular-negative-70)",
    "variable": "var(--colors-charts-polls-background-regular-negative-70)"
  },
  "colors.charts.polls.background.regular.negative.80": {
    "value": "var(--colors-charts-polls-background-regular-negative-80)",
    "variable": "var(--colors-charts-polls-background-regular-negative-80)"
  },
  "colors.charts.polls.background.regular.negative.90": {
    "value": "var(--colors-charts-polls-background-regular-negative-90)",
    "variable": "var(--colors-charts-polls-background-regular-negative-90)"
  },
  "colors.actions.primary.regular.fillDefault": {
    "value": "var(--colors-actions-primary-regular-fill-default)",
    "variable": "var(--colors-actions-primary-regular-fill-default)"
  },
  "colors.actions.primary.regular.textAndIconDefault": {
    "value": "var(--colors-actions-primary-regular-text-and-icon-default)",
    "variable": "var(--colors-actions-primary-regular-text-and-icon-default)"
  },
  "colors.actions.primary.regular.fillHover": {
    "value": "var(--colors-actions-primary-regular-fill-hover)",
    "variable": "var(--colors-actions-primary-regular-fill-hover)"
  },
  "colors.actions.primary.regular.fillPressed": {
    "value": "var(--colors-actions-primary-regular-fill-pressed)",
    "variable": "var(--colors-actions-primary-regular-fill-pressed)"
  },
  "colors.actions.primary.regular.textAndIconPressed": {
    "value": "var(--colors-actions-primary-regular-text-and-icon-pressed)",
    "variable": "var(--colors-actions-primary-regular-text-and-icon-pressed)"
  },
  "colors.actions.primary.regular.fillDisabled": {
    "value": "var(--colors-actions-primary-regular-fill-disabled)",
    "variable": "var(--colors-actions-primary-regular-fill-disabled)"
  },
  "colors.actions.primary.inverted.fillDefault": {
    "value": "var(--colors-actions-primary-inverted-fill-default)",
    "variable": "var(--colors-actions-primary-inverted-fill-default)"
  },
  "colors.actions.primary.inverted.textAndIconDefault": {
    "value": "var(--colors-actions-primary-inverted-text-and-icon-default)",
    "variable": "var(--colors-actions-primary-inverted-text-and-icon-default)"
  },
  "colors.actions.primary.inverted.fillHover": {
    "value": "var(--colors-actions-primary-inverted-fill-hover)",
    "variable": "var(--colors-actions-primary-inverted-fill-hover)"
  },
  "colors.actions.primary.inverted.fillPressed": {
    "value": "var(--colors-actions-primary-inverted-fill-pressed)",
    "variable": "var(--colors-actions-primary-inverted-fill-pressed)"
  },
  "colors.actions.primary.inverted.textAndIconPressed": {
    "value": "var(--colors-actions-primary-inverted-text-and-icon-pressed)",
    "variable": "var(--colors-actions-primary-inverted-text-and-icon-pressed)"
  },
  "colors.actions.primary.inverted.fillDisabled": {
    "value": "var(--colors-actions-primary-inverted-fill-disabled)",
    "variable": "var(--colors-actions-primary-inverted-fill-disabled)"
  },
  "colors.actions.primaryDanger.regular.fillDefault": {
    "value": "var(--colors-actions-primary-danger-regular-fill-default)",
    "variable": "var(--colors-actions-primary-danger-regular-fill-default)"
  },
  "colors.actions.primaryDanger.regular.fillHoverAndPressed": {
    "value": "var(--colors-actions-primary-danger-regular-fill-hover-and-pressed)",
    "variable": "var(--colors-actions-primary-danger-regular-fill-hover-and-pressed)"
  },
  "colors.actions.primaryDanger.regular.fillDisabled": {
    "value": "var(--colors-actions-primary-danger-regular-fill-disabled)",
    "variable": "var(--colors-actions-primary-danger-regular-fill-disabled)"
  },
  "colors.actions.primaryDanger.inverted.fillDefault": {
    "value": "var(--colors-actions-primary-danger-inverted-fill-default)",
    "variable": "var(--colors-actions-primary-danger-inverted-fill-default)"
  },
  "colors.actions.primaryDanger.inverted.fillHoverAndPressed": {
    "value": "var(--colors-actions-primary-danger-inverted-fill-hover-and-pressed)",
    "variable": "var(--colors-actions-primary-danger-inverted-fill-hover-and-pressed)"
  },
  "colors.actions.primaryDanger.inverted.fillDisabled": {
    "value": "var(--colors-actions-primary-danger-inverted-fill-disabled)",
    "variable": "var(--colors-actions-primary-danger-inverted-fill-disabled)"
  },
  "colors.actions.secondary.regular.strokeDefault": {
    "value": "var(--colors-actions-secondary-regular-stroke-default)",
    "variable": "var(--colors-actions-secondary-regular-stroke-default)"
  },
  "colors.actions.secondary.regular.fillHover": {
    "value": "var(--colors-actions-secondary-regular-fill-hover)",
    "variable": "var(--colors-actions-secondary-regular-fill-hover)"
  },
  "colors.actions.secondary.regular.fillPressed": {
    "value": "var(--colors-actions-secondary-regular-fill-pressed)",
    "variable": "var(--colors-actions-secondary-regular-fill-pressed)"
  },
  "colors.actions.secondary.regular.strokeDisabled": {
    "value": "var(--colors-actions-secondary-regular-stroke-disabled)",
    "variable": "var(--colors-actions-secondary-regular-stroke-disabled)"
  },
  "colors.actions.secondary.inverted.default": {
    "value": "var(--colors-actions-secondary-inverted-default)",
    "variable": "var(--colors-actions-secondary-inverted-default)"
  },
  "colors.actions.secondary.inverted.fillHover": {
    "value": "var(--colors-actions-secondary-inverted-fill-hover)",
    "variable": "var(--colors-actions-secondary-inverted-fill-hover)"
  },
  "colors.actions.secondary.inverted.fillPressed": {
    "value": "var(--colors-actions-secondary-inverted-fill-pressed)",
    "variable": "var(--colors-actions-secondary-inverted-fill-pressed)"
  },
  "colors.actions.secondary.inverted.strokeDisabled": {
    "value": "var(--colors-actions-secondary-inverted-stroke-disabled)",
    "variable": "var(--colors-actions-secondary-inverted-stroke-disabled)"
  },
  "colors.actions.secondaryDanger.regular.strokeDefault": {
    "value": "var(--colors-actions-secondary-danger-regular-stroke-default)",
    "variable": "var(--colors-actions-secondary-danger-regular-stroke-default)"
  },
  "colors.actions.secondaryDanger.regular.fillHover": {
    "value": "var(--colors-actions-secondary-danger-regular-fill-hover)",
    "variable": "var(--colors-actions-secondary-danger-regular-fill-hover)"
  },
  "colors.actions.secondaryDanger.regular.fillPressed": {
    "value": "var(--colors-actions-secondary-danger-regular-fill-pressed)",
    "variable": "var(--colors-actions-secondary-danger-regular-fill-pressed)"
  },
  "colors.actions.secondaryDanger.regular.strokeDisabled": {
    "value": "var(--colors-actions-secondary-danger-regular-stroke-disabled)",
    "variable": "var(--colors-actions-secondary-danger-regular-stroke-disabled)"
  },
  "colors.actions.secondaryDanger.inverted.strokeDefault": {
    "value": "var(--colors-actions-secondary-danger-inverted-stroke-default)",
    "variable": "var(--colors-actions-secondary-danger-inverted-stroke-default)"
  },
  "colors.actions.secondaryDanger.inverted.fillHover": {
    "value": "var(--colors-actions-secondary-danger-inverted-fill-hover)",
    "variable": "var(--colors-actions-secondary-danger-inverted-fill-hover)"
  },
  "colors.actions.secondaryDanger.inverted.fillPressed": {
    "value": "var(--colors-actions-secondary-danger-inverted-fill-pressed)",
    "variable": "var(--colors-actions-secondary-danger-inverted-fill-pressed)"
  },
  "colors.actions.secondaryDanger.inverted.strokeDisabled": {
    "value": "var(--colors-actions-secondary-danger-inverted-stroke-disabled)",
    "variable": "var(--colors-actions-secondary-danger-inverted-stroke-disabled)"
  },
  "colors.actions.tertiary.regular.fillDefault": {
    "value": "var(--colors-actions-tertiary-regular-fill-default)",
    "variable": "var(--colors-actions-tertiary-regular-fill-default)"
  },
  "colors.actions.tertiary.regular.fillHover": {
    "value": "var(--colors-actions-tertiary-regular-fill-hover)",
    "variable": "var(--colors-actions-tertiary-regular-fill-hover)"
  },
  "colors.actions.tertiary.regular.fillPressed": {
    "value": "var(--colors-actions-tertiary-regular-fill-pressed)",
    "variable": "var(--colors-actions-tertiary-regular-fill-pressed)"
  },
  "colors.actions.tertiary.regular.fillDisabled": {
    "value": "var(--colors-actions-tertiary-regular-fill-disabled)",
    "variable": "var(--colors-actions-tertiary-regular-fill-disabled)"
  },
  "colors.actions.tertiary.inverted.fillDefault": {
    "value": "var(--colors-actions-tertiary-inverted-fill-default)",
    "variable": "var(--colors-actions-tertiary-inverted-fill-default)"
  },
  "colors.actions.tertiary.inverted.fillHover": {
    "value": "var(--colors-actions-tertiary-inverted-fill-hover)",
    "variable": "var(--colors-actions-tertiary-inverted-fill-hover)"
  },
  "colors.actions.tertiary.inverted.fillPressed": {
    "value": "var(--colors-actions-tertiary-inverted-fill-pressed)",
    "variable": "var(--colors-actions-tertiary-inverted-fill-pressed)"
  },
  "colors.actions.tertiary.inverted.fillDisabled": {
    "value": "var(--colors-actions-tertiary-inverted-fill-disabled)",
    "variable": "var(--colors-actions-tertiary-inverted-fill-disabled)"
  },
  "colors.actions.links.regular.default": {
    "value": "var(--colors-actions-links-regular-default)",
    "variable": "var(--colors-actions-links-regular-default)"
  },
  "colors.actions.links.regular.hoverAndPressed": {
    "value": "var(--colors-actions-links-regular-hover-and-pressed)",
    "variable": "var(--colors-actions-links-regular-hover-and-pressed)"
  },
  "colors.actions.links.regular.visited": {
    "value": "var(--colors-actions-links-regular-visited)",
    "variable": "var(--colors-actions-links-regular-visited)"
  },
  "colors.actions.links.regular.disabled": {
    "value": "var(--colors-actions-links-regular-disabled)",
    "variable": "var(--colors-actions-links-regular-disabled)"
  },
  "colors.actions.links.inverted.default": {
    "value": "var(--colors-actions-links-inverted-default)",
    "variable": "var(--colors-actions-links-inverted-default)"
  },
  "colors.actions.links.inverted.hoverAndPressed": {
    "value": "var(--colors-actions-links-inverted-hover-and-pressed)",
    "variable": "var(--colors-actions-links-inverted-hover-and-pressed)"
  },
  "colors.actions.links.inverted.visited": {
    "value": "var(--colors-actions-links-inverted-visited)",
    "variable": "var(--colors-actions-links-inverted-visited)"
  },
  "colors.actions.controls.regular.onFillDefault": {
    "value": "var(--colors-actions-controls-regular-on-fill-default)",
    "variable": "var(--colors-actions-controls-regular-on-fill-default)"
  },
  "colors.actions.controls.regular.onFillHoverAndPressed": {
    "value": "var(--colors-actions-controls-regular-on-fill-hover-and-pressed)",
    "variable": "var(--colors-actions-controls-regular-on-fill-hover-and-pressed)"
  },
  "colors.actions.controls.regular.onStrokeFocus": {
    "value": "var(--colors-actions-controls-regular-on-stroke-focus)",
    "variable": "var(--colors-actions-controls-regular-on-stroke-focus)"
  },
  "colors.actions.controls.regular.onFillDisabled": {
    "value": "var(--colors-actions-controls-regular-on-fill-disabled)",
    "variable": "var(--colors-actions-controls-regular-on-fill-disabled)"
  },
  "colors.actions.controls.regular.offFillDefault": {
    "value": "var(--colors-actions-controls-regular-off-fill-default)",
    "variable": "var(--colors-actions-controls-regular-off-fill-default)"
  },
  "colors.actions.controls.regular.offFillHoverAndPressed": {
    "value": "var(--colors-actions-controls-regular-off-fill-hover-and-pressed)",
    "variable": "var(--colors-actions-controls-regular-off-fill-hover-and-pressed)"
  },
  "colors.actions.controls.regular.offFillDisabled": {
    "value": "var(--colors-actions-controls-regular-off-fill-disabled)",
    "variable": "var(--colors-actions-controls-regular-off-fill-disabled)"
  },
  "colors.actions.controls.regular.fillThumb": {
    "value": "var(--colors-actions-controls-regular-fill-thumb)",
    "variable": "var(--colors-actions-controls-regular-fill-thumb)"
  },
  "colors.actions.controls.inverted.onFillDefault": {
    "value": "var(--colors-actions-controls-inverted-on-fill-default)",
    "variable": "var(--colors-actions-controls-inverted-on-fill-default)"
  },
  "colors.actions.controls.inverted.onFillHoverAndPressed": {
    "value": "var(--colors-actions-controls-inverted-on-fill-hover-and-pressed)",
    "variable": "var(--colors-actions-controls-inverted-on-fill-hover-and-pressed)"
  },
  "colors.actions.controls.inverted.onStrokeFocus": {
    "value": "var(--colors-actions-controls-inverted-on-stroke-focus)",
    "variable": "var(--colors-actions-controls-inverted-on-stroke-focus)"
  },
  "colors.actions.controls.inverted.onFillDisabled": {
    "value": "var(--colors-actions-controls-inverted-on-fill-disabled)",
    "variable": "var(--colors-actions-controls-inverted-on-fill-disabled)"
  },
  "colors.actions.controls.inverted.offFillDefault": {
    "value": "var(--colors-actions-controls-inverted-off-fill-default)",
    "variable": "var(--colors-actions-controls-inverted-off-fill-default)"
  },
  "colors.actions.controls.inverted.offFillHoverAndPressed": {
    "value": "var(--colors-actions-controls-inverted-off-fill-hover-and-pressed)",
    "variable": "var(--colors-actions-controls-inverted-off-fill-hover-and-pressed)"
  },
  "colors.actions.controls.inverted.offFillDisabled": {
    "value": "var(--colors-actions-controls-inverted-off-fill-disabled)",
    "variable": "var(--colors-actions-controls-inverted-off-fill-disabled)"
  },
  "colors.actions.controls.inverted.fillThumb": {
    "value": "var(--colors-actions-controls-inverted-fill-thumb)",
    "variable": "var(--colors-actions-controls-inverted-fill-thumb)"
  },
  "colors.actions.controlsDanger.regular.onFillDefault": {
    "value": "var(--colors-actions-controls-danger-regular-on-fill-default)",
    "variable": "var(--colors-actions-controls-danger-regular-on-fill-default)"
  },
  "colors.actions.controlsDanger.regular.onFillHoverAndPressed": {
    "value": "var(--colors-actions-controls-danger-regular-on-fill-hover-and-pressed)",
    "variable": "var(--colors-actions-controls-danger-regular-on-fill-hover-and-pressed)"
  },
  "colors.actions.controlsDanger.regular.onStrokeFocus": {
    "value": "var(--colors-actions-controls-danger-regular-on-stroke-focus)",
    "variable": "var(--colors-actions-controls-danger-regular-on-stroke-focus)"
  },
  "colors.actions.controlsDanger.regular.onFillDisabled": {
    "value": "var(--colors-actions-controls-danger-regular-on-fill-disabled)",
    "variable": "var(--colors-actions-controls-danger-regular-on-fill-disabled)"
  },
  "colors.actions.controlsDanger.inverted.onFillDefault": {
    "value": "var(--colors-actions-controls-danger-inverted-on-fill-default)",
    "variable": "var(--colors-actions-controls-danger-inverted-on-fill-default)"
  },
  "colors.actions.controlsDanger.inverted.onFillHoverAndPressed": {
    "value": "var(--colors-actions-controls-danger-inverted-on-fill-hover-and-pressed)",
    "variable": "var(--colors-actions-controls-danger-inverted-on-fill-hover-and-pressed)"
  },
  "colors.actions.controlsDanger.inverted.onStrokeFocus": {
    "value": "var(--colors-actions-controls-danger-inverted-on-stroke-focus)",
    "variable": "var(--colors-actions-controls-danger-inverted-on-stroke-focus)"
  },
  "colors.actions.controlsDanger.inverted.onFillDisabled": {
    "value": "var(--colors-actions-controls-danger-inverted-on-fill-disabled)",
    "variable": "var(--colors-actions-controls-danger-inverted-on-fill-disabled)"
  },
  "colors.actions.filterTags.regular.fillDefault": {
    "value": "var(--colors-actions-filter-tags-regular-fill-default)",
    "variable": "var(--colors-actions-filter-tags-regular-fill-default)"
  },
  "colors.actions.filterTags.regular.strokeHover": {
    "value": "var(--colors-actions-filter-tags-regular-stroke-hover)",
    "variable": "var(--colors-actions-filter-tags-regular-stroke-hover)"
  },
  "colors.actions.filterTags.regular.fillSelected": {
    "value": "var(--colors-actions-filter-tags-regular-fill-selected)",
    "variable": "var(--colors-actions-filter-tags-regular-fill-selected)"
  },
  "colors.actions.filterTags.regular.fillSelectedHoverAndPressed": {
    "value": "var(--colors-actions-filter-tags-regular-fill-selected-hover-and-pressed)",
    "variable": "var(--colors-actions-filter-tags-regular-fill-selected-hover-and-pressed)"
  },
  "colors.actions.filterTags.regular.fillDisabled": {
    "value": "var(--colors-actions-filter-tags-regular-fill-disabled)",
    "variable": "var(--colors-actions-filter-tags-regular-fill-disabled)"
  },
  "colors.actions.filterTags.regular.textAndIconDisabled": {
    "value": "var(--colors-actions-filter-tags-regular-text-and-icon-disabled)",
    "variable": "var(--colors-actions-filter-tags-regular-text-and-icon-disabled)"
  },
  "colors.actions.filterTags.inverted.fillDefault": {
    "value": "var(--colors-actions-filter-tags-inverted-fill-default)",
    "variable": "var(--colors-actions-filter-tags-inverted-fill-default)"
  },
  "colors.actions.filterTags.inverted.strokeHover": {
    "value": "var(--colors-actions-filter-tags-inverted-stroke-hover)",
    "variable": "var(--colors-actions-filter-tags-inverted-stroke-hover)"
  },
  "colors.actions.filterTags.inverted.fillSelected": {
    "value": "var(--colors-actions-filter-tags-inverted-fill-selected)",
    "variable": "var(--colors-actions-filter-tags-inverted-fill-selected)"
  },
  "colors.actions.filterTags.inverted.fillSelectedHoverAndPressed": {
    "value": "var(--colors-actions-filter-tags-inverted-fill-selected-hover-and-pressed)",
    "variable": "var(--colors-actions-filter-tags-inverted-fill-selected-hover-and-pressed)"
  },
  "colors.actions.filterTags.inverted.fillDisabled": {
    "value": "var(--colors-actions-filter-tags-inverted-fill-disabled)",
    "variable": "var(--colors-actions-filter-tags-inverted-fill-disabled)"
  },
  "colors.actions.filterTags.inverted.textAndIconDisabled": {
    "value": "var(--colors-actions-filter-tags-inverted-text-and-icon-disabled)",
    "variable": "var(--colors-actions-filter-tags-inverted-text-and-icon-disabled)"
  },
  "colors.actions.uploads.fillHover": {
    "value": "var(--colors-actions-uploads-fill-hover)",
    "variable": "var(--colors-actions-uploads-fill-hover)"
  },
  "colors.actions.uploads.strokeHover": {
    "value": "var(--colors-actions-uploads-stroke-hover)",
    "variable": "var(--colors-actions-uploads-stroke-hover)"
  },
  "colors.other.scrim": {
    "value": "var(--colors-other-scrim)",
    "variable": "var(--colors-other-scrim)"
  },
  "colors.other.indicator.regular.hover": {
    "value": "var(--colors-other-indicator-regular-hover)",
    "variable": "var(--colors-other-indicator-regular-hover)"
  },
  "colors.other.indicator.regular.selected": {
    "value": "var(--colors-other-indicator-regular-selected)",
    "variable": "var(--colors-other-indicator-regular-selected)"
  },
  "colors.other.indicator.regular.default": {
    "value": "var(--colors-other-indicator-regular-default)",
    "variable": "var(--colors-other-indicator-regular-default)"
  },
  "colors.other.indicator.inverted.hover": {
    "value": "var(--colors-other-indicator-inverted-hover)",
    "variable": "var(--colors-other-indicator-inverted-hover)"
  },
  "colors.other.indicator.inverted.selected": {
    "value": "var(--colors-other-indicator-inverted-selected)",
    "variable": "var(--colors-other-indicator-inverted-selected)"
  },
  "colors.other.border.regular.default": {
    "value": "var(--colors-other-border-regular-default)",
    "variable": "var(--colors-other-border-regular-default)"
  },
  "colors.other.border.regular.strong": {
    "value": "var(--colors-other-border-regular-strong)",
    "variable": "var(--colors-other-border-regular-strong)"
  },
  "colors.other.border.regular.weak": {
    "value": "var(--colors-other-border-regular-weak)",
    "variable": "var(--colors-other-border-regular-weak)"
  },
  "colors.other.border.regular.focus": {
    "value": "var(--colors-other-border-regular-focus)",
    "variable": "var(--colors-other-border-regular-focus)"
  },
  "colors.other.border.regular.disabled": {
    "value": "var(--colors-other-border-regular-disabled)",
    "variable": "var(--colors-other-border-regular-disabled)"
  },
  "colors.other.border.regular.shareSpecial": {
    "value": "var(--colors-other-border-regular-share-special)",
    "variable": "var(--colors-other-border-regular-share-special)"
  },
  "colors.other.border.inverted.default": {
    "value": "var(--colors-other-border-inverted-default)",
    "variable": "var(--colors-other-border-inverted-default)"
  },
  "colors.other.border.inverted.shareSpecial": {
    "value": "var(--colors-other-border-inverted-share-special)",
    "variable": "var(--colors-other-border-inverted-share-special)"
  },
  "colors.other.border.inverted.focus": {
    "value": "var(--colors-other-border-inverted-focus)",
    "variable": "var(--colors-other-border-inverted-focus)"
  },
  "colors.other.border.sand.hairline": {
    "value": "var(--colors-other-border-sand-hairline)",
    "variable": "var(--colors-other-border-sand-hairline)"
  },
  "colors.other.border.sand.weak": {
    "value": "var(--colors-other-border-sand-weak)",
    "variable": "var(--colors-other-border-sand-weak)"
  },
  "colors.other.border.sand.strong": {
    "value": "var(--colors-other-border-sand-strong)",
    "variable": "var(--colors-other-border-sand-strong)"
  },
  "colors.other.pattern.aquamarine": {
    "value": "var(--colors-other-pattern-aquamarine)",
    "variable": "var(--colors-other-pattern-aquamarine)"
  },
  "colors.other.pattern.citrine": {
    "value": "var(--colors-other-pattern-citrine)",
    "variable": "var(--colors-other-pattern-citrine)"
  },
  "colors.other.pattern.coral": {
    "value": "var(--colors-other-pattern-coral)",
    "variable": "var(--colors-other-pattern-coral)"
  },
  "colors.other.pattern.dark": {
    "value": "var(--colors-other-pattern-dark)",
    "variable": "var(--colors-other-pattern-dark)"
  },
  "colors.other.pattern.light": {
    "value": "var(--colors-other-pattern-light)",
    "variable": "var(--colors-other-pattern-light)"
  },
  "colors.trafficLights.success.onBackground": {
    "value": "var(--colors-traffic-lights-success-on-background)",
    "variable": "var(--colors-traffic-lights-success-on-background)"
  },
  "colors.trafficLights.success.default": {
    "value": "var(--colors-traffic-lights-success-default)",
    "variable": "var(--colors-traffic-lights-success-default)"
  },
  "colors.trafficLights.success.variant": {
    "value": "var(--colors-traffic-lights-success-variant)",
    "variable": "var(--colors-traffic-lights-success-variant)"
  },
  "colors.trafficLights.success.background": {
    "value": "var(--colors-traffic-lights-success-background)",
    "variable": "var(--colors-traffic-lights-success-background)"
  },
  "colors.trafficLights.warning.default": {
    "value": "var(--colors-traffic-lights-warning-default)",
    "variable": "var(--colors-traffic-lights-warning-default)"
  },
  "colors.trafficLights.warning.background": {
    "value": "var(--colors-traffic-lights-warning-background)",
    "variable": "var(--colors-traffic-lights-warning-background)"
  },
  "colors.trafficLights.error.onBackground": {
    "value": "var(--colors-traffic-lights-error-on-background)",
    "variable": "var(--colors-traffic-lights-error-on-background)"
  },
  "colors.trafficLights.error.default": {
    "value": "var(--colors-traffic-lights-error-default)",
    "variable": "var(--colors-traffic-lights-error-default)"
  },
  "colors.trafficLights.error.variant": {
    "value": "var(--colors-traffic-lights-error-variant)",
    "variable": "var(--colors-traffic-lights-error-variant)"
  },
  "colors.trafficLights.error.background": {
    "value": "var(--colors-traffic-lights-error-background)",
    "variable": "var(--colors-traffic-lights-error-background)"
  },
  "colors.trafficLights.error.backgroundVariant": {
    "value": "var(--colors-traffic-lights-error-background-variant)",
    "variable": "var(--colors-traffic-lights-error-background-variant)"
  },
  "colors.signal.background.regular.success": {
    "value": "var(--colors-signal-background-regular-success)",
    "variable": "var(--colors-signal-background-regular-success)"
  },
  "colors.signal.background.regular.warning": {
    "value": "var(--colors-signal-background-regular-warning)",
    "variable": "var(--colors-signal-background-regular-warning)"
  },
  "colors.signal.background.regular.danger": {
    "value": "var(--colors-signal-background-regular-danger)",
    "variable": "var(--colors-signal-background-regular-danger)"
  },
  "colors.signal.background.regular.neutral": {
    "value": "var(--colors-signal-background-regular-neutral)",
    "variable": "var(--colors-signal-background-regular-neutral)"
  },
  "colors.signal.background.regular.information": {
    "value": "var(--colors-signal-background-regular-information)",
    "variable": "var(--colors-signal-background-regular-information)"
  },
  "colors.signal.background.inverted.success": {
    "value": "var(--colors-signal-background-inverted-success)",
    "variable": "var(--colors-signal-background-inverted-success)"
  },
  "colors.signal.background.inverted.warning": {
    "value": "var(--colors-signal-background-inverted-warning)",
    "variable": "var(--colors-signal-background-inverted-warning)"
  },
  "colors.signal.background.inverted.danger": {
    "value": "var(--colors-signal-background-inverted-danger)",
    "variable": "var(--colors-signal-background-inverted-danger)"
  },
  "colors.signal.background.inverted.neutral": {
    "value": "var(--colors-signal-background-inverted-neutral)",
    "variable": "var(--colors-signal-background-inverted-neutral)"
  },
  "colors.signal.background.inverted.brand": {
    "value": "var(--colors-signal-background-inverted-brand)",
    "variable": "var(--colors-signal-background-inverted-brand)"
  },
  "colors.signal.onBackground.regular.success": {
    "value": "var(--colors-signal-on-background-regular-success)",
    "variable": "var(--colors-signal-on-background-regular-success)"
  },
  "colors.signal.onBackground.regular.warning": {
    "value": "var(--colors-signal-on-background-regular-warning)",
    "variable": "var(--colors-signal-on-background-regular-warning)"
  },
  "colors.signal.onBackground.regular.danger": {
    "value": "var(--colors-signal-on-background-regular-danger)",
    "variable": "var(--colors-signal-on-background-regular-danger)"
  },
  "colors.signal.onBackground.regular.neutral": {
    "value": "var(--colors-signal-on-background-regular-neutral)",
    "variable": "var(--colors-signal-on-background-regular-neutral)"
  },
  "colors.signal.onBackground.regular.brand": {
    "value": "var(--colors-signal-on-background-regular-brand)",
    "variable": "var(--colors-signal-on-background-regular-brand)"
  },
  "colors.signal.onBackground.inverted.success": {
    "value": "var(--colors-signal-on-background-inverted-success)",
    "variable": "var(--colors-signal-on-background-inverted-success)"
  },
  "colors.signal.onBackground.inverted.warning": {
    "value": "var(--colors-signal-on-background-inverted-warning)",
    "variable": "var(--colors-signal-on-background-inverted-warning)"
  },
  "colors.signal.onBackground.inverted.danger": {
    "value": "var(--colors-signal-on-background-inverted-danger)",
    "variable": "var(--colors-signal-on-background-inverted-danger)"
  },
  "colors.signal.onBackground.inverted.neutral": {
    "value": "var(--colors-signal-on-background-inverted-neutral)",
    "variable": "var(--colors-signal-on-background-inverted-neutral)"
  },
  "colors.signal.onBackground.inverted.brand": {
    "value": "var(--colors-signal-on-background-inverted-brand)",
    "variable": "var(--colors-signal-on-background-inverted-brand)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  },
  "colors.colorPalette.50": {
    "value": "var(--colors-color-palette-50)",
    "variable": "var(--colors-color-palette-50)"
  },
  "colors.colorPalette.100": {
    "value": "var(--colors-color-palette-100)",
    "variable": "var(--colors-color-palette-100)"
  },
  "colors.colorPalette.200": {
    "value": "var(--colors-color-palette-200)",
    "variable": "var(--colors-color-palette-200)"
  },
  "colors.colorPalette.300": {
    "value": "var(--colors-color-palette-300)",
    "variable": "var(--colors-color-palette-300)"
  },
  "colors.colorPalette.400": {
    "value": "var(--colors-color-palette-400)",
    "variable": "var(--colors-color-palette-400)"
  },
  "colors.colorPalette.500": {
    "value": "var(--colors-color-palette-500)",
    "variable": "var(--colors-color-palette-500)"
  },
  "colors.colorPalette.600": {
    "value": "var(--colors-color-palette-600)",
    "variable": "var(--colors-color-palette-600)"
  },
  "colors.colorPalette.700": {
    "value": "var(--colors-color-palette-700)",
    "variable": "var(--colors-color-palette-700)"
  },
  "colors.colorPalette.800": {
    "value": "var(--colors-color-palette-800)",
    "variable": "var(--colors-color-palette-800)"
  },
  "colors.colorPalette.900": {
    "value": "var(--colors-color-palette-900)",
    "variable": "var(--colors-color-palette-900)"
  },
  "colors.colorPalette.950": {
    "value": "var(--colors-color-palette-950)",
    "variable": "var(--colors-color-palette-950)"
  },
  "colors.colorPalette.neutrals.0": {
    "value": "var(--colors-color-palette-neutrals-0)",
    "variable": "var(--colors-color-palette-neutrals-0)"
  },
  "colors.colorPalette.0": {
    "value": "var(--colors-color-palette-0)",
    "variable": "var(--colors-color-palette-0)"
  },
  "colors.colorPalette.neutrals.10": {
    "value": "var(--colors-color-palette-neutrals-10)",
    "variable": "var(--colors-color-palette-neutrals-10)"
  },
  "colors.colorPalette.10": {
    "value": "var(--colors-color-palette-10)",
    "variable": "var(--colors-color-palette-10)"
  },
  "colors.colorPalette.neutrals.20": {
    "value": "var(--colors-color-palette-neutrals-20)",
    "variable": "var(--colors-color-palette-neutrals-20)"
  },
  "colors.colorPalette.20": {
    "value": "var(--colors-color-palette-20)",
    "variable": "var(--colors-color-palette-20)"
  },
  "colors.colorPalette.neutrals.30": {
    "value": "var(--colors-color-palette-neutrals-30)",
    "variable": "var(--colors-color-palette-neutrals-30)"
  },
  "colors.colorPalette.30": {
    "value": "var(--colors-color-palette-30)",
    "variable": "var(--colors-color-palette-30)"
  },
  "colors.colorPalette.neutrals.40": {
    "value": "var(--colors-color-palette-neutrals-40)",
    "variable": "var(--colors-color-palette-neutrals-40)"
  },
  "colors.colorPalette.40": {
    "value": "var(--colors-color-palette-40)",
    "variable": "var(--colors-color-palette-40)"
  },
  "colors.colorPalette.neutrals.60": {
    "value": "var(--colors-color-palette-neutrals-60)",
    "variable": "var(--colors-color-palette-neutrals-60)"
  },
  "colors.colorPalette.60": {
    "value": "var(--colors-color-palette-60)",
    "variable": "var(--colors-color-palette-60)"
  },
  "colors.colorPalette.neutrals.70": {
    "value": "var(--colors-color-palette-neutrals-70)",
    "variable": "var(--colors-color-palette-neutrals-70)"
  },
  "colors.colorPalette.70": {
    "value": "var(--colors-color-palette-70)",
    "variable": "var(--colors-color-palette-70)"
  },
  "colors.colorPalette.neutrals.80": {
    "value": "var(--colors-color-palette-neutrals-80)",
    "variable": "var(--colors-color-palette-neutrals-80)"
  },
  "colors.colorPalette.80": {
    "value": "var(--colors-color-palette-80)",
    "variable": "var(--colors-color-palette-80)"
  },
  "colors.colorPalette.neutrals.90": {
    "value": "var(--colors-color-palette-neutrals-90)",
    "variable": "var(--colors-color-palette-neutrals-90)"
  },
  "colors.colorPalette.90": {
    "value": "var(--colors-color-palette-90)",
    "variable": "var(--colors-color-palette-90)"
  },
  "colors.colorPalette.neutrals.100": {
    "value": "var(--colors-color-palette-neutrals-100)",
    "variable": "var(--colors-color-palette-neutrals-100)"
  },
  "colors.colorPalette.neutrals.100-8": {
    "value": "var(--colors-color-palette-neutrals-100-8)",
    "variable": "var(--colors-color-palette-neutrals-100-8)"
  },
  "colors.colorPalette.100-8": {
    "value": "var(--colors-color-palette-100-8)",
    "variable": "var(--colors-color-palette-100-8)"
  },
  "colors.colorPalette.neutrals.100-12": {
    "value": "var(--colors-color-palette-neutrals-100-12)",
    "variable": "var(--colors-color-palette-neutrals-100-12)"
  },
  "colors.colorPalette.100-12": {
    "value": "var(--colors-color-palette-100-12)",
    "variable": "var(--colors-color-palette-100-12)"
  },
  "colors.colorPalette.neutrals.100-30": {
    "value": "var(--colors-color-palette-neutrals-100-30)",
    "variable": "var(--colors-color-palette-neutrals-100-30)"
  },
  "colors.colorPalette.100-30": {
    "value": "var(--colors-color-palette-100-30)",
    "variable": "var(--colors-color-palette-100-30)"
  },
  "colors.colorPalette.neutrals.100-50": {
    "value": "var(--colors-color-palette-neutrals-100-50)",
    "variable": "var(--colors-color-palette-neutrals-100-50)"
  },
  "colors.colorPalette.100-50": {
    "value": "var(--colors-color-palette-100-50)",
    "variable": "var(--colors-color-palette-100-50)"
  },
  "colors.colorPalette.neutrals.90-20": {
    "value": "var(--colors-color-palette-neutrals-90-20)",
    "variable": "var(--colors-color-palette-neutrals-90-20)"
  },
  "colors.colorPalette.90-20": {
    "value": "var(--colors-color-palette-90-20)",
    "variable": "var(--colors-color-palette-90-20)"
  },
  "colors.colorPalette.neutrals.90-30": {
    "value": "var(--colors-color-palette-neutrals-90-30)",
    "variable": "var(--colors-color-palette-neutrals-90-30)"
  },
  "colors.colorPalette.90-30": {
    "value": "var(--colors-color-palette-90-30)",
    "variable": "var(--colors-color-palette-90-30)"
  },
  "colors.colorPalette.neutrals.90-80": {
    "value": "var(--colors-color-palette-neutrals-90-80)",
    "variable": "var(--colors-color-palette-neutrals-90-80)"
  },
  "colors.colorPalette.90-80": {
    "value": "var(--colors-color-palette-90-80)",
    "variable": "var(--colors-color-palette-90-80)"
  },
  "colors.colorPalette.neutrals.0-0": {
    "value": "var(--colors-color-palette-neutrals-0-0)",
    "variable": "var(--colors-color-palette-neutrals-0-0)"
  },
  "colors.colorPalette.0-0": {
    "value": "var(--colors-color-palette-0-0)",
    "variable": "var(--colors-color-palette-0-0)"
  },
  "colors.colorPalette.neutrals.0-8": {
    "value": "var(--colors-color-palette-neutrals-0-8)",
    "variable": "var(--colors-color-palette-neutrals-0-8)"
  },
  "colors.colorPalette.0-8": {
    "value": "var(--colors-color-palette-0-8)",
    "variable": "var(--colors-color-palette-0-8)"
  },
  "colors.colorPalette.neutrals.0-15": {
    "value": "var(--colors-color-palette-neutrals-0-15)",
    "variable": "var(--colors-color-palette-neutrals-0-15)"
  },
  "colors.colorPalette.0-15": {
    "value": "var(--colors-color-palette-0-15)",
    "variable": "var(--colors-color-palette-0-15)"
  },
  "colors.colorPalette.neutrals.0-30": {
    "value": "var(--colors-color-palette-neutrals-0-30)",
    "variable": "var(--colors-color-palette-neutrals-0-30)"
  },
  "colors.colorPalette.0-30": {
    "value": "var(--colors-color-palette-0-30)",
    "variable": "var(--colors-color-palette-0-30)"
  },
  "colors.colorPalette.neutrals.0-50": {
    "value": "var(--colors-color-palette-neutrals-0-50)",
    "variable": "var(--colors-color-palette-neutrals-0-50)"
  },
  "colors.colorPalette.0-50": {
    "value": "var(--colors-color-palette-0-50)",
    "variable": "var(--colors-color-palette-0-50)"
  },
  "colors.colorPalette.sand.20": {
    "value": "var(--colors-color-palette-sand-20)",
    "variable": "var(--colors-color-palette-sand-20)"
  },
  "colors.colorPalette.sand.30": {
    "value": "var(--colors-color-palette-sand-30)",
    "variable": "var(--colors-color-palette-sand-30)"
  },
  "colors.colorPalette.sand.40": {
    "value": "var(--colors-color-palette-sand-40)",
    "variable": "var(--colors-color-palette-sand-40)"
  },
  "colors.colorPalette.sand.50": {
    "value": "var(--colors-color-palette-sand-50)",
    "variable": "var(--colors-color-palette-sand-50)"
  },
  "colors.colorPalette.sand.60": {
    "value": "var(--colors-color-palette-sand-60)",
    "variable": "var(--colors-color-palette-sand-60)"
  },
  "colors.colorPalette.sand.70": {
    "value": "var(--colors-color-palette-sand-70)",
    "variable": "var(--colors-color-palette-sand-70)"
  },
  "colors.colorPalette.sand.80": {
    "value": "var(--colors-color-palette-sand-80)",
    "variable": "var(--colors-color-palette-sand-80)"
  },
  "colors.colorPalette.sand.90": {
    "value": "var(--colors-color-palette-sand-90)",
    "variable": "var(--colors-color-palette-sand-90)"
  },
  "colors.colorPalette.sand.100": {
    "value": "var(--colors-color-palette-sand-100)",
    "variable": "var(--colors-color-palette-sand-100)"
  },
  "colors.colorPalette.amethyst.10": {
    "value": "var(--colors-color-palette-amethyst-10)",
    "variable": "var(--colors-color-palette-amethyst-10)"
  },
  "colors.colorPalette.amethyst.20": {
    "value": "var(--colors-color-palette-amethyst-20)",
    "variable": "var(--colors-color-palette-amethyst-20)"
  },
  "colors.colorPalette.amethyst.30": {
    "value": "var(--colors-color-palette-amethyst-30)",
    "variable": "var(--colors-color-palette-amethyst-30)"
  },
  "colors.colorPalette.amethyst.40": {
    "value": "var(--colors-color-palette-amethyst-40)",
    "variable": "var(--colors-color-palette-amethyst-40)"
  },
  "colors.colorPalette.amethyst.50": {
    "value": "var(--colors-color-palette-amethyst-50)",
    "variable": "var(--colors-color-palette-amethyst-50)"
  },
  "colors.colorPalette.amethyst.60": {
    "value": "var(--colors-color-palette-amethyst-60)",
    "variable": "var(--colors-color-palette-amethyst-60)"
  },
  "colors.colorPalette.amethyst.70": {
    "value": "var(--colors-color-palette-amethyst-70)",
    "variable": "var(--colors-color-palette-amethyst-70)"
  },
  "colors.colorPalette.amethyst.80": {
    "value": "var(--colors-color-palette-amethyst-80)",
    "variable": "var(--colors-color-palette-amethyst-80)"
  },
  "colors.colorPalette.amethyst.90": {
    "value": "var(--colors-color-palette-amethyst-90)",
    "variable": "var(--colors-color-palette-amethyst-90)"
  },
  "colors.colorPalette.amethyst.100": {
    "value": "var(--colors-color-palette-amethyst-100)",
    "variable": "var(--colors-color-palette-amethyst-100)"
  },
  "colors.colorPalette.amethyst.80-30": {
    "value": "var(--colors-color-palette-amethyst-80-30)",
    "variable": "var(--colors-color-palette-amethyst-80-30)"
  },
  "colors.colorPalette.80-30": {
    "value": "var(--colors-color-palette-80-30)",
    "variable": "var(--colors-color-palette-80-30)"
  },
  "colors.colorPalette.aquamarine.10": {
    "value": "var(--colors-color-palette-aquamarine-10)",
    "variable": "var(--colors-color-palette-aquamarine-10)"
  },
  "colors.colorPalette.aquamarine.20": {
    "value": "var(--colors-color-palette-aquamarine-20)",
    "variable": "var(--colors-color-palette-aquamarine-20)"
  },
  "colors.colorPalette.aquamarine.30": {
    "value": "var(--colors-color-palette-aquamarine-30)",
    "variable": "var(--colors-color-palette-aquamarine-30)"
  },
  "colors.colorPalette.aquamarine.40": {
    "value": "var(--colors-color-palette-aquamarine-40)",
    "variable": "var(--colors-color-palette-aquamarine-40)"
  },
  "colors.colorPalette.aquamarine.50": {
    "value": "var(--colors-color-palette-aquamarine-50)",
    "variable": "var(--colors-color-palette-aquamarine-50)"
  },
  "colors.colorPalette.aquamarine.60": {
    "value": "var(--colors-color-palette-aquamarine-60)",
    "variable": "var(--colors-color-palette-aquamarine-60)"
  },
  "colors.colorPalette.aquamarine.70": {
    "value": "var(--colors-color-palette-aquamarine-70)",
    "variable": "var(--colors-color-palette-aquamarine-70)"
  },
  "colors.colorPalette.aquamarine.80": {
    "value": "var(--colors-color-palette-aquamarine-80)",
    "variable": "var(--colors-color-palette-aquamarine-80)"
  },
  "colors.colorPalette.aquamarine.90": {
    "value": "var(--colors-color-palette-aquamarine-90)",
    "variable": "var(--colors-color-palette-aquamarine-90)"
  },
  "colors.colorPalette.aquamarine.100": {
    "value": "var(--colors-color-palette-aquamarine-100)",
    "variable": "var(--colors-color-palette-aquamarine-100)"
  },
  "colors.colorPalette.citrine.10": {
    "value": "var(--colors-color-palette-citrine-10)",
    "variable": "var(--colors-color-palette-citrine-10)"
  },
  "colors.colorPalette.citrine.20": {
    "value": "var(--colors-color-palette-citrine-20)",
    "variable": "var(--colors-color-palette-citrine-20)"
  },
  "colors.colorPalette.citrine.30": {
    "value": "var(--colors-color-palette-citrine-30)",
    "variable": "var(--colors-color-palette-citrine-30)"
  },
  "colors.colorPalette.citrine.40": {
    "value": "var(--colors-color-palette-citrine-40)",
    "variable": "var(--colors-color-palette-citrine-40)"
  },
  "colors.colorPalette.citrine.50": {
    "value": "var(--colors-color-palette-citrine-50)",
    "variable": "var(--colors-color-palette-citrine-50)"
  },
  "colors.colorPalette.citrine.60": {
    "value": "var(--colors-color-palette-citrine-60)",
    "variable": "var(--colors-color-palette-citrine-60)"
  },
  "colors.colorPalette.citrine.70": {
    "value": "var(--colors-color-palette-citrine-70)",
    "variable": "var(--colors-color-palette-citrine-70)"
  },
  "colors.colorPalette.citrine.80": {
    "value": "var(--colors-color-palette-citrine-80)",
    "variable": "var(--colors-color-palette-citrine-80)"
  },
  "colors.colorPalette.citrine.90": {
    "value": "var(--colors-color-palette-citrine-90)",
    "variable": "var(--colors-color-palette-citrine-90)"
  },
  "colors.colorPalette.citrine.100": {
    "value": "var(--colors-color-palette-citrine-100)",
    "variable": "var(--colors-color-palette-citrine-100)"
  },
  "colors.colorPalette.coral.10": {
    "value": "var(--colors-color-palette-coral-10)",
    "variable": "var(--colors-color-palette-coral-10)"
  },
  "colors.colorPalette.coral.20": {
    "value": "var(--colors-color-palette-coral-20)",
    "variable": "var(--colors-color-palette-coral-20)"
  },
  "colors.colorPalette.coral.30": {
    "value": "var(--colors-color-palette-coral-30)",
    "variable": "var(--colors-color-palette-coral-30)"
  },
  "colors.colorPalette.coral.40": {
    "value": "var(--colors-color-palette-coral-40)",
    "variable": "var(--colors-color-palette-coral-40)"
  },
  "colors.colorPalette.coral.50": {
    "value": "var(--colors-color-palette-coral-50)",
    "variable": "var(--colors-color-palette-coral-50)"
  },
  "colors.colorPalette.coral.60": {
    "value": "var(--colors-color-palette-coral-60)",
    "variable": "var(--colors-color-palette-coral-60)"
  },
  "colors.colorPalette.coral.70": {
    "value": "var(--colors-color-palette-coral-70)",
    "variable": "var(--colors-color-palette-coral-70)"
  },
  "colors.colorPalette.coral.80": {
    "value": "var(--colors-color-palette-coral-80)",
    "variable": "var(--colors-color-palette-coral-80)"
  },
  "colors.colorPalette.coral.90": {
    "value": "var(--colors-color-palette-coral-90)",
    "variable": "var(--colors-color-palette-coral-90)"
  },
  "colors.colorPalette.coral.100": {
    "value": "var(--colors-color-palette-coral-100)",
    "variable": "var(--colors-color-palette-coral-100)"
  },
  "colors.colorPalette.emerald.20": {
    "value": "var(--colors-color-palette-emerald-20)",
    "variable": "var(--colors-color-palette-emerald-20)"
  },
  "colors.colorPalette.emerald.40": {
    "value": "var(--colors-color-palette-emerald-40)",
    "variable": "var(--colors-color-palette-emerald-40)"
  },
  "colors.colorPalette.emerald.50": {
    "value": "var(--colors-color-palette-emerald-50)",
    "variable": "var(--colors-color-palette-emerald-50)"
  },
  "colors.colorPalette.emerald.60": {
    "value": "var(--colors-color-palette-emerald-60)",
    "variable": "var(--colors-color-palette-emerald-60)"
  },
  "colors.colorPalette.emerald.70": {
    "value": "var(--colors-color-palette-emerald-70)",
    "variable": "var(--colors-color-palette-emerald-70)"
  },
  "colors.colorPalette.amber.30": {
    "value": "var(--colors-color-palette-amber-30)",
    "variable": "var(--colors-color-palette-amber-30)"
  },
  "colors.colorPalette.amber.50": {
    "value": "var(--colors-color-palette-amber-50)",
    "variable": "var(--colors-color-palette-amber-50)"
  },
  "colors.colorPalette.amber.70": {
    "value": "var(--colors-color-palette-amber-70)",
    "variable": "var(--colors-color-palette-amber-70)"
  },
  "colors.colorPalette.amber.50-30": {
    "value": "var(--colors-color-palette-amber-50-30)",
    "variable": "var(--colors-color-palette-amber-50-30)"
  },
  "colors.colorPalette.50-30": {
    "value": "var(--colors-color-palette-50-30)",
    "variable": "var(--colors-color-palette-50-30)"
  },
  "colors.colorPalette.nucleusBlue.10": {
    "value": "var(--colors-color-palette-nucleus-blue-10)",
    "variable": "var(--colors-color-palette-nucleus-blue-10)"
  },
  "colors.colorPalette.nucleusBlue.20": {
    "value": "var(--colors-color-palette-nucleus-blue-20)",
    "variable": "var(--colors-color-palette-nucleus-blue-20)"
  },
  "colors.colorPalette.nucleusBlue.30": {
    "value": "var(--colors-color-palette-nucleus-blue-30)",
    "variable": "var(--colors-color-palette-nucleus-blue-30)"
  },
  "colors.colorPalette.nucleusBlue.60": {
    "value": "var(--colors-color-palette-nucleus-blue-60)",
    "variable": "var(--colors-color-palette-nucleus-blue-60)"
  },
  "colors.colorPalette.nucleusBlue.70": {
    "value": "var(--colors-color-palette-nucleus-blue-70)",
    "variable": "var(--colors-color-palette-nucleus-blue-70)"
  },
  "colors.colorPalette.nucleusBlue.10-30": {
    "value": "var(--colors-color-palette-nucleus-blue-10-30)",
    "variable": "var(--colors-color-palette-nucleus-blue-10-30)"
  },
  "colors.colorPalette.10-30": {
    "value": "var(--colors-color-palette-10-30)",
    "variable": "var(--colors-color-palette-10-30)"
  },
  "colors.colorPalette.green.20": {
    "value": "var(--colors-color-palette-green-20)",
    "variable": "var(--colors-color-palette-green-20)"
  },
  "colors.colorPalette.green.50": {
    "value": "var(--colors-color-palette-green-50)",
    "variable": "var(--colors-color-palette-green-50)"
  },
  "colors.colorPalette.green.60": {
    "value": "var(--colors-color-palette-green-60)",
    "variable": "var(--colors-color-palette-green-60)"
  },
  "colors.colorPalette.green.70": {
    "value": "var(--colors-color-palette-green-70)",
    "variable": "var(--colors-color-palette-green-70)"
  },
  "colors.colorPalette.yellow.20": {
    "value": "var(--colors-color-palette-yellow-20)",
    "variable": "var(--colors-color-palette-yellow-20)"
  },
  "colors.colorPalette.yellow.50": {
    "value": "var(--colors-color-palette-yellow-50)",
    "variable": "var(--colors-color-palette-yellow-50)"
  },
  "colors.colorPalette.red.10": {
    "value": "var(--colors-color-palette-red-10)",
    "variable": "var(--colors-color-palette-red-10)"
  },
  "colors.colorPalette.red.20": {
    "value": "var(--colors-color-palette-red-20)",
    "variable": "var(--colors-color-palette-red-20)"
  },
  "colors.colorPalette.red.50": {
    "value": "var(--colors-color-palette-red-50)",
    "variable": "var(--colors-color-palette-red-50)"
  },
  "colors.colorPalette.red.60": {
    "value": "var(--colors-color-palette-red-60)",
    "variable": "var(--colors-color-palette-red-60)"
  },
  "colors.colorPalette.red.70": {
    "value": "var(--colors-color-palette-red-70)",
    "variable": "var(--colors-color-palette-red-70)"
  },
  "colors.colorPalette.red.50-30": {
    "value": "var(--colors-color-palette-red-50-30)",
    "variable": "var(--colors-color-palette-red-50-30)"
  },
  "colors.colorPalette.green.10": {
    "value": "var(--colors-color-palette-green-10)",
    "variable": "var(--colors-color-palette-green-10)"
  },
  "colors.colorPalette.green.30": {
    "value": "var(--colors-color-palette-green-30)",
    "variable": "var(--colors-color-palette-green-30)"
  },
  "colors.colorPalette.green.40": {
    "value": "var(--colors-color-palette-green-40)",
    "variable": "var(--colors-color-palette-green-40)"
  },
  "colors.colorPalette.green.80": {
    "value": "var(--colors-color-palette-green-80)",
    "variable": "var(--colors-color-palette-green-80)"
  },
  "colors.colorPalette.green.90": {
    "value": "var(--colors-color-palette-green-90)",
    "variable": "var(--colors-color-palette-green-90)"
  },
  "colors.colorPalette.green.100": {
    "value": "var(--colors-color-palette-green-100)",
    "variable": "var(--colors-color-palette-green-100)"
  },
  "colors.colorPalette.green.100-8": {
    "value": "var(--colors-color-palette-green-100-8)",
    "variable": "var(--colors-color-palette-green-100-8)"
  },
  "colors.colorPalette.green.100-12": {
    "value": "var(--colors-color-palette-green-100-12)",
    "variable": "var(--colors-color-palette-green-100-12)"
  },
  "colors.colorPalette.green.90-30": {
    "value": "var(--colors-color-palette-green-90-30)",
    "variable": "var(--colors-color-palette-green-90-30)"
  },
  "colors.colorPalette.green.80-30": {
    "value": "var(--colors-color-palette-green-80-30)",
    "variable": "var(--colors-color-palette-green-80-30)"
  },
  "colors.colorPalette.gold.10": {
    "value": "var(--colors-color-palette-gold-10)",
    "variable": "var(--colors-color-palette-gold-10)"
  },
  "colors.colorPalette.gold.20": {
    "value": "var(--colors-color-palette-gold-20)",
    "variable": "var(--colors-color-palette-gold-20)"
  },
  "colors.colorPalette.gold.30": {
    "value": "var(--colors-color-palette-gold-30)",
    "variable": "var(--colors-color-palette-gold-30)"
  },
  "colors.colorPalette.gold.40": {
    "value": "var(--colors-color-palette-gold-40)",
    "variable": "var(--colors-color-palette-gold-40)"
  },
  "colors.colorPalette.gold.50": {
    "value": "var(--colors-color-palette-gold-50)",
    "variable": "var(--colors-color-palette-gold-50)"
  },
  "colors.colorPalette.gold.60": {
    "value": "var(--colors-color-palette-gold-60)",
    "variable": "var(--colors-color-palette-gold-60)"
  },
  "colors.colorPalette.gold.70": {
    "value": "var(--colors-color-palette-gold-70)",
    "variable": "var(--colors-color-palette-gold-70)"
  },
  "colors.colorPalette.gold.80": {
    "value": "var(--colors-color-palette-gold-80)",
    "variable": "var(--colors-color-palette-gold-80)"
  },
  "colors.colorPalette.gold.90": {
    "value": "var(--colors-color-palette-gold-90)",
    "variable": "var(--colors-color-palette-gold-90)"
  },
  "colors.colorPalette.gold.100": {
    "value": "var(--colors-color-palette-gold-100)",
    "variable": "var(--colors-color-palette-gold-100)"
  },
  "colors.colorPalette.gold.100-30": {
    "value": "var(--colors-color-palette-gold-100-30)",
    "variable": "var(--colors-color-palette-gold-100-30)"
  },
  "colors.colorPalette.gold.60-15": {
    "value": "var(--colors-color-palette-gold-60-15)",
    "variable": "var(--colors-color-palette-gold-60-15)"
  },
  "colors.colorPalette.60-15": {
    "value": "var(--colors-color-palette-60-15)",
    "variable": "var(--colors-color-palette-60-15)"
  },
  "colors.colorPalette.gold.60-30": {
    "value": "var(--colors-color-palette-gold-60-30)",
    "variable": "var(--colors-color-palette-gold-60-30)"
  },
  "colors.colorPalette.60-30": {
    "value": "var(--colors-color-palette-60-30)",
    "variable": "var(--colors-color-palette-60-30)"
  },
  "colors.colorPalette.gold.10-30": {
    "value": "var(--colors-color-palette-gold-10-30)",
    "variable": "var(--colors-color-palette-gold-10-30)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar