import { sva } from '@/styled-system/css';

export const anchorBarRecipe = sva({
	slots: ['positioner', 'container', 'triggerListItem', 'triggerContainer', 'border', 'trigger', 'scrollTopTrigger'],
	base: {
		positioner: {
			position: 'absolute',
			left: 0,
			pr: 'env(safe-area-inset-right)',
			pl: 'env(safe-area-inset-left)',

			width: 'full',
			backgroundColor: 'surface.interactive.transparent.regular.default',

			visibility: 'hidden',
			transform: 'translateY(-100%)',
			animation: 'slideOutToTop 0.2s ease-in',
			transition: 'visibility 0.2s ease-in',

			'&[data-visible="true"]': {
				visibility: 'visible',
				transform: 'translateY(0)',
				animation: 'slideInFromTop 0.2s ease-in',
			},
		},
		container: {
			height: 'anchorBarHeight',
			width: 'full',
			overflow: 'hidden',
			display: 'flex',
			alignItems: 'flex-end',
			maxW: { base: 'full', md: 'var(--content-max-width)' },
			marginX: 'auto',
			paddingX: { base: 0, md: 8, xl: 0 },
		},
		triggerContainer: {
			width: 'full',
			display: 'flex',
			alignItems: 'center',
			overflowX: 'auto',
		},
		triggerListItem: {
			width: 'full',
			height: 'full',
			display: 'flex',
			flexGrow: 1,
			whiteSpace: 'nowrap',
		},
		trigger: {
			fontSize: { base: 'sm', md: 'md' },
			fontWeight: 'semibold',
			lineHeight: '142%',
			color: 'text.regular.subtitle',
			borderBottom: '4px solid',
			borderColor: 'transparent',
			borderTopRadius: 'md',
			paddingX: 4,
			paddingTop: 3,
			paddingBottom: '9px',
			display: 'flex',
			flexGrow: 1,
			alignItems: 'end',
			justifyContent: 'center',
			cursor: 'pointer',

			'&[data-selected="true"]': {
				borderColor: 'other.indicator.regular.selected',
				color: 'text.regular.default',
			},

			_hover: {
				color: 'text.regular.default',
				borderColor: 'other.indicator.regular.hover',
				backgroundColor: 'surface.interactive.transparent.regular.hover',
			},
		},
		scrollTopTrigger: {
			position: 'relative',
			fontSize: { base: 'sm', md: 'md' },
			lineHeight: '142%',
			paddingLeft: 4,
			paddingRight: { base: 4, md: 4 },
			paddingTop: 3,
			paddingBottom: '9px',
			color: 'text.regular.default',
			fontWeight: 'semibold',
			borderLeft: '1px solid',
			borderLeftColor: 'other.border.regular.default',
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',

			borderBottom: '4px solid',
			borderColor: 'transparent',
			borderTopRightRadius: 'md',

			_hover: {
				borderBottom: '4px solid',
				borderBottomColor: 'other.indicator.regular.hover',
				backgroundColor: 'surface.interactive.transparent.regular.hover',
			},

			'& span > svg': {
				transform: 'rotate(-90deg)',
			},

			_before: {
				content: '""',
				position: 'absolute',
				top: 0,
				left: '-21px',
				width: '20px',
				height: 'full',
				background: 'linear-gradient(to right, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 100%)',
				pointerEvents: 'none',
			},
		},
	},
});
