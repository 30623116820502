import { hamburgerButtonRecipe } from '@/components/shared/Header/components/HamburgerButton/HamburgerButton.recipe';
import { Dialog } from '@ark-ui/react';

interface IHamburgerButtonProps {
	onClick: () => void;
	isOpen: boolean;
}

export const HamburgerButton = ({ onClick, isOpen }: IHamburgerButtonProps) => {
	const classes = hamburgerButtonRecipe({ open: isOpen });

	return (
		<>
			<Dialog.Trigger className={classes.root} onClick={onClick} title="Navigation" aria-label="Navigation">
				<div className={classes.bar}></div>
				<div className={classes.bar}></div>
				<div className={classes.bar}></div>
			</Dialog.Trigger>
		</>
	);
};
