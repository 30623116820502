import { ButtonIcon } from '@/components/core/Button/Button';
import { NextLink } from '@/components/core/NextLink/NextLink';
import { poly } from '@/libs/poly';
import { css, cx } from '@/styled-system/css';
import { styled } from '@/styled-system/jsx';
import { SystemStyleObject } from '@/styled-system/types';
import { ButtonVariants, button } from '@/styles/theme/button';
import { logger } from '@/utils/logger';
import { ComponentProps, ReactElement, ReactNode, forwardRef } from 'react';

const ButtonLinkNextContainer = styled(poly(NextLink));
const ButtonLinkAnchorContainer = styled(poly('a'));

export type IButtonLinkProps = Omit<ComponentProps<typeof ButtonLinkNextContainer>, 'onClick' | 'isLoading'> &
	Partial<ButtonVariants> & {
		children?: ReactNode;
		leftIcon?: ReactElement;
		rightIcon?: ReactElement;
		isLoading?: boolean;
		rootProps?: SystemStyleObject;
	};

// The TypeScript typings for this component are not correct. TODO: Review and make necessary corrections.
export const ButtonLink = forwardRef<HTMLLinkElement, IButtonLinkProps>((props: IButtonLinkProps, ref) => {
	const {
		variant,
		color,
		children,
		leftIcon,
		rightIcon,
		className,
		size,
		target,
		mode,
		widthVariant,
		href,
		rootProps = {},
		...rest
	} = props;

	if (!href) {
		logger.warn('ButtonLink: href is required. Id:', rest?.id);

		return null;
	}

	const ButtonLinkContainer =
		href.startsWith('tel:') || href.startsWith('mailto:') ? ButtonLinkAnchorContainer : ButtonLinkNextContainer;

	const isIconButton = !children;
	const displayType = isIconButton ? 'iconButton' : 'standard';

	const classes = button.raw({ size, variant, displayType, mode, widthVariant });

	return (
		<ButtonLinkContainer
			className={cx(css(classes.root, rootProps), className)}
			target={target}
			href={href}
			ref={ref}
			{...rest}
		>
			{leftIcon && (
				<ButtonIcon className={css(classes.icon)} color={leftIcon.props.color}>
					{leftIcon}
				</ButtonIcon>
			)}
			{children}
			{rightIcon && (
				<ButtonIcon className={css(classes.icon)} color={rightIcon.props.color}>
					{rightIcon}
				</ButtonIcon>
			)}
		</ButtonLinkContainer>
	);
});
