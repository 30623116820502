import { SiteName } from '@/types/feature-flags';
import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

export const logoComponents: Record<SiteName, ComponentType | null> = {
	vonsec: dynamic(() => import('@/components/shared/Logo/components/VontobelLogo').then((mod) => mod.VontobelLogo)),
	corpweb: dynamic(() => import('@/components/shared/Logo/components/VontobelLogo').then((mod) => mod.VontobelLogo)),
	vorsorgestiftung: null,
	spendenstiftung: dynamic(() =>
		import('@/components/shared/Logo/components/SpendenstiftungLogo').then((mod) => mod.SpendenstiftungLogo)
	),
	cosmofunding: dynamic(() =>
		import('@/components/shared/Logo/components/CosmofundingLogo').then((mod) => mod.CosmofundingLogo)
	),
	deritrade: dynamic(() =>
		import('@/components/shared/Logo/components/DeritradeLogo').then((mod) => mod.DeritradeLogo)
	),
	lyra: dynamic(() => import('@/components/shared/Logo/components/LyraLogo').then((mod) => mod.LyraLogo)),
	vosti: dynamic(() => import('@/components/shared/Logo/components/VostiLogo').then((mod) => mod.VostiLogo)),
	tb: dynamic(() => import('@/components/shared/Logo/components/VontobelLogo').then((mod) => mod.VontobelLogo)),
	volt: dynamic(() => import('@/components/shared/Logo/components/VoltLogo').then((mod) => mod.VoltLogo)),
};
