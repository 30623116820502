import { Button } from '@/components/core/Button/Button';
import { Text } from '@/components/core/Text/Text';
import { IPoll } from '@/components/shared/Poll/Poll';
import { PollType } from '@/components/shared/Poll/PollType.enum';
import { SpinnerIcon } from '@/icons/SpinnerIcon';
import { css } from '@/styled-system/css';
import { styled } from '@/styled-system/jsx';
import { link } from '@/styled-system/recipes/link';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';

interface IPollActionBarProps {
	classes?: string;
	poll: IPoll;
	isSubmitting: boolean;
	isSubmitted: boolean;
	isSubmitDisabled: boolean;
	voteCount?: number;
	translations: TranslationLabelValues;
	onSubmitForm: (selectedAnswerIds: Array<number>) => void;
	onShowResults: () => void;
}

export const PollActionBar = ({
	classes,
	poll,
	onSubmitForm,
	translations,
	isSubmitted,
	isSubmitting,
	isSubmitDisabled = false,
	onShowResults,
	voteCount,
}: IPollActionBarProps) => {
	return (
		<styled.div className={classes}>
			{poll.allowMultiVoting && (
				<Button
					disabled={isSubmitting || isSubmitted || isSubmitDisabled}
					onClick={onSubmitForm}
					title={translate(translations, 'polls.submitButton.title')}
					aria-label={translate(translations, 'polls.submitButton.title')}
				>
					{translate(translations, 'polls.submitButton.label')}
				</Button>
			)}
			<styled.div tabIndex={-1} flexGrow={1}></styled.div>
			{poll.type === PollType.QUIZ && !poll.hideResultsAfterVoting && (
				<styled.div display="flex">
					{voteCount === undefined && (
						<SpinnerIcon
							className={css({
								animation: 'rotating 1s linear infinite',
							})}
							width="20px"
							height="20px"
						/>
					)}
					{voteCount !== undefined && (
						<Text size="sm">
							{voteCount !== undefined ? Math.max(voteCount, 5) : ''}{' '}
							{translate(translations, 'polls.participantCountLabel')}
						</Text>
					)}
				</styled.div>
			)}
			{poll.type !== PollType.QUIZ && (
				<styled.button
					onClick={onShowResults}
					className={link({
						size: 'sm',
						mode: 'standard',
					})}
					title={translate(translations, 'polls.showResultsButton.title')}
					aria-label={translate(translations, 'polls.showResultsButton.title')}
					disabled={isSubmitted}
					// Hide the button when the poll is submitted instead of removing it to prevent layout shift
					visibility={isSubmitted ? 'hidden' : 'visible'}
				>
					{translate(translations, 'polls.showResultsButton.label')}
				</styled.button>
			)}
		</styled.div>
	);
};
