import { SVGAttributes } from 'react';

export const CheckIcon = (props: SVGAttributes<SVGElement>) => (
	<svg viewBox="0 0 9 7" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M3.56455 6.06921L8.46119 1.17257L7.7398 0.451172L2.84316 5.34781L0.801474 3.30613L0.0800781 4.02752L2.84316 6.7906L3.56455 6.06921Z"
			fill="currentColor"
		/>
	</svg>
);
