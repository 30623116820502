import { Checkbox } from '@/components/core/Checkbox';
import { PollType } from '@/components/shared/Poll/PollType.enum';
import { CheckIcon } from '@/icons/CheckIcon';
import { CloseIcon } from '@/icons/CloseIcon';
import { PlusIcon } from '@/icons/PlusIcon';

interface IPollAnswerIconProps {
	pollType: PollType;
	isVoted: boolean;
	allowMultiVoting: boolean;
	isSelected: boolean;
	isCorrect?: boolean;
	answerText: string;
}

export const PollAnswerIcon = ({
	allowMultiVoting = false,
	isVoted,
	isSelected,
	pollType,
	isCorrect,
	answerText,
}: IPollAnswerIconProps) => {
	if (!isVoted && allowMultiVoting) {
		return (
			<Checkbox
				// Checkbox onChange left unassigned because we are handling the change in the parent component
				// The reason being that we can't call preventDefault on the checkbox as no click event is exposed so the event bubbles to the parent
				isChecked={isSelected}
				label=""
				ariaLabel={answerText}
			/>
		);
	}

	if (!isVoted && !allowMultiVoting && pollType === PollType.QUIZ) {
		return <CheckIcon width="20px" height="20px" />;
	}

	if (!isVoted && !allowMultiVoting && pollType !== PollType.QUIZ) {
		return <PlusIcon width="32px" height="32px" />;
	}

	if (isVoted && pollType === PollType.VOTE && isSelected) {
		return <CheckIcon width="20px" height="20px" />;
	}

	if (isVoted && pollType === PollType.QUIZ) {
		return votedQuizAnswerIcon({ isCorrect: isCorrect!, isSelected });
	}

	return null;
};

const votedQuizAnswerIcon: React.FC<{ isCorrect: boolean; isSelected: boolean }> = ({ isCorrect, isSelected }) => {
	if (isCorrect) {
		return <CheckIcon width="20px" height="20px" />;
	}

	if (!isCorrect && isSelected) {
		return <CloseIcon width="32px" height="32px" />;
	}

	if (!isCorrect && !isSelected) {
		return <CloseIcon width="32px" height="32px" />;
	}
};
