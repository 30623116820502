import { textSnippetRecipe } from '@/components/shared/TextSnippet/textSnippet.recipe';
import { BackgroundColorVariant } from '@/enums/ColorMode';
import { Flex, VStack } from '@/styled-system/jsx';

export const NavigationWelcome = ({ data = '' }: { data: string }) => {
	const className = textSnippetRecipe({ parentBackgroundColor: BackgroundColorVariant.PRIMARY });

	return (
		<Flex alignSelf="stretch" flex="2" justifyContent="center">
			<VStack className={className} alignItems="flex-start" dangerouslySetInnerHTML={{ __html: data?.trim() }} />
		</Flex>
	);
};
