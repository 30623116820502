'use client';

import { Text } from '@/components/core/Text/Text';
import { pollAnswerRecipe } from '@/components/shared/Poll/components/PollAnswer.recipe';
import { useAnimatedPollAnswerValue } from '@/components/shared/Poll/components/PollAnswer.hooks';
import { PollAnswerIcon } from '@/components/shared/Poll/components/PollAnswerIcon';
import { PollAnswerValue } from '@/components/shared/Poll/components/PollAnswerValue';
import { PollType } from '@/components/shared/Poll/PollType.enum';
import { cx } from '@/styled-system/css';
import { styled } from '@/styled-system/jsx';
import { translate, TranslationLabelValues } from '@/utils/i18n/translation-labels/translationLabels';
import { Progress } from '@ark-ui/react';

interface IPollAnswer {
	index: number;
	answerText: string;
	percentageValue?: number;
	absoluteValue?: number;
	type: PollType;
	isSelected: boolean;
	isCorrect?: boolean;
	disabled: boolean;
	isVoted: boolean;
	allowMultiVoting?: boolean;
	shouldShowAbsoluteValue: boolean;
	translations: TranslationLabelValues;
	onClick: () => void;
	onChangeResultLabel: () => void;
}

export const PollAnswer = ({
	index,
	answerText,
	percentageValue,
	absoluteValue,
	type,
	isSelected,
	isCorrect,
	disabled,
	isVoted,
	allowMultiVoting,
	shouldShowAbsoluteValue = false,
	translations,
	onClick,
	onChangeResultLabel,
}: IPollAnswer) => {
	let progressBarValue = percentageValue;

	if (type === PollType.QUIZ && isVoted && isCorrect) {
		progressBarValue = 100;
	}
	const animatedValue = useAnimatedPollAnswerValue(progressBarValue);

	const classes = pollAnswerRecipe({
		voted: isVoted ? 'voted' : 'notVoted',
		type,
		correctness: isCorrect ? 'correct' : 'incorrect',
		selected: isSelected ? 'selected' : 'notSelected',
		index,
	});

	const AnswerContainer = disabled ? styled.div : styled.button;

	return (
		<AnswerContainer
			className={classes.root}
			// In case of multi voting, we will render a checkbox and it will be tabbable
			tabIndex={allowMultiVoting || disabled ? -1 : 0}
			onClick={() => {
				if (disabled) {
					return;
				}

				onClick();
			}}
			onKeyDown={(e) => {
				if (disabled) {
					return;
				}

				if (e.key === 'Enter' || e.key === ' ') {
					onClick();
				}
			}}
			title={`${answerText} ${translate(translations, 'polls.answer.titleSuffix')}`}
			disabled={disabled}
		>
			<styled.div className={classes.iconContainer}>
				{type !== PollType.SENTIMENT && (
					<PollAnswerIcon
						allowMultiVoting={!!allowMultiVoting}
						isVoted={isVoted}
						isSelected={isSelected}
						pollType={type}
						isCorrect={isCorrect}
						answerText={answerText}
					/>
				)}
			</styled.div>
			<styled.div className={classes.answerLabel}>
				<Text size="lg">{answerText}</Text>
				{animatedValue !== undefined && (
					<Progress.Root className={classes.progressRoot} value={animatedValue}>
						<Progress.Track>
							<Progress.Range className={cx(classes.progressRange)} />
						</Progress.Track>
					</Progress.Root>
				)}
			</styled.div>

			{disabled && type !== PollType.QUIZ && (
				<PollAnswerValue
					answerText={answerText}
					absoluteValue={absoluteValue}
					animatedPercentageValue={animatedValue}
					onChangeResultLabel={onChangeResultLabel}
					shouldShowAbsoluteValue={shouldShowAbsoluteValue}
					parentClasses={classes.answerValue}
					translations={translations}
				/>
			)}
		</AnswerContainer>
	);
};
