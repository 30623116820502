// eslint-disable-next-line no-restricted-imports
import Link, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes, ReactNode, RefAttributes, forwardRef } from 'react';

type NextLinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
	LinkProps & {
		children?: ReactNode;
	} & RefAttributes<HTMLAnchorElement>;

export const NextLink = forwardRef<HTMLAnchorElement, NextLinkProps>(({ children, ...rest }, ref) => {
	return (
		<Link {...rest} ref={ref} prefetch={false}>
			{children}
		</Link>
	);
});
