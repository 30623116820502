import { themeContract } from './contract';

export const amethystTheme = themeContract({
	semanticTokens: {
		fonts: {
			heading: {
				h1: {
					value: '{fonts.serif}',
				},
			},
		},
		fontWeights: {
			heading: {
				h1: {
					bold: {
						value: '1000',
					},
				},
			},
		},
		colors: {
			text: {
				regular: {
					default: {
						value: '{colors.base.neutrals.90}',
					},
					subtitle: {
						value: '{colors.base.neutrals.70}',
					},
					disabled: {
						value: '{colors.base.neutrals.100-30}',
					},
					pressed: {
						value: '{colors.base.neutrals.100}',
					},
					danger: {
						value: '{colors.signal.red.60}',
					},
					success: {
						value: '{colors.secondary.emerald.60}',
					},
				},
				inverted: {
					default: {
						value: '{colors.base.neutrals.0}',
					},
					subtitle: {
						value: '{colors.base.neutrals.40}',
					},
					disabled: {
						value: '{colors.base.neutrals.0-30}',
					},
					pressed: {
						value: '{colors.base.amethyst.30}',
					},
					danger: {
						value: '{colors.signal.red.50}',
					},
					success: {
						value: '{colors.secondary.emerald.50}',
					},
				},
			},
			icons: {
				regular: {
					default: {
						value: '{colors.base.neutrals.90}',
					},
					secondary: {
						value: '{colors.base.neutrals.70}',
					},
					disabled: {
						value: '{colors.base.neutrals.100-30}',
					},
					pressed: {
						value: '{colors.base.neutrals.100}',
					},
					danger: {
						value: '{colors.signal.red.60}',
					},
				},
				inverted: {
					default: {
						value: '{colors.base.neutrals.0}',
					},
					secondary: {
						value: '{colors.base.neutrals.40}',
					},
					disabled: {
						value: '{colors.base.neutrals.0-30}',
					},
					pressed: {
						value: '{colors.base.amethyst.30}',
					},
					danger: {
						value: '{colors.signal.red.50}',
					},
				},
				// Not applicable
				accent: {
					aquamarine: {
						value: '',
					},
					coral: {
						value: '',
					},
					citrine: {
						value: '',
					},
					sand: {
						value: '',
					},
				},
			},
			surface: {
				static: {
					primary: {
						value: '{colors.base.neutrals.0}',
					},
					secondary: {
						value: '{colors.base.neutrals.30}',
					},
					tertiary: {
						value: '{colors.base.neutrals.40}',
					},
					chat: {
						value: '{colors.base.neutrals.20}',
					},
					inverted: {
						value: '{colors.base.neutrals.90}',
					},
					primaryInverted: {
						value: '{colors.base.amethyst.90}',
					},
					invertedException: {
						value: '{colors.base.neutrals.100}',
					},
					aquamarine: {
						value: '{colors.base.neutrals.40}',
					},
					citrine: {
						value: '{colors.base.neutrals.30}',
					},
					coral: {
						value: '{colors.base.amethyst.10}',
					},
				},
				interactive: {
					card: {
						primary: {
							value: '{colors.base.neutrals.0}',
						},
						secondary: {
							value: '{colors.base.neutrals.30}',
						},
						inverted: {
							value: '{colors.base.neutrals.90}',
						},
						primaryInverted: {
							value: '{colors.base.amethyst.80}',
						},
						primaryAccent: {
							value: '{colors.base.neutrals.40}',
						},
						secondaryAccent: {
							value: '{colors.base.neutrals.30}',
						},
						tertiaryAccent: {
							value: '{colors.base.neutrals.30}',
						},
					},
					transparent: {
						regular: {
							default: {
								value: '{colors.base.neutrals.0}',
							},
							hover: {
								value: '{colors.base.neutrals.20}',
							},
							pressed: {
								value: '{colors.base.neutrals.30}',
							},
							disabled: {
								value: '{colors.base.neutrals.20}',
							},
						},
						inverted: {
							default: {
								value: '{colors.base.amethyst.90}',
							},
							hover: {
								value: '{colors.base.neutrals.100-30}',
							},
							pressed: {
								value: '{colors.base.neutrals.100-30}',
							},
							disabled: {
								value: '{colors.base.amethyst.80}',
							},
						},
					},
					input: {
						regular: {
							hoverAndPressed: {
								value: '{colors.base.neutrals.10}',
							},
						},
						inverted: {
							hoverAndPressed: {
								value: '{colors.base.neutrals.100-30}',
							},
						},
					},
					accordion: {
						regular: {
							fillDefault: {
								value: '{colors.base.neutrals.0}',
							},
							fillDefaultOpened: {
								value: '{colors.base.neutrals.30}',
							},
							fillHover: {
								value: '{colors.base.neutrals.20}',
							},
							fillHoverOpened: {
								value: '{colors.base.neutrals.40}',
							},
						},
						inverted: {
							fillDefault: {
								value: '{colors.base.amethyst.90}',
							},
							fillDefaultOpened: {
								value: '{colors.base.neutrals.0-8}',
							},
							fillHover: {
								value: '{colors.base.neutrals.0-8}',
							},
							fillHoverOpened: {
								value: '{colors.base.neutrals.0-30}',
							},
						},
						sand: {
							fillDefault: {
								value: '{colors.base.neutrals.30}',
							},
							fillDefaultOpened: {
								value: '{colors.base.neutrals.40}',
							},
							fillHover: {
								value: '{colors.base.neutrals.40}',
							},
							fillHoverOpened: {
								value: '{colors.base.neutrals.60}',
							},
						},
					},
					skeleton: {
						regular: {
							value: '{colors.base.neutrals.100-8}',
						},
						inverted: {
							value: '{colors.base.neutrals.0-8}',
						},
					},
				},
			},
			actions: {
				primary: {
					regular: {
						fillDefault: {
							value: '{colors.base.amethyst.80}',
						},
						textAndIconDefault: {
							value: '{colors.base.neutrals.0}',
						},
						fillHover: {
							value: '{colors.base.amethyst.100}',
						},
						fillPressed: {
							value: '{colors.base.amethyst.100}',
						},
						textAndIconPressed: {
							value: '{colors.base.amethyst.40}',
						},
						fillDisabled: {
							value: '{colors.base.amethyst.80-30}',
						},
					},
					inverted: {
						fillDefault: {
							value: '{colors.base.neutrals.0}',
						},
						textAndIconDefault: {
							value: '{colors.base.amethyst.90}',
						},
						fillHover: {
							value: '{colors.base.amethyst.30}',
						},
						fillPressed: {
							value: '{colors.base.amethyst.40}',
						},
						textAndIconPressed: {
							value: '{colors.base.amethyst.90}',
						},
						fillDisabled: {
							value: '{colors.base.neutrals.0-30}',
						},
					},
				},
				primaryDanger: {
					regular: {
						fillDefault: {
							value: '{colors.signal.red.60}',
						},
						fillHoverAndPressed: {
							value: '{colors.signal.red.70}',
						},
						fillDisabled: {
							value: '{colors.base.neutrals.90-30}',
						},
					},
					inverted: {
						fillDefault: {
							value: '{colors.signal.red.60}',
						},
						fillHoverAndPressed: {
							value: '{colors.signal.red.70}',
						},
						fillDisabled: {
							value: '{colors.base.neutrals.0-30}',
						},
					},
				},
				secondary: {
					regular: {
						strokeDefault: {
							value: '{colors.base.neutrals.90}',
						},
						fillHover: {
							value: '{colors.base.neutrals.100-8}',
						},
						fillPressed: {
							value: '{colors.base.neutrals.100-12}',
						},
						strokeDisabled: {
							value: '{colors.base.neutrals.100-30}',
						},
					},
					inverted: {
						default: {
							value: '{colors.base.neutrals.0}',
						},
						fillHover: {
							value: '{colors.base.neutrals.0-8}',
						},
						fillPressed: {
							value: '{colors.base.neutrals.0-15}',
						},
						strokeDisabled: {
							value: '{colors.base.neutrals.0-30}',
						},
					},
				},
				secondaryDanger: {
					regular: {
						strokeDefault: {
							value: '{colors.signal.red.60}',
						},
						fillHover: {
							value: '{colors.signal.red.10}',
						},
						fillPressed: {
							value: '{colors.signal.red.20}',
						},
						strokeDisabled: {
							value: '{colors.base.neutrals.90-30}',
						},
					},
					inverted: {
						strokeDefault: {
							value: '{colors.signal.red.50}',
						},
						fillHover: {
							value: '{colors.base.neutrals.100-30}',
						},
						fillPressed: {
							value: '{colors.base.neutrals.100-30}',
						},
						strokeDisabled: {
							value: '{colors.base.neutrals.0-30}',
						},
					},
				},
				tertiary: {
					regular: {
						fillDefault: {
							value: '{colors.base.neutrals.30}',
						},
						fillHover: {
							value: '{colors.base.neutrals.40}',
						},
						fillPressed: {
							value: '{colors.base.neutrals.60}',
						},
						fillDisabled: {
							value: '{colors.base.neutrals.20}',
						},
					},
					inverted: {
						fillDefault: {
							value: '{colors.base.neutrals.60}',
						},
						fillHover: {
							value: '{colors.base.neutrals.80}',
						},
						fillPressed: {
							value: '{colors.base.neutrals.90}',
						},
						fillDisabled: {
							value: '{colors.base.neutrals.90-20}',
						},
					},
				},
				links: {
					regular: {
						default: {
							value: '{colors.base.amethyst.60}',
						},
						hoverAndPressed: {
							value: '{colors.base.amethyst.80}',
						},
						visited: {
							value: '{colors.base.amethyst.60}',
						},
						disabled: {
							value: '{colors.base.amethyst.30}',
						},
					},
					inverted: {
						default: {
							value: '{colors.base.amethyst.30}',
						},
						hoverAndPressed: {
							value: '{colors.base.amethyst.20}',
						},
						visited: {
							value: '{colors.base.amethyst.30}',
						},
					},
				},
				controls: {
					regular: {
						onFillDefault: {
							value: '{colors.base.amethyst.60}',
						},
						onFillHoverAndPressed: {
							value: '{colors.base.amethyst.80}',
						},
						onStrokeFocus: {
							value: '{colors.base.amethyst.10}',
						},
						onFillDisabled: {
							value: '{colors.base.amethyst.20}',
						},
						offFillDefault: {
							value: '{colors.base.neutrals.100-30}',
						},
						offFillHoverAndPressed: {
							value: '{colors.base.neutrals.100-50}',
						},
						offFillDisabled: {
							value: '{colors.base.neutrals.100-8}',
						},
						fillThumb: {
							value: '{colors.base.neutrals.0}',
						},
					},
					inverted: {
						onFillDefault: {
							value: '{colors.base.amethyst.30}',
						},
						onFillHoverAndPressed: {
							value: '{colors.base.amethyst.20}',
						},
						onStrokeFocus: {
							value: '{colors.base.amethyst.70}',
						},
						onFillDisabled: {
							value: '{colors.base.amethyst.70}',
						},
						offFillDefault: {
							value: '{colors.base.neutrals.0-30}',
						},
						offFillHoverAndPressed: {
							value: '{colors.base.neutrals.0-50}',
						},
						offFillDisabled: {
							value: '{colors.base.neutrals.0-8}',
						},
						fillThumb: {
							value: '{colors.base.neutrals.0}',
						},
					},
				},
				controlsDanger: {
					regular: {
						onFillDefault: {
							value: '{colors.signal.red.60}',
						},
						onFillHoverAndPressed: {
							value: '{colors.signal.red.70}',
						},
						onStrokeFocus: {
							value: '{colors.signal.red.20}',
						},
						onFillDisabled: {
							value: '{colors.signal.red.20}',
						},
					},
					inverted: {
						onFillDefault: {
							value: '{colors.signal.red.50}',
						},
						onFillHoverAndPressed: {
							value: '{colors.signal.red.60}',
						},
						onStrokeFocus: {
							value: '{colors.signal.red.50-30}',
						},
						onFillDisabled: {
							value: '{colors.signal.red.50-30}',
						},
					},
				},
				filterTags: {
					regular: {
						fillDefault: {
							value: '{colors.base.neutrals.0}',
						},
						strokeHover: {
							value: '{colors.base.amethyst.60}',
						},
						fillSelected: {
							value: '{colors.base.amethyst.70}',
						},
						fillSelectedHoverAndPressed: {
							value: '{colors.base.amethyst.80}',
						},
						fillDisabled: {
							value: '{colors.base.amethyst.20}',
						},
						textAndIconDisabled: {
							value: '{colors.base.neutrals.100-30}',
						},
					},
					inverted: {
						fillDefault: {
							value: '{colors.base.amethyst.90}',
						},
						strokeHover: {
							value: '{colors.base.amethyst.30}',
						},
						fillSelected: {
							value: '{colors.base.amethyst.30}',
						},
						fillSelectedHoverAndPressed: {
							value: '{colors.base.amethyst.20}',
						},
						fillDisabled: {
							value: '{colors.base.amethyst.10}',
						},
						textAndIconDisabled: {
							value: '{colors.base.neutrals.100-30}',
						},
					},
				},
				uploads: {
					fillHover: {
						value: '{colors.base.amethyst.10}',
					},
					strokeHover: {
						value: '{colors.base.amethyst.60}',
					},
				},
			},
			charts: {
				polls: {
					background: {
						regular: {
							positive: {
								90: { value: '{colors.evolution.aquamarine.80}' },
								80: { value: '{colors.evolution.aquamarine.70}' },
								70: { value: '{colors.evolution.aquamarine.70}' },
								60: { value: '{colors.evolution.aquamarine.60}' },
							},
							neutral: {
								value: '{colors.base.neutrals.60}',
							},
							negative: {
								60: {
									value: '{colors.base.neutrals.40}',
								},
								70: {
									value: '{colors.base.neutrals.30}',
								},
								80: {
									value: '{colors.base.neutrals.20}',
								},
								90: {
									value: '{colors.base.neutrals.10}',
								},
							},
						},
					},
				},
			},
			other: {
				scrim: {
					value: '{colors.base.amethyst.80-30}',
				},
				indicator: {
					regular: {
						hover: {
							value: '{colors.base.amethyst.80}',
						},
						selected: {
							value: '{colors.base.amethyst.60}',
						},
						default: {
							value: '{colors.base.neutrals.90-30}',
						},
					},
					inverted: {
						hover: {
							value: '{colors.base.amethyst.70}',
						},
						selected: {
							value: '{colors.base.amethyst.30}',
						},
					},
				},
				border: {
					regular: {
						default: {
							value: '{colors.base.neutrals.90-20}',
						},
						strong: {
							value: '{colors.base.neutrals.80}',
						},
						weak: {
							value: '{colors.base.amethyst.20}',
						},
						focus: {
							value: '{colors.base.amethyst.90}',
						},
						disabled: {
							value: '{colors.base.amethyst.20}',
						},
						shareSpecial: {
							value: '{colors.base.neutrals.90-20}',
						},
					},
					inverted: {
						default: {
							value: '{colors.base.neutrals.0-15}',
						},
						shareSpecial: {
							value: '{colors.base.neutrals.0-15}',
						},
						focus: {
							value: '{colors.base.neutrals.0}',
						},
					},
					sand: {
						hairline: {
							value: '{colors.base.neutrals.40}',
						},
						weak: {
							value: '{colors.base.neutrals.70}',
						},
						strong: {
							value: '{colors.base.neutrals.80}',
						},
					},
				},
				pattern: {
					aquamarine: { value: '{colors.base.neutrals.60}' },
					citrine: { value: '{colors.base.neutrals.40}' },
					coral: { value: '{colors.charts.amethyst.60}' },
					dark: { value: '{colors.base.neutrals.90}' },
					light: { value: '{colors.base.neutrals.0}' },
				},
			},
			trafficLights: {
				success: {
					onBackground: {
						value: '{colors.secondary.emerald.70}',
					},
					default: {
						value: '{colors.secondary.emerald.60}',
					},
					variant: {
						value: '{colors.secondary.emerald.60}',
					},
					background: {
						value: '{colors.secondary.emerald.20}',
					},
				},
				warning: {
					default: {
						value: '{colors.secondary.amber.50}',
					},
					background: {
						value: '{colors.secondary.amber.50-30}',
					},
				},
				error: {
					onBackground: {
						value: '{colors.signal.red.70}',
					},
					default: {
						value: '{colors.signal.red.60}',
					},
					variant: {
						value: '{colors.signal.red.50}',
					},
					background: {
						value: '{colors.signal.red.20}',
					},
					backgroundVariant: {
						value: '{colors.signal.red.10}',
					},
				},
			},
			signal: {
				background: {
					regular: {
						success: {
							value: '{colors.secondary.emerald.20}',
						},
						warning: {
							value: '{colors.secondary.amber.50-30}',
						},
						danger: {
							value: '{colors.signal.red.20}',
						},
						neutral: {
							value: '{colors.base.neutrals.30}',
						},
						information: {
							value: '{colors.base.amethyst.30}',
						},
					},
					inverted: {
						success: {
							value: '{colors.signal.green.20}',
						},
						warning: {
							value: '{colors.secondary.amber.50-30}',
						},
						danger: {
							value: '{colors.signal.red.20}',
						},
						neutral: {
							value: '{colors.base.neutrals.30}',
						},
						brand: {
							value: '{colors.base.amethyst.30}',
						},
					},
				},
				onBackground: {
					regular: {
						success: {
							value: '{colors.secondary.emerald.70}',
						},
						warning: {
							value: '{colors.base.neutrals.90}',
						},
						danger: {
							value: '{colors.signal.red.70}',
						},
						neutral: {
							value: '{colors.base.neutrals.90}',
						},
						brand: {
							value: '{colors.base.amethyst.90}',
						},
					},
					inverted: {
						success: {
							value: '{colors.secondary.emerald.70}',
						},
						warning: {
							value: '{colors.base.neutrals.90}',
						},
						danger: {
							value: '{colors.signal.red.70}',
						},
						neutral: {
							value: '{colors.base.neutrals.90}',
						},
						brand: {
							value: '{colors.base.amethyst.90}',
						},
					},
				},
			},
		},
	},
});
