import { IAnchorBarItems } from '@/components/shared/AnchorBar/AnchorBar';
import { ContentType } from '@/enums/ContentType';
import { IPageContentWithPageElements } from '@/interfaces/coreInformation';
import { ITrackedBlockInformation } from '@/types/tracking';
import { generateBlockId } from '@/utils/generateBlockId';

export const formatSectionTitleForHash = (title: string) => {
	return title ? title.replaceAll(' ', '-').toLowerCase() : '';
};

export const getAnchorBarItems = (pageDefinition: IPageContentWithPageElements): Array<IAnchorBarItems> => {
	if (pageDefinition.contentType[1] === ContentType.DailyTelegramPage) {
		return (
			pageDefinition.mainContentArea
				?.filter((block) => block.contentLink?.expanded?.contentType?.at(-1) === ContentType.TextBlock)
				.map((block) => ({
					id: block.contentLink?.id,
					name: block.contentLink?.expanded?.name,
					sectionTitle: block.contentLink?.expanded?.name,
					sectionTitleHash: formatSectionTitleForHash(block.contentLink?.expanded?.name),
					sectionBlockId: generateBlockId({
						contentType: ContentType.TextBlock.toLowerCase(),
						id: block.contentLink?.id?.toString(),
					}),
				})) || []
		);
	}

	return (
		pageDefinition?.anchorBarElements?.map((item) => ({
			...item,
			sectionTitleHash: formatSectionTitleForHash(item.sectionTitle),
		})) || []
	);
};

export const getTargetUrl = (sectionTitleHash: string) => {
	if (typeof window === 'undefined') {
		return '';
	}
	const url = new URL(window.location.href);

	url.hash = sectionTitleHash;

	return url.toString();
};

export const getAnchorBarCmsTrackingInformation = (sectionTitleHash: string, name: string, conversionId?: string) => {
	return {
		techCategory: 'link',
		category: 'link-anchor',
		action: 'click',
		label: name,
		conversionId: conversionId || '',
		nonInteraction: false,
		targetUrl: getTargetUrl(sectionTitleHash),
	};
};

export const getAnchorBarTrackedBlockInformation = (name: string, id: number) => {
	return {
		blockInstanceId: id?.toString(),
		blockInstanceName: name,
		blockIsRelevant: true,
	} as ITrackedBlockInformation;
};
