import { ILinkBlockComponentProps } from '@/components/block-templates/LinkBlockTemplate/LinkBlockTemplate';
import { IContent, IContentLinkExpanded } from '@/interfaces/coreInformation';
import { Breadcrumb } from '@/types/breadcrumb';
import { PropsWithChildren, ReactNode } from 'react';
import { styled } from 'styled-system/jsx';
import { v4 as uuidv4 } from 'uuid';

export interface IHeroProps extends PropsWithChildren {
	colorScheme: HeroColorScheme;
	heroType?: HeroType;
	children: ReactNode;
}

export enum HeroSize {
	SMALL = 'small',
	LARGE = 'large',
}

export enum HeroColorScheme {
	DARK = 'dark',
	LIGHT = 'light',
}

export enum HeroType {
	IMAGE = 'image',
	VIDEO = 'video',
	TEXT = 'text',
}

export interface IHeroBackground extends IContent {
	alternativeText?: string;
	caption?: string;
	mimeType?: string;
	routeSegment?: string;
	thumbnail?: {
		id?: string;
		url?: string;
	};
	url: string;
}

export interface IHeroBlock {
	caption?: string;
	description?: string;
	heroSectionSize?: 'Large' | 'Small';
	title?: string;
	heroBackground: IContentLinkExpanded<IHeroBackground>;
	useDarkGradient?: boolean;
	ctaButtons?: Array<ILinkBlockComponentProps>;
	showEvolutionSystem?: boolean;
}

export const getHeroBlockType = (heroBlock: IHeroBlock): 'video' | 'image' => {
	return heroBlock.heroBackground?.expanded?.mimeType?.includes('video') ? 'video' : 'image';
};

export const normaliseHeroBlockProperties = (heroBlock: IHeroBlock) => {
	const mimeType = heroBlock?.heroBackground?.expanded?.mimeType;
	let heroType = HeroType.TEXT;

	if (mimeType?.includes('video')) {
		heroType = HeroType.VIDEO;
	} else if (mimeType?.includes('image')) {
		heroType = HeroType.IMAGE;
	}

	return { heroType, ...heroBlock };
};

export type NormalisedHeroBlockProperties = ReturnType<typeof normaliseHeroBlockProperties> & {
	breadcrumbs?: Array<Breadcrumb>;
};

export const HERO_ID = `hero-${uuidv4()}`;

export const Hero = ({ colorScheme, heroType, children }: IHeroProps) => {
	const hasVisual = heroType !== HeroType.TEXT;

	return (
		// This wrapper exists because Safari interprets row height differently than Chrome.
		<styled.div id={HERO_ID}>
			<styled.div
				position="relative"
				display="grid"
				gridTemplateColumns="1fr"
				gridTemplateRows="auto auto auto"
				color={{
					base: hasVisual
						? 'text.regular.default'
						: colorScheme === 'light'
							? 'text.regular.default'
							: 'text.inverted.default',
					lg: colorScheme === 'light' ? 'text.regular.default' : 'text.inverted.default',
				}}
				_print={{ color: colorScheme === 'light' ? 'text.regular.default' : 'text.inverted.default' }}
				bgColor={hasVisual || colorScheme === 'light' ? 'surface.static.primary' : 'surface.static.inverted'}
				py={0}
				pl="env(safe-area-inset-left)"
				pr="env(safe-area-inset-right)"
				zIndex={2}
			>
				{children}
			</styled.div>
		</styled.div>
	);
};
