'use client';

import { contentSearchFieldRecipe } from '@/components/block-templates/ContentSearchBlockTemplate/components/ContentSearchField.recipe';
import { Button } from '@/components/core/Button/Button';
import useQueryParams from '@/hooks/useQueryParams';
import { CloseIcon } from '@/icons/CloseIcon';
import { SearchIcon } from '@/icons/SearchIcon';
import { Box, BoxProps } from '@/styled-system/jsx';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { FC, KeyboardEvent, useRef, useState } from 'react';

interface IContentSearchFieldProps extends BoxProps {
	contentSearchTranslations: TranslationLabelValues;
}

export const ContentSearchField: FC<IContentSearchFieldProps> = ({ contentSearchTranslations, ...rest }) => {
	const classes = contentSearchFieldRecipe();
	const inputRef = useRef<HTMLInputElement>(null);

	const { query, change } = useQueryParams();
	const [value, setValue] = useState(initializeInputValue(query.s));
	const [isClearButtonVisible, setIsClearButtonVisible] = useState(false);
	const buttonLabel = translate(contentSearchTranslations, 'contentSearch.input.buttonLabel');

	const onBlur = () => {
		if (!value) {
			setIsClearButtonVisible(false);
		}
	};

	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			change({ ...query, s: value });
		}
	};

	const clearInput = () => {
		if (value) {
			setValue('');
			change({ ...query, s: '' });

			inputRef.current?.focus();
		}
	};

	return (
		<Box data-part="root" className={classes.root} {...rest}>
			<Box data-part="leftElement" className={classes.leftElement}>
				<SearchIcon />
			</Box>

			<input
				ref={inputRef}
				data-part="input"
				className={classes.input}
				value={value}
				onBlur={onBlur}
				onKeyUp={onKeyUp}
				placeholder={translate(contentSearchTranslations, 'contentSearch.input.placeholder')}
				onChange={(e) => setValue(e.target.value)}
				onFocus={() => setIsClearButtonVisible(true)}
			/>

			<Box data-part="rightElement" className={classes.rightElement}>
				{isClearButtonVisible && (
					<button data-part="clearButton" className={classes.clearButton} onClick={clearInput}>
						<CloseIcon />
					</button>
				)}

				<Button
					size="large"
					className={classes.searchButton}
					title={buttonLabel}
					aria-label={buttonLabel}
					onClick={() => change({ ...query, s: value })}
				>
					<SearchIcon />
					<span>{buttonLabel}</span>
				</Button>
			</Box>
		</Box>
	);
};

const initializeInputValue = (query?: string | string[]): string => {
	if (!query) {
		return '';
	}

	if (Array.isArray(query)) {
		return query.join(',');
	}

	return query;
};
