import { RecipeVariant, sva } from '@/styled-system/css';

export const appBannerRecipe = sva({
	slots: ['container', 'contentContainer', 'closeButton', 'image', 'title', 'description', 'actionLinkWrapper'],
	base: {
		container: {
			display: {
				base: 'flex',
				lg: 'none',
			},
			alignItems: 'center',
			justifyContent: 'space-between',
			height: 'appBannerHeight',
			px: 2,
			transition: 'height 0.4s ease-in-out',
		},
		contentContainer: {
			display: 'flex',
			alignItems: 'center',
			gap: 1,
			width: 'full',
			mr: 1,
		},
		closeButton: {
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '24px',
			height: '24px',

			'& > svg': {
				width: '24px',
				height: '24px',
			},
		},
		image: {
			maxW: '64px',
			maxH: '64px',
			transition: 'transform 0.4s ease-in-out',
			borderRadius: 'xl',
			mr: 1,
		},
		actionLinkWrapper: {
			transition: 'transform 0.4s ease-in-out',
		},
		title: {
			fontSize: '0.938rem',
			fontWeight: 'bold',
			color: 'text.regular.default',
		},
		description: {
			fontSize: '0.75rem',
			fontWeight: 'medium',
			color: 'text.regular.subtitle',
			maxH: '34px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			lineClamp: 2,
		},
	},
	defaultVariants: {
		color: 'Secondary',
	},
	variants: {
		color: {
			PrimaryAccent: {
				container: {
					backgroundColor: 'surface.interactive.card.primaryAccent',
				},
			},
			PrimaryInverted: {
				container: {
					backgroundColor: 'surface.interactive.card.primaryInverted',
				},
				title: {
					color: 'text.inverted.default',
				},
				description: {
					color: 'text.inverted.default',
				},
				closeButton: {
					color: 'text.inverted.default',
				},
			},
			Secondary: {
				container: {
					backgroundColor: 'surface.interactive.card.secondary',
				},
			},
			SecondaryAccent: {
				container: {
					backgroundColor: 'surface.interactive.card.secondaryAccent',
				},
			},
			Inverted: {
				container: {
					backgroundColor: 'surface.interactive.card.inverted',
				},
				title: {
					color: 'text.inverted.default',
				},
				description: {
					color: 'text.inverted.default',
				},
				closeButton: {
					color: 'text.inverted.default',
				},
			},
		},
	},
});

export type AppBannerRecipeVariants = RecipeVariant<typeof appBannerRecipe>;
