import { BreakpointToken, SpacingToken, token } from '@/styled-system/tokens';

type SpacingParameter = Partial<Record<BreakpointToken, SpacingToken>>;

export const displayOptionsCSSVariablesProvider = (spacing?: SpacingParameter, baseSpacing: SpacingToken = '4') => {
	const baseSpacingToken = token.var(`spacing.${baseSpacing}`);

	return {
		'--display-options-spacing-md': spacing?.md ? token.var(`spacing.${spacing?.md}`) : baseSpacingToken,
		'--display-options-spacing-lg': spacing?.lg ? token.var(`spacing.${spacing?.lg}`) : baseSpacingToken,
	};
};
