import { ILinkBlockTemplateProps } from '@/components/block-templates/LinkBlockTemplate/LinkBlockTemplate';
import { ButtonLink } from '@/components/core/ButtonLink/ButtonLink';
import { mapBlockPropsToButtonLinkProps } from '@/components/shared/Header/components/HeaderButtons/HeaderButtons.utils';
import { IBlockResponse } from '@/interfaces/coreInformation';
import { HStack } from '@/styled-system/jsx';
import { FC } from 'react';

interface IHeaderButtonsProps {
	buttons?: Array<IBlockResponse<ILinkBlockTemplateProps>>;
}

export const HeaderButtons: FC<IHeaderButtonsProps> = ({ buttons = [] }) => {
	return (
		<HStack gap="2" hideBelow="md" _print={{ display: 'none' }}>
			{buttons.map((button) => {
				const { id } = button.contentLink;

				return <ButtonLink key={id} {...mapBlockPropsToButtonLinkProps(button.contentLink.expanded)} />;
			})}
		</HStack>
	);
};
