'use client';

import { IPageContentLink } from '@/interfaces/coreInformation';
import { SiteName } from '@/types/feature-flags';
import { ReactNode, createContext, useMemo } from 'react';

export enum UserDeviceType {
	Console = 'console',
	Mobile = 'mobile',
	Tablet = 'tablet',
	SmartTv = 'smarttv',
	Wearable = 'wearable',
	Embedded = 'embedded',
}

interface IPageProviderClientProps {
	contentLink: IPageContentLink;
	siteName: SiteName;
	themeName: string;
	userDeviceType?: UserDeviceType;
	children: ReactNode;
}

export const PageContext = createContext({
	pageLink: undefined as IPageContentLink | undefined,
	siteName: 'corpweb' as SiteName,
	themeName: 'default' as string,
	userDeviceType: undefined as UserDeviceType | undefined,
});

export const PageContextProvider: React.FC<IPageProviderClientProps> = ({
	contentLink,
	siteName,
	themeName,
	userDeviceType,
	children,
}) => {
	const contextValue = useMemo(
		() => ({
			pageLink: contentLink,
			siteName,
			themeName,
			userDeviceType,
		}),
		[contentLink]
	);

	return <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>;
};
