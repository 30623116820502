import { ILinkBlockTemplateProps } from '@/components/block-templates/LinkBlockTemplate/LinkBlockTemplate';
import { IButtonLinkProps } from '@/components/core/ButtonLink/ButtonLink';
import { getIcon } from '@/const/icon';
import { IconPosition } from '@/enums/Icon';
import { LinkClickBehaviour } from '@/enums/Link';
import { getButtonVariant } from '@/utils/button/button';

export const mapBlockPropsToButtonLinkProps = (props: ILinkBlockTemplateProps): IButtonLinkProps => {
	const { displayText, link, buttonType, name, title, iconPosition, icon, linkClickBehaviour } = props;

	const buttonLinkProps: IButtonLinkProps = {
		title,
		size: 'small',
		variant: getButtonVariant(buttonType!),
		fontWeight: 'semibold',
		children: displayText || name,
		href: link,
	};

	if (linkClickBehaviour) {
		switch (linkClickBehaviour) {
			case LinkClickBehaviour.NEW_WINDOW:
				buttonLinkProps.target = '_blank';
				buttonLinkProps.rel = 'noopener noreferrer';
				break;
			case LinkClickBehaviour.SAME_WINDOW:
				buttonLinkProps.target = '_self';
				break;
		}
	}

	const leftIcon = iconPosition?.includes(IconPosition.LEFT) && getIcon(icon!);
	const rightIcon = iconPosition?.includes(IconPosition.RIGHT) && getIcon(icon!);

	if (leftIcon) {
		Object.assign(buttonLinkProps, { leftIcon });
	}

	if (rightIcon) {
		Object.assign(buttonLinkProps, { rightIcon });
	}

	return buttonLinkProps;
};
