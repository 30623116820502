import { css, cx } from '@/styled-system/css';
import { Box, BoxProps, Flex, VStack } from '@/styled-system/jsx';
import { scrollbar } from '@/styled-system/recipes';
import { forwardRef, ReactNode } from 'react';

interface INavigationColumnContainerProps extends BoxProps {
	children: ReactNode;
	flex?: number;
	showDelimiter?: boolean;
	ignoreDelimiterSpace?: boolean;
}

export const NavigationColumnContainer = forwardRef<HTMLDivElement, INavigationColumnContainerProps>(
	({ children, flex = 1, showDelimiter = false, ignoreDelimiterSpace = false, ...rest }, ref) => {
		return (
			<Flex flex={flex}>
				<VStack
					width="100%"
					maxH={{ base: 'calc(100dvh - 310px)', md: '50vh', lg: '530px', xl: '55vh' }}
					minH="200px"
					className={cx(scrollbar())}
					pr={{ base: '12px', lg: '24px', xl: '40px' }}
					alignItems="flex-start"
					gap={{ base: 3, md: 4, xl: 6 }}
					overflowY="auto"
					ref={ref}
					{...rest}
				>
					{children}
				</VStack>
				<Box
					pr={ignoreDelimiterSpace ? {} : { base: '12px', lg: '24px', xl: '40px' }}
					alignSelf="stretch"
					visibility={showDelimiter ? 'visible' : 'hidden'}
					ml={showDelimiter ? '-4px' : '0px'}
					zIndex={-1}
				>
					<Box className={css({ height: '100%', borderLeft: '1px solid' })} />
				</Box>
			</Flex>
		);
	}
);

NavigationColumnContainer.displayName = 'NavigationColumnContainer';
