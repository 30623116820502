'use client';

import { IArticleTeaserProps } from '@/components/block-templates/ArticlePageTeaserBlockTemplate/ArticlePageTeaserBlockTemplate.utils';
import { TeaserImage } from '@/components/core/Image/TeaserImage';
import { Text } from '@/components/core/Text/Text';
import {
	ArticleTeaserBackgroundColor,
	getArticleTeaserLinkModeVariant,
} from '@/components/shared/ArticleTeaser/ArticleTeaser.utils';
import { articleTeaserRecipe } from '@/components/shared/ArticleTeaser/ArticleTeaser.recipe';
import { decodeWhitelistedHTMLEntities } from '@/components/shared/Hero/components/Hero.utils';
import { PersonThumbnailImage } from '@/components/shared/PersonTeaser/components/PersonThumbnail';
import { ArrowRightIcon } from '@/icons/ArrowRightIcon';
import { translate } from '@/utils/i18n/translation-labels/translationLabels';
import { formatPublicationDateToEuZurich } from '@/utils/i18n/locale-formatting/localeFormatting';
import { Fragment, useContext } from 'react';
import { LinkTracked } from '@/components/core/Link/LinkTracked';
import { PageContext } from '@/utils/pageContextProvider';

export const ArticleTeaser = ({
	id,
	author,
	publishedDate,
	categories,
	title,
	description,
	articleImage,
	articleUrl,
	positionOfTheImage,
	variant,
	displayOption,
	cloudinaryOverrides,
	articleTranslations,
	timezoneTranslations,
}: IArticleTeaserProps) => {
	const { siteName } = useContext(PageContext);

	const resolvedVariant = siteName === 'deritrade' ? 'Inverted' : variant;
	const classes = articleTeaserRecipe({
		variant: resolvedVariant,
		imagePosition: positionOfTheImage,
	});

	const linkLabel = translate(articleTranslations, 'article.teaser.readMore');
	const publicationDateObj = publishedDate ? new Date(publishedDate) : undefined;
	const trackingBlockId = id?.startsWith('articlepage-') ? id.slice(12) : id;

	return (
		<div className={classes.container} id={id}>
			<div className={classes.contentWrapper}>
				<div className={classes.imageContainer}>
					{articleImage && articleImage.url && (
						<TeaserImage
							src={articleImage.url}
							alt={`${articleImage.alternativeText}`}
							cloudinaryOverrides={cloudinaryOverrides}
							displayOption={displayOption}
						/>
					)}
				</div>
				<div className={classes.contentContainer}>
					<div className={classes.textContainer}>
						<div className={classes.dateAndCategoryContainer}>
							<Text size="xs">
								{formatPublicationDateToEuZurich(publicationDateObj, '', true, timezoneTranslations)}

								{categories && categories.length > 0 && (
									<>
										&ensp;&#124;&ensp;
										{categories.map((category, i) => (
											<Fragment key={category}>
												{category}
												{i !== categories.length - 1 && <>&ensp;&#124;&ensp;</>}
											</Fragment>
										))}
									</>
								)}
							</Text>
						</div>
						{title && (
							<p className={classes.titleContainer} data-part="article-teaser-title">
								{decodeWhitelistedHTMLEntities(title)}
							</p>
						)}
						{author && (
							<div className={classes.authorContainer}>
								<PersonThumbnailImage
									src={author.photo.url}
									alt={author.fullName}
									cloudinaryOverrides={cloudinaryOverrides}
								/>
								<Text size="sm">{author.fullName}</Text>
							</div>
						)}
						<Text size="sm">{description}</Text>
					</div>
					<LinkTracked
						mode={getArticleTeaserLinkModeVariant(resolvedVariant as ArticleTeaserBackgroundColor)}
						size="sm"
						href={articleUrl}
						rightIcon={<ArrowRightIcon />}
						className={classes.link}
						trackingData={{
							cmsTrackingInformation: {
								label: `${trackingBlockId}|${title?.replace(/&shy;/g, '')}`,
								mediaSource: 'corpweb-cms',
								mediaType: 'page',
								mediaValue: trackingBlockId,
							},
						}}
					>
						{linkLabel}
					</LinkTracked>
				</div>
			</div>
		</div>
	);
};
