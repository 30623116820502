import { AnswerVoteCount, IPoll } from '@/components/shared/Poll/Poll';
import { PollType } from '@/components/shared/Poll/PollType.enum';
import { ITrackingInformation } from '@/types/tracking';
import { CustomTrackingEvent, doTrack } from '@/utils/tracking/tracking';
import { RefObject } from 'react';

type AnswerPercentage = {
	answerCmsId: number;
	percentage: number;
};

export function calculateVotePercentages(
	totalVotes: number,
	answerVoteCounts: Array<AnswerVoteCount>
): Array<AnswerPercentage> {
	const percentages: Array<AnswerPercentage> = [];

	answerVoteCounts.forEach((answer) => {
		const answerVotes = answer.voteCount;
		const votePercentage = totalVotes && (answerVotes * 100) / totalVotes;

		percentages.push({ answerCmsId: answer.answerCmsId, percentage: votePercentage });
	});

	percentages.sort((a, b) => b.percentage - Math.floor(b.percentage) - (a.percentage - Math.floor(a.percentage)));
	const roundedPercentages = percentages.map((answer) => ({
		answerCmsId: answer.answerCmsId,
		percentage: Math.floor(answer.percentage),
	}));

	let percentageDiff = 100 - roundedPercentages.reduce((sum, value) => sum + value.percentage, 0);

	if (percentageDiff > 0) {
		for (let i = 0; i < roundedPercentages.length && percentageDiff > 0; i++) {
			roundedPercentages[i].percentage += 1;
			percentageDiff -= 1;
		}
	}

	return roundedPercentages;
}

export const isPollAnsweredCorrectly = (poll: IPoll, selectedAnswerIds: Array<number>) => {
	const selectedAllCorrectAnswers = poll.answers.every(
		(answer) => !answer.isCorrect || selectedAnswerIds.includes(answer.id)
	);

	const selectedNoIncorrectAnswers = poll.answers.every(
		(answer) => answer.isCorrect || !selectedAnswerIds.includes(answer.id)
	);

	return selectedAllCorrectAnswers && selectedNoIncorrectAnswers;
};

export const getPollResults = async (pollId: number) => {
	const answersResponse = await fetch(`/api/polls/GetPollResults/${pollId}/`);

	if (answersResponse.ok) {
		return await answersResponse.json();
	}
};

export const trackVoteEvent = (
	selectedAnswers: Array<number>,
	isShowResults = false,
	trackingRef: RefObject<HTMLDivElement>,
	poll: IPoll
) => {
	if (trackingRef?.current) {
		const event = CustomTrackingEvent.FromTarget(trackingRef.current, 'vote');
		const answerData: Array<{
			id: number | string;
			name: string;
			status: {
				selected: boolean;
				correct?: boolean;
			};
		}> = poll.answers.map((answer) => {
			return {
				id: answer.id,
				name: answer.blockName || '',

				status: {
					selected: selectedAnswers.includes(answer.id),
					...(poll.type === PollType.QUIZ ? { correct: !!answer.isCorrect } : {}),
				},
			};
		});

		if (poll.type !== PollType.QUIZ) {
			answerData.push({
				id: 'results only',
				name: 'results only',
				status: {
					selected: isShowResults,
				},
			});
		}

		const eventLabel = {
			status: {
				type: poll.type.toLowerCase(),
				question: poll.trackedBlockInformation?.blockInstanceName,
				...(poll.type === PollType.QUIZ ? { correct: !!isPollAnsweredCorrectly(poll, selectedAnswers) } : {}),
			},
			items: answerData,
		};

		const trackData: ITrackingInformation = {
			label: JSON.stringify(eventLabel),
		};

		doTrack(event, trackData);
	}
};

export const hasUserAlreadyVoted = (pollId: number) => {
	const currentPolls = localStorage.getItem('vt-polls');

	const currentPollsArray = currentPolls?.split(',') || [];

	return currentPollsArray.includes(pollId.toString());
};

export const setUserVoted = (pollId: number) => {
	const currentPolls = localStorage.getItem('vt-polls');

	const currentPollsArray = currentPolls?.split(',') || [];

	if (!currentPollsArray.includes(pollId.toString())) {
		currentPollsArray.push(pollId.toString());
	}

	const newPollsString = currentPollsArray.join(',');

	localStorage.setItem('vt-polls', newPollsString);
};
