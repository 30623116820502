import { sva } from '@/styled-system/css';

export const hamburgerButtonRecipe = sva({
	slots: ['root', 'bar'],
	base: {
		root: {
			position: 'relative',
			cursor: 'pointer',
			p: '0.5rem 0.25rem',
			h: 8,
			w: 8,
			_print: { display: 'none' },
		},
		bar: {
			h: '2px',
			w: 6,
			bg: 'icons.regular.default',
			display: 'block',
			m: '4px auto',
			position: 'relative',
			borderRadius: 2,
			transitionDuration: '300ms',

			'&:first-of-type': {
				mt: 0,
			},
			'&:nth-of-type(2)': {
				opacity: 1,
			},
			'&:last-of-type': {
				mb: 0,
			},
		},
	},
	variants: {
		open: {
			true: {
				bar: {
					'&:nth-of-type(1)': {
						transform: 'translateY(6px) rotate(45deg)',
					},
					'&:nth-of-type(2)': {
						opacity: 0,
					},
					'&:nth-of-type(3)': {
						transform: 'translateY(-6px) rotate(-45deg)',
					},
				},
			},
		},
	},
});
