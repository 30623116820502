'use client';

import {
	FormMessageVariant,
	FormMessageVariants,
	formMessageRecipe,
} from '@/components/block-templates/Forms/FormContainerBlockTemplate/components/FormMessage.recipe';
import { TextSnippet } from '@/components/shared/TextSnippet/TextSnippet';
import { BackgroundColorVariant } from '@/enums/ColorMode';
import { SendIconWithBackground } from '@/icons/SendIconWithBackground';
import { WarningIcon } from '@/icons/WarningIcon';
import { Box, BoxProps } from '@/styled-system/jsx';
import { PageContext } from '@/utils/pageContextProvider';
import { FC, forwardRef, useContext } from 'react';

export type IFormMessageProps = BoxProps &
	FormMessageVariants & {
		content?: string;
	};

export const FormMessage = forwardRef<HTMLDivElement, IFormMessageProps>(({ variant, content, ...rest }, ref) => {
	if (!content) {
		return null;
	}

	const { siteName } = useContext(PageContext);
	const classes = formMessageRecipe({ variant, site: siteName as FormMessageVariant['site'] });

	return (
		<Box ref={ref} data-part="root" className={classes.root} {...rest}>
			<TextSnippet
				id={`salesforceFormMessageText${variant}`}
				content={content}
				dataId="form-message-text-snippet"
				preventAutomatedTracking
				parentBackgroundColor={BackgroundColorVariant.PRIMARY}
			/>
			<FormMessageIcon success={variant === 'success'} className={classes.icon} />
		</Box>
	);
});

const FormMessageIcon: FC<BoxProps & { success?: boolean }> = ({ success = false, ...rest }) => {
	return (
		<Box data-part="icon" {...rest}>
			{success ? <SendIconWithBackground /> : <WarningIcon />}
		</Box>
	);
};
