'use client';

import { useGlobalTracking } from '@/components/shared/GlobalTrackingContainer/useGlobalTracking';
import { IFormFieldResponse } from '@/interfaces/blocks/forms';
import { splitLocale } from '@/utils/language';
import { useParams } from 'next/navigation';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { UtagType } from 'typings/common';

export interface IHiddenFormFieldProps {
	field: IFormFieldResponse;
}

export const HiddenFormField: FC<IHiddenFormFieldProps> = ({ field }) => {
	const { utag } = useGlobalTracking();
	const { setValue } = useFormContext();

	const fieldName = field?.contentLink?.expanded?.fieldName;
	const autoCollectData = field?.contentLink?.expanded?.autoCollectData;

	const params = useParams<{ lang: string }>();
	const { language, country } = splitLocale(params.lang);

	if (!fieldName || !autoCollectData) {
		return null;
	}

	const { pageUrl, pageUrlWithoutUrlParameters } = getPageUrls();

	const formFieldAutoCollectData = {
		PageUrl: pageUrl,
		PageUrlWithoutUrlParameters: pageUrlWithoutUrlParameters,
		PageCulture: params.lang,
		PageCountry: country,
		PageLanguage: language,
		ClientData: JSON.stringify(getClientData(utag)),
		GoogleClickID: utag?.data?.gclid,
	} as const;

	if (formFieldAutoCollectData[autoCollectData]) {
		setValue(fieldName, formFieldAutoCollectData[autoCollectData]);
	}

	return null;
};

enum ClientData {
	t_vid = 'tealium_visitor_id',
	t_sid = 'tealium_session_id',
	t_env = 'tealium_environment',
	td = 'touchpoint_id',
	tn = 'touchpoint_name',
	cn = 'utm_campaign',
	cc = 'utm_content',
	cm = 'utm_medium',
	cs = 'utm_source',
	ct = 'utm_term',
	cu = 'utm_cu',
	gclid = 'gclid',
	fc = 'c9',
	ac = 'c1',
	tc = 'c3',
	pc = 'c6',
}

export const getClientData = (utag?: UtagType) => {
	const utagData = utag?.data;

	if (!utagData) {
		return undefined;
	}

	return Object.entries(ClientData).reduce((acc, [key, value]) => {
		let itemValue;

		// Cookie values needs to be handled differently
		if (['c9', 'c1', 'c3', 'c6'].includes(value)) {
			itemValue = utag?.gdpr?.getCookieValues?.()[value];
		} else {
			itemValue = utagData?.[value];
		}

		if (itemValue !== null && itemValue !== undefined) {
			return {
				...acc,
				[key]: itemValue,
			};
		}

		return acc;
	}, {});
};

export function getPageUrls() {
	if (typeof window === 'undefined') {
		return { withSearchParams: '', withoutSearchParams: '' };
	}

	const url = new URL(window.location.href);

	return {
		pageUrl: url.href,
		pageUrlWithoutUrlParameters: `${url.origin}${url.pathname}${url.hash}`,
	};
}
