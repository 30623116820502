'use client';

import { SpinnerIcon } from '@/icons/SpinnerIcon';
import { poly } from '@/libs/poly';
import { css, cx } from '@/styled-system/css';
import { HTMLStyledProps, styled } from '@/styled-system/jsx';
import { SystemStyleObject } from '@/styled-system/types';
import { ButtonVariants, button } from '@/styles/theme/button';

import { ComponentProps, ReactElement, ReactNode, SVGAttributes, forwardRef } from 'react';

const ButtonContainer = styled(poly('button'));

export type ButtonProps = Omit<ComponentProps<typeof ButtonContainer>, 'color' | 'css'> &
	ButtonVariants & {
		children?: ReactNode | undefined;
		leftIcon?: ReactElement;
		rightIcon?: ReactElement;
		isLoading?: boolean;
		href?: string;
		target?: string;
		rootProps?: SystemStyleObject;
	};

export const ButtonIcon = (props: HTMLStyledProps<'span'>) => {
	const { children, css, className, ...rest } = props;

	return (
		<styled.span className={className} aria-hidden {...rest}>
			{children}
		</styled.span>
	);
};

const ButtonLoading = (props: SVGAttributes<SVGElement>) => {
	return (
		<span>
			<SpinnerIcon
				className={css({
					animation: 'rotating 1s linear infinite',
				})}
				width="24px"
				height="24px"
				{...props}
			/>
		</span>
	);
};

// The TypeScript typings for this component are not correct. TODO: Review and make necessary corrections.
export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
	const {
		children,
		leftIcon,
		rightIcon,
		onClick,
		variant,
		size,
		className,
		isLoading,
		mode,
		widthVariant = 'unsized',
		rootProps = {},
		...rest
	} = props;

	const onButtonClick = (e: any) => {
		if (isLoading) {
			return;
		}

		onClick?.(e);
	};

	const isIconButton = !children;
	const displayType = isIconButton ? 'iconButton' : 'standard';

	const classes = button.raw({ size, variant, displayType, mode, isLoading, widthVariant });

	return (
		<ButtonContainer
			className={cx(css(classes.root, rootProps), className)}
			onClick={onButtonClick}
			ref={ref}
			{...rest}
		>
			{leftIcon && (
				<ButtonIcon className={css(classes.icon)} color={leftIcon.props.color}>
					{leftIcon}
				</ButtonIcon>
			)}
			{isLoading ? <ButtonLoading data-scope="button" data-part="spinner" /> : children}
			{rightIcon && (
				<ButtonIcon className={css(classes.icon)} color={rightIcon.props.color}>
					{rightIcon}
				</ButtonIcon>
			)}
		</ButtonContainer>
	);
});
