import { NextLink } from '@/components/core/NextLink/NextLink';
import { logoComponents } from '@/components/shared/Logo/logoComponents';
import { siteName } from '@/constants/global';
import { Flex } from '@/styled-system/jsx';
import { getTranslationInstance, translate } from '@/utils/i18n/translation-labels/translationLabels';

interface ILogoContainerProps {
	locale: string;
}

export const Logo = async ({ locale }: ILogoContainerProps) => {
	const LogoComponent = logoComponents[siteName];

	if (!LogoComponent) {
		return null;
	}

	const translations = (await getTranslationInstance(locale, ['breadcrumbs'])).namespaceTranslations;

	return (
		<NextLink
			href={`/${locale.toLowerCase()}`}
			title={translate(translations, 'breadcrumbs.root')}
			aria-label={translate(translations, 'breadcrumbs.root')}
		>
			<Flex
				height="100%"
				direction="row"
				gap={{ base: '12px', sm: '21px', md: '22px', lg: '20px', xl: '24px' }}
				alignItems="center"
			>
				<LogoComponent />
			</Flex>
		</NextLink>
	);
};
