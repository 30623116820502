import { NextLink } from '@/components/core/NextLink/NextLink';
import { isExternalLink } from '@/components/shared/Navigation/Navigation.utils';
import { poly } from '@/libs/poly';
import { cx } from '@/styled-system/css';
import { HTMLStyledProps, styled } from '@/styled-system/jsx';
import { link, LinkVariantProps } from '@/styled-system/recipes';
import { logger } from '@/utils/logger';

import { ComponentProps, ReactElement, ReactNode, cloneElement, forwardRef, isValidElement } from 'react';

const LinkContainer = styled(poly(NextLink));

export interface ILinkProps extends Omit<ComponentProps<typeof LinkContainer>, 'css'>, LinkVariantProps {
	children?: ReactNode | undefined;
	leftIcon?: ReactElement;
	rightIcon?: ReactElement;
}

export const Link = forwardRef<HTMLLinkElement, ILinkProps>((props: ILinkProps, ref) => {
	const { children, leftIcon, rightIcon, onClick, className, size, target, mode, ...rest } = props;

	if (!rest.href) {
		logger.warn('Link component is missing href prop');

		return null;
	}

	if (!children) {
		logger.warn('Link component is missing children prop');
	}

	return (
		<LinkContainer
			className={cx(link({ size, mode }), className)}
			target={target || isExternalLink(rest.href) ? '_blank' : ''}
			onClick={onClick}
			_focus={{
				boxShadow: 'shadow.outline',
			}}
			ref={ref}
			{...rest}
		>
			{leftIcon && <LinkIcon data-part="left-icon">{leftIcon}</LinkIcon>}
			{children}
			{rightIcon && <LinkIcon data-part="right-icon">{rightIcon}</LinkIcon>}
		</LinkContainer>
	);
});

const LinkIcon = (props: HTMLStyledProps<'span'>) => {
	const { children, css, ...rest } = props;

	const _children = isValidElement(children)
		? cloneElement(children, {
				// @ts-expect-error typings are wrong
				'aria-hidden': true,
				focusable: true,
			})
		: children;

	return <styled.span {...rest}>{_children}</styled.span>;
};
