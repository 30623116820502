import { elementWithDisplayOptionsRecipe } from '@/components/shared/ElementWithDisplayOptions/ElementWithDisplayOptions.recipe';
import { Box, BoxProps } from '@/styled-system/jsx';
import { DisplayOption } from '@/types/display-options';
import { ReactNode } from 'react';

interface IElementWithDisplayOptionsWrapperProps extends BoxProps {
	displayOption: DisplayOption;
	children: ReactNode;
}

export const ElementWithDisplayOptionsWrapper = ({
	displayOption,
	children,
	...rest
}: IElementWithDisplayOptionsWrapperProps) => {
	return (
		<Box
			className={elementWithDisplayOptionsRecipe({
				displayOption: displayOption,
			})}
			{...rest}
		>
			{children}
		</Box>
	);
};
