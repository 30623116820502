'use client';

import { useEffect, useRef, useState } from 'react';
import { Dialog, Portal } from '@ark-ui/react';
import { LegalDisclaimer } from '@/components/shared/Disclaimer/components/LegalDisclaimer';
import { CookieDisclaimer } from '@/components/shared/Disclaimer/components/CookieDisclaimer';
import { disclaimer } from '@/styles/theme/disclaimer';
import { css, cx } from '@/styled-system/css';
import { Box, Flex } from '@/styled-system/jsx';

import './disclaimer.css';
import { CustomTrackingEvent, doTrack } from '@/utils/tracking/tracking';
import {
	fetchAndUpdateTrackingData,
	getDisclaimerTrackingInformation,
	setTermsCookie,
	shouldDisclaimerOpen,
} from '@/components/shared/Disclaimer/Disclaimer.utils';
import { Tracked } from '@/components/shared/tracking/Tracked';
import { EventAttachMode } from '@/types/tracking';
import { useGlobalTracking } from '@/components/shared/GlobalTrackingContainer/useGlobalTracking';
import { SettingsDisclaimer } from '@/components/shared/Disclaimer/components/SettingsDisclaimer';
import { IDisclaimerSubmitBody } from '@/types/disclaimer-submission/IDisclaimerSubmitBody';
import { CountryCode } from '@/enums/Language';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import { ILanguageSettings, splitLocale } from '@/utils/language';
import { IPageLanguage } from '@/interfaces/coreInformation';
import { translate } from '@/utils/i18n/translation-labels/translationLabels';
import { useDisclaimerCache } from '@/components/shared/Disclaimer/components/useDisclaimerCache';

export enum DisclaimerSections {
	TOGETHER = 'Together',
	COOKIE = 'Cookie',
	LEGAL = 'Legal',
}

export enum ButtonsToHide {
	ACCEPT = 'ButtonAccept',
	FUNCTIONAL_ONLY = 'ButtonFunctionalOnly',
	DECLINE = 'ButtonDecline',
}

export interface IDisplaySettings {
	disclaimerType?: 'Footer' | 'Overlay';
	disclaimerSections?: DisclaimerSections;
	hideVisitorDomicileAndRole?: boolean;
	disclaimerTitleText?: string;
	disclaimerConsentSectionText?: string;
	disclaimerCookieSectionText?: string;
	linkTermsAndConditions?: string;
	countriesListForVisitorDomicile?: Array<{ country: string; mapToGlobalCountry: boolean }>;
	buttonsToHide?: string;
}

export interface IDisclaimerFunctionality {
	termsAndConditionsCookieKey?: string;
	disclaimerOptions?: string;
	usaSelectionRedirectLink?: string;
	globalCountryRedirectLink?: string;
	declineButtonRedirectLink?: string;
}

export interface IDisclaimerProps {
	displaySettings: IDisplaySettings;
	disclaimerFunctionality: IDisclaimerFunctionality;
	selectedCountry?: string;
	translations: Record<string, string>;
	language: string;
	customTermsCookieKey: string | undefined;
	languageSettings: ILanguageSettings;
	existingLanguages: Array<IPageLanguage>;
}

export const Disclaimer = ({
	displaySettings,
	disclaimerFunctionality,
	translations,
	language,
	customTermsCookieKey,
	selectedCountry,
	languageSettings,
	existingLanguages,
}: IDisclaimerProps) => {
	const router = useRouter();
	const query = useSearchParams().toString();
	const params = useParams<{ lang: string }>();
	const { language: urlLanguage } = splitLocale(params.lang);
	const [isOpen, setIsOpen] = useState(false);
	const [settingsOpen, setSettingsOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { trackingSettings } = useGlobalTracking();
	const disclaimerCache = useDisclaimerCache();

	const displayedSection = displaySettings.disclaimerSections;

	const [selectionLegal, setSelectionLegal] = useState<IDisclaimerSubmitBody>({});

	const displayLegal = displayedSection === DisclaimerSections.LEGAL;
	const displayTogether = displayedSection === DisclaimerSections.TOGETHER;

	const classes = disclaimer({ blur: displayedSection !== DisclaimerSections.COOKIE });

	const trackingRef = useRef<HTMLDivElement>(null);

	const { isTealiumReady } = useGlobalTracking();

	useEffect(() => {
		if (disclaimerCache.cachedValues?.selectedDomicile) {
			window.utag_data.domicile = disclaimerCache.cachedValues.selectedDomicile.value.toLowerCase();
		}
	}, [disclaimerCache.cachedValues?.selectedDomicile]);

	useEffect(() => {
		setIsOpen(shouldDisclaimerOpen(displaySettings.disclaimerSections, customTermsCookieKey));

		if (!trackingRef.current || !isTealiumReady) {
			return;
		}

		const trackingEvent = CustomTrackingEvent.FromTarget(trackingRef.current, 'show');

		doTrack(trackingEvent);
	}, [isTealiumReady]);

	const handleSettingsClose = () => {
		setSettingsOpen(false);
		setIsOpen(true);
	};

	const handleLegalSubmit = async () => {
		disclaimerCache.clearCache();

		const isDomicileGlobal =
			selectionLegal.Domicile?.toLowerCase() === CountryCode.GLOBAL.toLowerCase() ||
			selectionLegal.Domicile?.toLowerCase() === CountryCode.INTERNATIONAL.toLowerCase();
		const isUsaSelected = selectionLegal.Domicile?.toLowerCase() === CountryCode.US.toLowerCase();

		if (isDomicileGlobal && disclaimerFunctionality?.globalCountryRedirectLink) {
			router.push(disclaimerFunctionality?.globalCountryRedirectLink + (query?.length > 0 ? `?${query}` : ''));

			return;
		}

		if ((isUsaSelected || selectionLegal?.IsUSCitizen) && disclaimerFunctionality?.usaSelectionRedirectLink) {
			router.push(disclaimerFunctionality?.usaSelectionRedirectLink + (query?.length > 0 ? `?${query}` : ''));

			return;
		}

		setTermsCookie(customTermsCookieKey, true);
		setIsSubmitting(true);
		const response = await fetch('/api/Disclaimer/Submit/', {
			method: 'POST',
			body: JSON.stringify({ ...selectionLegal, PreferredLanguage: urlLanguage }),
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (response.ok) {
			await fetchAndUpdateTrackingData();
		}

		setIsSubmitting(false);
	};

	const isVisitorRoleRelevant =
		!displaySettings?.hideVisitorDomicileAndRole &&
		trackingSettings?.tealiumSettings?.keyValueCollection &&
		trackingSettings?.tealiumSettings?.keyValueCollection?.length > 0;

	const isSelectionLegalValid = selectionLegal.VisitorRole && selectionLegal.Domicile;
	const isCookieDisclaimerDisabled = (isVisitorRoleRelevant && !isSelectionLegalValid) || isSubmitting;

	return (
		<Dialog.Root
			id="disclaimer"
			open={isOpen}
			modal={true}
			initialFocusEl={() => {
				const focusedElement =
					document?.getElementById('visitor-role-dropdown') ||
					document?.getElementById('accept-terms-button') ||
					document?.getElementById('accept-cookies-button');

				return focusedElement;
			}}
		>
			<SettingsDisclaimer open={settingsOpen} onClose={handleSettingsClose} language={language} />
			{isOpen && (
				<Portal>
					<Tracked
						trackingElementRef={trackingRef}
						attachTrackingEvent={EventAttachMode.Custom}
						trackingInformation={getDisclaimerTrackingInformation()}
					>
						<Dialog.Backdrop className={classes.backdrop} />
						<Dialog.Backdrop className={classes.overlay} />
						<Dialog.Content
							// data-id is used to fix the body scroll issue on IOS devices - check layout.tsx
							data-id="disclaimer-content"
							className={classes.content}
							ref={trackingRef}
							title={translate(translations, 'disclaimer.link.terms.text')}
						>
							{displayTogether || displayLegal ? (
								<Flex className={classes.container}>
									<Box flexGrow="1" overflow="auto">
										<LegalDisclaimer
											trackingRef={trackingRef}
											className={cx(classes.legalDisclaimer, displayTogether ? '-together' : '')}
											displaySettings={displaySettings}
											disclaimerFunctionality={disclaimerFunctionality}
											displayedTogether={displayTogether}
											setIsOpen={setIsOpen}
											translations={translations}
											customTermsCookieKey={customTermsCookieKey}
											onSubmit={handleLegalSubmit}
											onChange={(value) => setSelectionLegal(value)}
											disabled={isSubmitting}
											selectedCountry={selectedCountry}
											languageSettings={languageSettings}
											existingLanguages={existingLanguages}
											disclaimerCache={disclaimerCache}
										/>
										{displayTogether && (
											<>
												<div className={classes.separator} />
												<CookieDisclaimer
													className={cx(classes.cookieDisclaimer, '-together')}
													displaySettings={displaySettings}
													disclaimerFunctionality={disclaimerFunctionality}
													setIsOpen={setIsOpen}
													setSettingsOpen={setSettingsOpen}
													displayTogether
													translations={translations}
													language={language}
													trackingRef={trackingRef}
													customTermsCookieKey={customTermsCookieKey}
													onSubmit={handleLegalSubmit}
													disabled={isCookieDisclaimerDisabled}
												/>
											</>
										)}
									</Box>
								</Flex>
							) : (
								<CookieDisclaimer
									className={classes.cookieDisclaimer}
									containerClassName={css({ pr: 'env(safe-area-inset-right)', pl: 'env(safe-area-inset-left)' })}
									displaySettings={displaySettings}
									disclaimerFunctionality={disclaimerFunctionality}
									setIsOpen={setIsOpen}
									setSettingsOpen={setSettingsOpen}
									translations={translations}
									language={language}
									trackingRef={trackingRef}
								/>
							)}
						</Dialog.Content>
					</Tracked>
				</Portal>
			)}
		</Dialog.Root>
	);
};
