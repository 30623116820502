import { sva } from '@/styled-system/css';

export const pollRecipe = sva({
	slots: ['root', 'answerContainer', 'actionBar', 'question', 'legalNoticeContainer', 'legalNoticeTitle'],
	base: {
		root: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
			color: 'text.regular.default',
			gap: 4,
		},
		question: {
			marginBottom: 8,
			'& > a': {
				color: 'text.regular.default',
				display: 'inline',
				borderBottom: '0px',
				fontSize: 'inherit',
				lineHeight: 'inherit',
			},
		},
		answerContainer: {
			flexDirection: 'column',
			gap: 6,
		},
		actionBar: {
			display: 'flex',
			alignItems: 'center',
			gap: '6',
			flexDir: 'row',
			justifyContent: 'space-between',
			width: '100%',
		},
		legalNoticeContainer: {
			color: 'base.neutrals.70',
		},
		legalNoticeTitle: {
			marginBottom: 2,
			scrollMarginTop: 'var(--header-height)',
		},
	},
	variants: {
		displayOption: {
			halfWidth: {},
			fullWidth: {
				answerContainer: {
					lg: {
						maxWidth: '66%',
					},
				},
				actionBar: {
					lg: {
						maxWidth: '66%',
					},
				},
			},
		},
	},
});
