import { sva } from '@/styled-system/css';

const generateIndexVariants = () => {
	const variants: {
		[key: number]: {
			root?: { borderColor: string };
		};
	} = {};

	for (let i = 0; i < 10; i++) {
		variants[i] = {};
	}

	return variants;
};

export const pollAnswerRecipe = sva({
	slots: ['root', 'iconContainer', 'answerLabel', 'answerValue', 'progressRoot', 'progressRange', 'icon'],
	base: {
		root: {
			display: 'flex',
			gap: 4,
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
			borderWidth: '2px',
			borderRadius: 'sm',
			borderColor: 'other.border.regular.default',
			_enabled: {
				_hover: {
					color: 'actions.filterTags.regular.strokeHover',
					cursor: 'pointer',
					borderColor: 'actions.filterTags.regular.strokeHover',
					borderWidth: '4px',
					paddingX: '12px',
					paddingY: '9px',
					fontWeight: 'bold',
				},
				_focusVisible: {
					color: 'actions.filterTags.regular.strokeHover',
					borderColor: 'actions.filterTags.regular.strokeHover',
					borderWidth: '4px',
					paddingX: '12px',
					paddingY: '9px',
					fontWeight: 'bold',
				},
			},
			boxSizing: 'border-box',
			paddingX: '14px',
			paddingY: '11px',
		},
		iconContainer: {
			minWidth: '32px',
			minHeight: '32px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		answerLabel: {
			flexGrow: 1,
			position: 'relative',
		},
		answerValue: {
			textDecoration: 'underline',
			textUnderlineOffset: '4px',
			cursor: 'pointer',
			minWidth: '38px',
			textAlign: 'end',
			_hover: {
				color: 'actions.links.regular.hoverAndPressed',
			},
			_focusVisible: {
				boxShadow: 'outline',
			},
		},

		progressRoot: {
			height: '4px',
			width: '100%',
			left: '0px',
			bottom: '-10px',
			position: 'absolute',
		},
		progressRange: {
			height: '4px',

			borderRadius: 'full',
		},
		icon: {
			transition: 'opacity 200ms ease-out',
			transitionProperty: 'opacity',
			padding: '7px',
			paddingTop: '7px',
			paddingBottom: '7px',
		},
	},
	variants: {
		index: generateIndexVariants(),

		type: {
			Vote: {
				root: {
					_enabled: {
						_hover: {
							transition: 'none',
							borderColor: 'actions.filterTags.regular.strokeHover',
							color: 'actions.filterTags.regular.strokeHover',

							cursor: 'pointer',
						},
						_focusVisible: {
							transition: 'none',
							borderColor: 'actions.filterTags.regular.strokeHover',
							color: 'actions.filterTags.regular.strokeHover',
						},
					},
				},
			},
			Quiz: {
				root: {
					_enabled: {
						_hover: {
							transition: 'none',
							borderColor: 'actions.filterTags.regular.strokeHover',
							color: 'actions.filterTags.regular.strokeHover',
						},
						_focusVisible: {
							transition: 'none',
							borderColor: 'actions.filterTags.regular.strokeHover',
							color: 'actions.filterTags.regular.strokeHover',
						},
					},
				},
			},
			Sentiment: {
				progressRoot: {
					width: '100%',
					left: '0px',
				},
				iconContainer: {
					minWidth: '0px',
					marginRight: -4,
				},
			},
		},
		correctness: {
			correct: {},
			incorrect: {},
		},
		voted: {
			notVoted: {},
			voted: {
				root: {
					borderColor: 'transparent',
				},
			},
		},
		selected: {
			selected: {},
			notSelected: {},
		},
	},
	compoundVariants: [
		{
			voted: 'voted',
			selected: 'selected',
			css: {
				root: {
					fontWeight: 'bold',
					color: 'actions.filterTags.regular.strokeHover',
				},
				progressRange: {
					backgroundColor: 'actions.filterTags.regular.strokeHover',
				},
			},
		},
		{
			type: 'Vote',
			voted: 'voted',
			selected: 'notSelected',
			css: {
				progressRange: {
					opacity: 0.5,
					backgroundColor: 'icons.regular.secondary',
				},
			},
		},
		{
			type: 'Quiz',
			voted: 'voted',
			selected: 'notSelected',
			css: {
				progressRange: {
					opacity: 0.5,
					backgroundColor: 'icons.regular.secondary',
				},
			},
		},

		{
			type: 'Sentiment',
			voted: 'notVoted',
			index: 0,
			css: {
				root: {
					borderColor: 'charts.polls.background.regular.positive.90',
				},
			},
		},
		{
			type: 'Sentiment',
			voted: 'voted',
			index: 0,
			css: {
				progressRange: {
					backgroundColor: 'charts.polls.background.regular.positive.90',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 1,
			voted: 'notVoted',
			css: {
				root: {
					borderColor: 'charts.polls.background.regular.positive.80',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 1,
			voted: 'voted',
			css: {
				progressRange: {
					backgroundColor: 'charts.polls.background.regular.positive.80',
				},
			},
		},

		{
			type: 'Sentiment',
			index: 2,
			voted: 'notVoted',
			css: {
				root: {
					borderColor: 'charts.polls.background.regular.positive.70',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 2,
			voted: 'voted',
			css: {
				progressRange: {
					backgroundColor: 'charts.polls.background.regular.positive.70',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 3,
			voted: 'notVoted',
			css: {
				root: {
					borderColor: 'charts.polls.background.regular.positive.60',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 3,
			voted: 'voted',
			css: {
				progressRange: {
					backgroundColor: 'charts.polls.background.regular.positive.60',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 4,
			voted: 'notVoted',
			css: {
				root: {
					borderColor: 'charts.polls.background.regular.neutral',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 4,
			voted: 'voted',
			css: {
				progressRange: {
					backgroundColor: 'charts.polls.background.regular.neutral',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 5,
			voted: 'notVoted',
			css: {
				root: {
					borderColor: 'charts.polls.background.regular.negative.60',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 5,
			voted: 'voted',
			css: {
				progressRange: {
					backgroundColor: 'charts.polls.background.regular.negative.60',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 6,
			voted: 'notVoted',
			css: {
				root: {
					borderColor: 'charts.polls.background.regular.negative.70',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 6,
			voted: 'voted',
			css: {
				progressRange: {
					backgroundColor: 'charts.polls.background.regular.negative.70',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 7,
			voted: 'notVoted',
			css: {
				root: {
					borderColor: 'charts.polls.background.regular.negative.80',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 7,
			voted: 'voted',
			css: {
				progressRange: {
					backgroundColor: 'charts.polls.background.regular.negative.80',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 8,
			voted: 'notVoted',
			css: {
				root: {
					borderColor: 'charts.polls.background.regular.negative.90',
				},
			},
		},
		{
			type: 'Sentiment',
			index: 8,
			voted: 'voted',
			css: {
				progressRange: {
					backgroundColor: 'charts.polls.background.regular.negative.90',
				},
			},
		},
		{
			voted: 'voted',
			selected: 'selected',
			css: {
				progressRange: {
					backgroundColor: 'actions.filterTags.regular.strokeHover',
				},
				root: {
					color: 'actions.filterTags.regular.strokeHover',
				},
			},
		},
	],
});
