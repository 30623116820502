import { Heading } from '@/components/core/Heading/Heading';
import { LinkTracked } from '@/components/core/Link/LinkTracked';
import { NextLink } from '@/components/core/NextLink/NextLink';
import { Text } from '@/components/core/Text/Text';
import { IJob } from '@/components/shared/Jobs/Jobs.types';
import { inlineJobItemRecipe } from '@/components/shared/Jobs/components/JobItem/JobItem.recipe';
import { TimeIcon } from '@/icons/TimeIcon';
import { Box, Flex, Stack } from '@/styled-system/jsx';
import { formatRelativeDate } from '@/utils/i18n/locale-formatting/localeFormatting';
import { FC } from 'react';

interface IJobItemProps {
	job: IJob;
	language: string;
}

export const JobItem: FC<IJobItemProps> = ({ job, language }) => {
	const classes = inlineJobItemRecipe();

	if (!job || !job.url || !job.title || !job.startPublish || !job.jobOrgGf || !job.locationDescription) {
		return null;
	}

	return (
		<LinkTracked
			title={job.title}
			href={job.url}
			trackingId={job.trackingId}
			component={NextLink}
			trackingData={{
				cmsTrackingInformation: {
					label: `${job.id}|${job.title}`,
					conversionId: 'vt-job-interest',
					mediaSource: 'corpweb-cms',
					mediaType: 'page',
					mediaValue: job.id,
				},
			}}
		>
			<Stack className={classes.container} direction={{ base: 'column', md: 'row', lg: 'row', xl: 'row' }} gap={0}>
				<Box flex={2} py={{ base: 4, xl: 2, lg: 2, md: 2 }}>
					<Heading as="p" type="h6">
						{job.title}
					</Heading>
					<Flex mt={2} alignItems="center">
						<TimeIcon width={16} />
						<Text size="xs" ml={1} lineHeight="16px" className={classes.date}>
							{formatRelativeDate(job.startPublish, language)}
						</Text>
					</Flex>
				</Box>
				<Stack
					direction={{ base: 'column', lg: 'row', xl: 'row' }}
					flex={{ base: 1, lg: 2, xl: 2 }}
					gap={0}
					ml={{ base: 0, xl: 4, lg: 4, md: 4 }}
				>
					<Text size="sm" className={classes.label}>
						{job.jobOrgGf}{' '}
					</Text>
					<Text size="sm" className={classes.label}>
						{job.locationDescription}
					</Text>
				</Stack>
			</Stack>
		</LinkTracked>
	);
};
