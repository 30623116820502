import { IAppBannerBlockProps } from '@/components/block-templates/AppBannerBlockTemplate/AppBannerBlockTemplate';
import { ButtonModeVariant } from '@/enums/Button';
import { ContentType } from '@/enums/ContentType';
import { IBlockResponse, IPageContent } from '@/interfaces/coreInformation';

export const findAppBannerBlock = (pageDefinition: IPageContent) => {
	return pageDefinition?.pageInheritablesBlock?.inheritableBlocks?.find(
		(block) => block?.contentLink?.expanded?.contentType?.at(-1) === ContentType.AppBannerBlock
	) as IBlockResponse<IAppBannerBlockProps> | undefined;
};

export enum AppBannerBackgroundColor {
	PRIMARY_ACCENT = 'PrimaryAccent',
	PRIMARY_INVERTED = 'PrimaryInverted',
	SECONDARY = 'Secondary',
	SECONDARY_ACCENT = 'SecondaryAccent',
	INVERTED = 'Inverted',
}

export const getAppBannerButtonModeVariant = (
	appBannerBackgroundVariant?: AppBannerBackgroundColor
): ButtonModeVariant => {
	switch (appBannerBackgroundVariant) {
		case AppBannerBackgroundColor.PRIMARY_INVERTED:
		case AppBannerBackgroundColor.INVERTED:
			return ButtonModeVariant.INVERTED;
		default:
			return ButtonModeVariant.STANDARD;
	}
};
