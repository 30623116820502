'use client';

import { Text } from '@/components/core/Text/Text';
import { Flex } from '@/styled-system/jsx';
import { logger } from '@/utils/logger';
import { Component, FC, ReactNode } from 'react';

interface IErrorBoundaryProps {
	children: ReactNode;
}

interface IErrorBoundaryState {
	hasError: boolean;
	error: any;
}

const initialState: IErrorBoundaryState = {
	hasError: false,
	error: null,
};

// This is a wrapper component that will catch any error that occurs in the child components
const Wrapper: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;

export class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
	constructor(props: IErrorBoundaryProps) {
		super(props);
		this.state = initialState;
	}

	static getDerivedStateFromError(error: Error) {
		return { hasError: true, error };
	}

	componentDidCatch(error: Error) {
		logger.error(`[Error Boundary] Error message:${error?.message}`);
	}

	resetError = () => {
		this.setState(initialState);
	};

	render() {
		if (this.state.hasError) {
			const errorMessage = `${process.env.NODE_ENV !== 'production' ? `Info: ${this.state.error?.message}` : ''}`;

			return (
				<Flex w="full" h="full" flexDirection="column" alignItems="center" justifyContent="center">
					<Text size="md" color="text.regular.danger" fontWeight="semibold">
						Something went wrong. {errorMessage}
					</Text>
				</Flex>
			);
		}

		return <Wrapper>{this.props.children}</Wrapper>;
	}
}
