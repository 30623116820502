import { useArticleFilters } from '@/components/block-templates/ArticleFilterBlockTemplate/ArticleFilter.hook';
import { ISearchDropdownItem } from '@/components/core/SearchDropdown/SearchDropdown';
import { Tags } from '@/components/core/Tags/Tags';
import useQueryParams from '@/hooks/useQueryParams';
import { FC } from '@/interfaces/blocks/shared';
import { FilterBarOptions } from '@/types/article-filter';
import { omit } from 'lodash';

interface IArticleFilterTagsProps {
	filters: FilterBarOptions;
}

export const ArticleFilterTags: FC<IArticleFilterTagsProps> = ({ filters }) => {
	const { query, change } = useQueryParams();

	const { options } = useArticleFilters(filters);

	if (!query || Object.values(query).length === 0) {
		return <Tags.Root />;
	}

	const data = (() => {
		const mergedQueryValues = [...(query?.category || []), ...(query?.year || [])];

		const selectData = [
			...(options?.topicCategoryOptions || {}),
			...(options?.investorTypeCategoryOptions || {}),
			...(options?.yearsOptions || {}),
		];

		return selectData?.filter((option) => mergedQueryValues?.includes(option.value));
	})();

	const removeItemFromQuery = (options: ISearchDropdownItem | string) => {
		if (typeof options === 'string') {
			const search = options;
			const newQuery = omit(query, [search]);

			change(newQuery);

			return;
		}

		const newFilterQuery = Object.keys(query).reduce((acc, key) => {
			if (query[key].includes(options.value)) {
				const newQuery = (query[key] as Array<string>).filter((item) => item !== options.value);

				if (newQuery.length === 0) {
					return acc;
				}

				return { ...acc, [key]: newQuery };
			}

			return {
				...acc,
				[key]: query[key],
			};
		}, {});

		change(newFilterQuery);
	};

	return (
		<Tags.Root>
			{data?.map((option) => {
				return (
					<Tags.Item key={option.value}>
						<Tags.Label>{option.label}</Tags.Label>
						<Tags.CloseButton
							onClick={(e: MouseEvent) => {
								e.stopPropagation();
								removeItemFromQuery(option);
							}}
						/>
					</Tags.Item>
				);
			})}
		</Tags.Root>
	);
};
