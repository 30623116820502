import { useEffect, useState } from 'react';

export function useAnimatedPollAnswerValue(percentageValue: number | undefined) {
	const [animatedPercentageValue, setAnimatedPercentageValue] = useState<number | undefined>(percentageValue);

	useEffect(() => {
		if (percentageValue !== undefined) {
			let start: number | null = null;
			const duration = 1800;

			const easeInOutCubic = (t: number) => {
				return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
			};

			const animate = (timestamp: number) => {
				if (!start) {
					start = timestamp;
				}
				const progress = timestamp - start;
				const easedProgress = easeInOutCubic(progress / duration);
				const newValue = Math.round(easedProgress * percentageValue);

				setAnimatedPercentageValue(newValue);

				if (progress < duration) {
					requestAnimationFrame(animate);
				}
			};

			requestAnimationFrame(animate);
		}
	}, [percentageValue]);

	return animatedPercentageValue;
}
